

export interface InitialNotificationState {
    email_latest_update: boolean;
    email_tips_and_tutorials: boolean;
    errorFetchingNotification?: any;
    fetchingNotification?: boolean;
}

export const initialState: InitialNotificationState = {
    email_latest_update: false,
    email_tips_and_tutorials: false
}