// project imports
import { initialFilterState } from 'store/state/filters';
// import config from '../../config';

// action - state management
import * as filterConstants from "store/constants/filters"

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const filterReducer = (state = initialFilterState, action) => {
    switch (action.type) {
        case filterConstants.SET_PRODUCT_FILTER: {
            const { filter } = action.payload;
            console.log(filter);
            return {
                ...state,
                products: filter
            };
        }
        default:
            return state;
    }
};

export default filterReducer;
