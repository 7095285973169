import { Fragment } from "react";
import styled, { keyframes } from "styled-components";
// import Link from 'next/link';
// import styles from "../styles/views/footer/footerFull.module.scss"

// mui
import { useTheme, styled as MuiStyled } from "@mui/material/styles";
import {
  Avatar,
  Button,
  ButtonBase,
  Box,
  Divider,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  Paper,
} from "@mui/material";

// third-party

import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import LogoSection from "../FullLayout/LogoSection";
import Features from "fac/features";
import { gridSpacing } from "store/constants";
import NewsletterSection from "./NewsletterSection";
import { paths } from "navigation/paths";
import {
  SmallAvatarStyle,
  MediumAvatarStyle,
} from "ui-component/extended/Avatar";

// assets
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandYoutube,
  IconGlobe,
  IconPhone,
  IconMail,
  IconClock,
  IconBrandLinkedin,
} from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";
import ReactGA from "react-ga4";

var gradient = keyframes`
0% {
    background-positions: 0% 50%
}
50% {
    background-position: 100% 50%
}
100% {
    background-position: 0% 50%
}
`;

const Background = styled.section`
  color: #fff;
  border-radius: 20px 20px 0 0;
  background: linear-gradient(-45deg, #202020, #505050);
  background-size: 250% 250%;
`;

// animation: ${gradient} 7.5s ease infinite;

const StyledLink = MuiStyled(Link, { shouldForwardProp })(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.background.paper,
  "&:hover": {
    color: theme.palette.orange.main,
  },
}));

export default function Footer() {
  const theme = useTheme();

  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const footerContent = {
    brand: {
      route: "https://www.quasaonline.com",
      logo: <LogoSection width="150px" height="50px" />,
    },
    operation: [
      {
        text: "sales@tigatanresources.com",
        href: "mailto:sales@tigatanresources.com",
        icon: <IconMail stroke={1} size={16} />,
      },
      {
        text: "+60 3-3342 1212",
        icon: <IconPhone stroke={1} size={16} />,
      },
      {
        text: "Mon-Fri 9:00am - 5:00pm",
        icon: <IconClock stroke={1} size={16} />,
      },
      {
        text: "28, Jalan Batai Laut 4, Taman Intan, 41300 Klang, Selangor, Malaysia",
        href: "https://goo.gl/maps/ji1rfnM84sKnXSwU9",
        icon: <IconGlobe stroke={1} size={16} />,
      },
    ],
    socials: [
      {
        icon: <IconBrandFacebook stroke={1.5} size={18} />,
        link: "https://www.facebook.com/TigatanResources",
      },
      // {
      //   icon: <IconBrandInstagram stroke={1.5} size="1.2rem" />,
      //   link: "https://www.facebook.com/TigatanResources",
      // },
      // {
      //   icon: <IconBrandYoutube stroke={1.5} size="1.2rem" />,
      //   link: "https://www.facebook.com/TigatanResources",
      // },
      // {
      //   icon: <IconBrandLinkedin stroke={1.5} size="1.2rem" />,
      //   link: "https://www.facebook.com/TigatanResources",
      // },
    ],
    menus: [
      {
        name: "Company",
        items: [
          { name: "About us", href: paths.AboutPage },
          Features.isEcomEnabled()
            ? { name: "Be a retailer", href: paths.BecomeRetailerPage }
            : {},
          { name: "Locations", href: paths.LocationPage },
          Features.isEcomEnabled()
            ? { name: "Affliate", href: paths.AffliatesPage }
            : {},
          { name: "Career", href: paths.JobsPage },
        ],
      },
      {
        name: "Resources",
        items: [
          Features.isEcomEnabled()
            ? { name: "Find a Retailer", href: paths.FindRetailerPage }
            : {},
          { name: "Warranty Information", href: paths.WarrantyPage },
          { name: "Parts & Services", href: paths.PartsServices },
          { name: "Support", href: paths.ContactPage },
        ],
      },
    ],
    legal: [
      {
        name: "Terms of Service",
        href: paths.TermsPage,
        divider: true,
      },
      {
        name: "Privacy Policy",
        href: paths.PrivacyPage,
        divider: true,
      },
      { name: "Legal", href: paths.LegalPage, divider: false },
    ],
    copyright: (
      <Typography
        variant="subtitle2"
        sx={{
          display: "inline-flex",
          color: theme.palette.background.default,
          my: 1,
        }}
      >
        &copy; {new Date().getFullYear()} &nbsp;
        <Typography
          variant="subtitle2"
          sx={{ textDecoration: "none" }}
          component={Link}
          target="_blank"
          href="https://www.quasaonline.com"
        >
          Quasa by Tigatan Resources (M) Sdn. Bhd.
        </Typography>
      </Typography>
    ),
  };

  const OperationButton = ({ content }) => (
    <ButtonBase href={content.href} target="_blank" disableRipple={true}>
      <Typography
        variant="subtitle2"
        sx={{
          color: theme.palette.background.default,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <SmallAvatarStyle variant="rounded">{content.icon}</SmallAvatarStyle>
        {content.text}
      </Typography>
    </ButtonBase>
  );

  const OperationText = ({ content }) => (
    <Typography
      variant="subtitle2"
      sx={{
        color: theme.palette.background.default,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <SmallAvatarStyle variant="rounded" sx={{ cursor: "auto" }}>
        {content.icon}
      </SmallAvatarStyle>
      {content.text}
    </Typography>
  );

  const { brand, socials, operation, menus, copyright, legal } = footerContent;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={5}
          sx={{
            width: "97.5vw", borderRadius: "20px 20px 0 0",
          }}
        >
          <Background>
            <Grid container sx={{ py: 4, px: 2.5 }}>
              <Grid
                item
                xs={12}
                lg={3}
                sx={{
                  [theme.breakpoints.down("md") && theme.breakpoints.up("sm")]: {
                    my: "20px",
                  },
                }}
              >
                {brand.logo}
                <Grid container spacing={gridSpacing}>
                  <Grid
                    item
                    xs={12}
                    spacing={gridSpacing}
                    sx={{
                      marginTop: 2,
                    }}
                  >
                    {operation.map((content, idx) => (
                      <Fragment key={idx}>
                        {content.href ? (
                          <OperationButton content={content} />
                        ) : (
                          <OperationText content={content} />
                        )}
                      </Fragment>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              {menus.map(({ name: title, items }, idx) => (
                <Grid
                  key={idx}
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={2}
                  spacing={gridSpacing}
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      my: "10px",
                    },
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      color: theme.palette.background.default,
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.background.default,
                      mt: 1,
                    }}
                  >
                    <Grid container direction="column">
                      {items.map(({ name, href }, idx) => (
                        <StyledLink key={idx} href={href}>
                          <Typography variant="inherit" sx={{ my: 0.5 }}>
                            {name}
                          </Typography>
                        </StyledLink>
                      ))}
                    </Grid>
                  </Typography>
                </Grid>
              ))}
              <Grid
                item
                xs={12}
                lg={5}
                spacing={gridSpacing}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    my: "10px",
                  },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: theme.palette.background.default,
                  }}
                >
                  Newsletter
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: theme.palette.background.default,
                    mt: 1,
                  }}
                >
                  Be the first to get notified with updates on products!
                  <NewsletterSection />
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: theme.palette.background.default,
                    mt: 1,
                  }}
                >
                  Follow us on social networks
                </Typography>
                <Box>
                  {socials.map(({ icon, link }, idx) => (
                    <ButtonBase
                      key={idx}
                      sx={{ borderRadius: "12px" }}
                      href={link}
                      target="_blank"
                      onClick={() => {
                        ReactGA.event({
                          category: "social",
                          action: "click",
                          label: "facebook",
                        });
                      }}
                    >
                      <MediumAvatarStyle variant="rounded">
                        {icon}
                      </MediumAvatarStyle>
                    </ButtonBase>
                  ))}
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              display="flex"
              sx={{
                flexDirection: matchUpMd ? "row" : "column",
                [theme.breakpoints.down("md")]: {
                  paddingBottom: "50px"
                }
              }}
            >
              <Grid container display="flex" alignItems="center" sx={{ ml: 1 }}>
                {legal.map(({ name, href, divider }, idx) => (
                  <Fragment key={idx}>
                    <Button
                      size="small"
                      href={href}
                      sx={{
                        color: theme.palette.background.default,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: theme.palette.orange.dark,
                          color: theme.palette.background.default,
                        },
                      }}
                    >
                      {name}
                    </Button>
                    {divider && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          "&.MuiDivider-root": {
                            mx: "2px",
                            borderColor: theme.palette.grey[500],
                            height: "20px",
                            mt: "5px",
                          },
                        }}
                      />
                    )}
                  </Fragment>
                ))}
              </Grid>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent={matchUpMd ? "flex-end" : "flex-start"}
                sx={{
                  [theme.breakpoints.up("md")]: {
                    mr: 1,
                  },
                  [theme.breakpoints.down("md")]: {
                    ml: 1,
                  },
                }}
              >
                {copyright}
              </Grid>
              <span id="siteseal">
                <script
                  async
                  type="text/javascript"
                  src="https://seal.godaddy.com/getSeal?sealID=M2TtCpPJgOd9lvibNfGMrMQWcdsmR33q1SOtQPG0n967RPz4m1cxVpZKgLEK"
                ></script>
              </span>
            </Grid>
          </Background>
        </Paper>
      </Box>
    </>
  );
}
