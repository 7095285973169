import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

// assets
import { IconPlus, IconMinus } from "@tabler/icons";

// styles
const CardStyle = styled(Card)(({ theme }) => ({
  background: "#fff",
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  marginTop: "16px",
  marginBottom: "16px",
  overflow: "hidden",
  position: "relative",
  height: "7rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "10px solid ",
    borderColor: theme.palette.primary.main,
    borderRadius: "50%",
    top: "65px",
    right: "-150px",
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "3px solid ",
    borderColor: theme.palette.primary.main,
    borderRadius: "50%",
    top: "145px",
    right: "-70px",
  },
}));

const CartProduct = ({ entry, removeItem, incrementItem, decrementItem }) => {
  const theme = useTheme();

  return (
    <CardStyle>
      <Grid container display="flex" direction="row">
        <Grid
          container
          height="100"
          display="flex"
          justifyContent="center"
          alignItems="center"
          md={5}
        >
          <Box
            sx={{
              height: 100,
              width: 100,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={entry.image}
              alt={`${entry.name}`}
              height="100%"
              style={{
                borderRadius: 10,
                width: "100%",
              }}
            />
          </Box>
        </Grid>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          md={7}
        >
          <Grid
            item
            justifyContent="flex-start"
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
          >
            <Grid item>
              <Typography variant="subtitle">{entry.name}</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="caption"
                color="grey.900"
                sx={{ opacity: 0.6 }}
              >
                {entry.model_no}
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 1 }}>
              <Typography variant="caption">{entry.formattedValue}</Typography>
            </Grid>
            <Grid
              item
              justifyContent="center"
              alignItems="center"
              // sx={{ m }}
            >
              <IconButton
                aria-label="subtract"
                sx={{ mr: 1, p: 0 }}
                onClick={() => decrementItem(entry.id)}
              >
                <IconMinus size={14} />
              </IconButton>
              <Typography variant="subtitle">{entry.quantity}</Typography>
              <IconButton
                aria-label="add"
                sx={{ ml: 1, p: 0 }}
                onClick={() => incrementItem(entry.id)}
              >
                <IconPlus size={14} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardStyle>
  );
};

CartProduct.propTypes = {
  product: PropTypes.object.isRequired,
  removeProduct: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  quantityTextLabel: PropTypes.string.isRequired,
};

export default CartProduct;
