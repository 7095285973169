import { alertConstants } from '../constants';

export default function alert(state = {}, action) {
    switch (action.type) {
        case alertConstants.SUCCESS:
            return {
                type: 'success',
                payload: action.payload
            };
        case alertConstants.ERROR:
            return {
                type: 'error',
                payload: action.payload
            };
        case alertConstants.CLEAR:
            return {};
        default:
            return state;
    }
}
