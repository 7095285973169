import axios from 'axios';
import { alertActions } from "../actions/alert";
import {
    apiError,
    apiSuccess,
    API_REQUEST,
    CANCEL_API_REQUEST
} from "../actions/api";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export const apiMiddleware = ({ dispatch }) => next => action => {
    next(action);
    if (action.type === API_REQUEST) {
        const { url, method, data } = action.meta;
        axios({
            method,
            url,
            data,
            cancelToken: source.token
        })
            .then(
                ({ data }) => {
                    dispatch(apiSuccess({ response: data }));
                    if (data.username) {
                        dispatch(alertActions.success(
                            `Welcome back ${data.username}`
                        ))
                    } else {
                        dispatch(alertActions.success(
                            `Welcome back ${data.first_name}`
                        ))
                    }
                },
                error => {
                    dispatch(alertActions.error("Please try again"))
                    dispatch(apiError({ error: error.message }));
                }
            )
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error.message);
                }
            })
    } else if (action.type === CANCEL_API_REQUEST) {
        source.cancel("Operation canceled by the user");
        console.log("Request cancelled")
    }
}