import { lazy } from 'react';

// project imports
import FullLayout from 'layout/FullLayout';
import Loadable from 'ui-component/Loadable';

import { paths } from "navigation/paths";

// general routes
const ShopPage = Loadable(lazy(() => import('views/pages/shop')));
const UnityCelPage = Loadable(lazy(() => import("views/pages/shop/unityCel")));
const CatalogsPage = Loadable(lazy(() => import("views/pages/shop/catalogs")));
const ProductsPage = Loadable(lazy(() => import("views/pages/shop/products")))
const ProductDetailsPage = Loadable(lazy(() => import("views/pages/shop/productDetails")))
const SuccessPurchasedPage = Loadable(lazy(() => import("views/pages/shop/status/success")))

// ==============================|| MAIN ROUTING ||============================== //

const ShopRoutes = {
    path: '/',
    element: <FullLayout />,
    children: [
        {
            path: paths.ShopPage,
            element: <ShopPage />
        },
        {
            path: paths.UnityCelPage,
            element: <UnityCelPage />
        },
        {
            path: paths.CatalogsPage,
            element: <CatalogsPage />
        },
        {
            path: paths.ProductsPage,
            element: <ProductsPage />
        },
        {
            path: paths.ProductDetailsPage,
            element: <ProductDetailsPage />
        },
        {
            path: paths.SuccessPurchasedPage,
            element: <SuccessPurchasedPage />
        }
    ]
};

export default ShopRoutes;
