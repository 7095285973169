import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { signOut } from "aws-amplify/auth";

// mui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  InputAdornment,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

// third-party
// import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import CartCard from "./CartCard";
import User from "ui-component/icons/Person";
import { getCurrentMoment } from "utils/time";

// assets
import {
  IconLogout,
  IconLogin,
  IconShoppingCart,
  IconSettings,
} from "@tabler/icons";
import { paths, dashboardPaths } from "navigation/paths";
import { initialState } from "store/state/user";
import { setUser } from "store/actions/user";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = (props) => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [auth, setIsAuth] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    console.log("Logging out...");
    try {
      await signOut();
      props.setUser(initialState);
      setIsAuth(false)
    } catch (err) {
      console.log({ err });
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (user.email !== "" && user.userGroup !== "") { 
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [user])

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.orange.light,
          backgroundColor: theme.palette.orange.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.orange.dark,
            background: `${theme.palette.orange.dark}!important`,
            color: theme.palette.orange.light,
            "& svg": {
              stroke: theme.palette.orange.light,
              color: theme.palette.orange.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={"ui-component/icons/Person"}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
              backgroundColor: theme.palette.orange.main,
              color: `${theme.palette.orange.dark} !important`,
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color={theme.palette.orange.dark}
          />
        }
        label={
          <IconShoppingCart
            stroke={1.5}
            size={24}
            color={theme.palette.orange.dark}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {auth ? (
                  <MainCard
                    border={false}
                    // raised={}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ p: 2 }}>
                      <Stack>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="h4">
                            {getCurrentMoment()},
                          </Typography>
                          <Typography
                            component="span"
                            variant="h4"
                            sx={{ fontWeight: 400 }}
                          >
                            {user.given_name}
                          </Typography>
                        </Stack>
                      </Stack>
                      <CartCard />
                      <Divider />
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                            // backgroundColor: theme.palette.orange.light,
                            "&:hover": {
                              backgroundColor: theme.palette.orange.dark,
                              color: "white",
                              "& .MuiListItemIcon-root": {
                                color: "white",
                              },
                            },
                          }}
                          selected={selectedIndex === 0}
                          onClick={(event) =>
                            handleListItemClick(
                              event,
                              0,
                              dashboardPaths.SettingsPage
                            )
                          }
                        >
                          <ListItemIcon>
                            <IconSettings
                              stroke={1.5}
                              size={20}
                              sx={{ color: "inherit" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{ color: "inherit" }}
                              >
                                Profile
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                            // backgroundColor: theme.palette.orange.light,
                            "&:hover": {
                              backgroundColor: theme.palette.orange.dark,
                              color: "white",
                              "& .MuiListItemIcon-root": {
                                color: "white",
                              },
                            },
                          }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout
                              stroke={1.5}
                              size={20}
                              sx={{ color: "inherit" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{ color: "inherit" }}
                              >
                                Logout
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                    {/* </PerfectScrollbar> */}
                  </MainCard>
                ) : (
                  <Box sx={{ py: 0.5, px: 2 }}>
                    <CartCard />
                    <Divider />
                    <List
                      component="nav"
                      sx={{
                        width: "100%",
                        maxWidth: "100%",
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                        "& .MuiListItemButton-root": {
                          mt: 0.5,
                        },
                      }}
                    >
                      {/* <Link href="/login" sx={{ textDecoration: "none" }}> */}
                      <Link href={auth ? dashboardPaths.OrdersPage : paths.LoginPage } sx={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                            // backgroundColor: theme.palette.orange.light,
                            "&:hover": {
                              backgroundColor: theme.palette.orange.dark,
                              color: "white",
                              "& .MuiListItemIcon-root": {
                                color: "white",
                              },
                            },
                          }}
                          selected={selectedIndex === 4}
                        >
                          <ListItemIcon>
                            <IconLogin
                              stroke={1.5}
                              size={20}
                              sx={{ color: "inherit" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{ color: "inherit" }}
                              >
                                Sign In
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Box>
                )}
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  setUser,
};


export default connect(mapStateToProps, mapDispatchToProps)(ProfileSection);
