import { Reducer } from "redux";
// import { ProductAction } from "store/actions/products";
import * as productConstants from "store/constants/products"
import { InitialProductState, initialProductState } from "store/state/products"


export const productsReducer: Reducer<InitialProductState, any> = 
(state = initialProductState, action) => {
    switch (action.type) {
        case `${productConstants.LIST_PRODUCTS}_PENDING`: {
            return {
                ...state,
                fetchingProducts: true
            }
        }
        case `${productConstants.LIST_PRODUCTS}_REJECTED`: {
            const { errors } = action.payload;
            return {
                ...state,
                fetchingProductErrors: errors[0].message,
                fetchingProducts: false
            }
        }
        case `${productConstants.LIST_PRODUCTS}_FULFILLED`: {
            const { items, nextToken } = action.payload;

            return {
                ...state,
                products: [...state.products, ...items],
                productsNextToken: nextToken,
                fetchingProducts: false
            }
        }
        case `${productConstants.LIST_PRODUCT_DETAILS}_PENDING`: {
            return {
                ...state,
                fetchingProductDetails: true
            }
        }
        case `${productConstants.LIST_PRODUCT_DETAILS}_FULFILLED`: {
            const product = action.payload;

            return {
                ...state,
                productDetails: product,
                fetchingProductDetails: false
            }
        }
        case `${productConstants.LIST_PRODUCT_DETAILS}_REJECTED`: {
            const { errors } = action.payload;
            return {
                ...state,
                fetchingProductErrors: errors[0].message,
                fetchingProducts: false
            }
        }

        case `${productConstants.LIST_PRODUCT_COLLECTIONS}_PENDING`: {
            const { collectionId } = action.payload;
            
            return {
                ...state,
                collections: {
                    [collectionId]: {
                        collectionProducts: [],
                        fetchingCollectionProducts: true
                    }
                }
            }
        }

        case `${productConstants.LIST_PRODUCT_COLLECTIONS}_FULFILLED`: {
            const { collectionId, items, token } = action.payload;
            const { collections } = state;

            let currentProducts: any[] = [];
            
            if (collections && collections[collectionId].collectionProducts) {
                currentProducts = [...collections[collectionId].collectionProducts];
            }

            return {
                ...state,
                collections: {
                    [collectionId]: {
                        collectionProducts: [...currentProducts, ...items],
                        collectionProductsNextToken: token,
                        fetchingCollectionProducts: false,
                        lastUpdatedTime: Date.now().toString()
                    }
                }
            }
        }

        case `${productConstants.LIST_PRODUCT_BUNDLES}_PENDING`: {
            const { bundleId } = action.payload;
            
            return {
                ...state,
                bundles: {
                    [bundleId]: {
                        bundleProducts: [],
                        fetchingBundleProducts: true,
                        lastUpdatedTime: Date.now().toString()
                    }
                }
            }
        }

        case `${productConstants.LIST_PRODUCT_BUNDLES}_FULFILLED`: {
            const { bundleId, items, token } = action.payload;
            const { bundles } = state;

            let currentProducts: any[] = [];
            
            if (bundles && bundles[bundleId].bundleProducts) {
                currentProducts = [...bundles[bundleId].bundleProducts];
            }

            return {
                ...state,
                bundles: {
                    [bundleId]: {
                        bundleProducts: [...currentProducts, ...items],
                        bundleProductsNextToken: token,
                        fetchingBundleProducts: false,
                        lastUpdatedTime: Date.now().toString()
                    }
                }
            }
        }
        
        case `${productConstants.LIST_VIEWED_PRODUCTS}_PENDING`: {
            const { items } = action.payload;

            return {
                ...state,
                viewedProducts: items
            }
        }

        default:
            return state
    }
}