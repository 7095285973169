import { CreateUserInput, USER_TYPE } from "graphql/API";

interface ClientUser extends CreateUserInput {
    userGroup: any;
    role: any;
}
export interface InitialUserState { 
    user: CreateUserInput | null;
    fetchingCurrentUser: boolean;
    errorFetchingCurrentUser?: any;
    fetchError?: any;
}

export const initialUser: ClientUser = {
    username: "",
    email: "",
    contact_num: "",
    userGroup: "",
    family_name: "",
    given_name: "",
    role: "",
    customer_id: "",
    type: USER_TYPE.User,
    country: "",
    id: ""
}

export const initialState: InitialUserState = {
    user: initialUser,
    fetchingCurrentUser: false
}
