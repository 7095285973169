// assets
import { IconPackage, IconCheckbox, IconRefresh, IconTruckDelivery, IconTrashOff, IconClipboardCheck, IconTools, IconShieldHalf } from '@tabler/icons';

import { dashboardPaths } from 'navigation/paths';

// constant
const icons = { IconPackage, IconCheckbox, IconRefresh, IconTruckDelivery, IconTrashOff, IconClipboardCheck, IconTools, IconShieldHalf };

const services = {
    id: 'services',
    title: 'Services',
    type: 'group',
    children: [
        {
            id: 'warranty',
            title: 'Warranty',
            type: 'item',
            url: dashboardPaths.WarrantyPage,
            icon: icons.IconShieldHalf,
            breadcrumbs: false
        },
    ]
}

export default services;
