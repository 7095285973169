import { lazy } from "react";

// project imports
import FullLayout from "layout/FullLayout";
import Loadable from "ui-component/Loadable";

import { paths } from "navigation/paths";

// general routes
const NotFoundPage = Loadable(lazy(() => import('views/pages/general/404')));

// ==============================|| MAIN ROUTING ||============================== //

const ErrorRoutes = {
  path: "/",
  element: <FullLayout backgroundColor={'black'}/>,
  children: [
    {
      path: paths.ErrorsPage,
      element: <NotFoundPage />,
    },
  ],
};

export default ErrorRoutes;
