import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

// styles
const CardStyle = styled(Card)(({ theme }) => ({
  background: theme.palette.common.white,
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  marginTop: "16px",
  marginBottom: "16px",
  overflow: "hidden",
  position: "relative",
  height: "7rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "10px solid ",
    borderColor: theme.palette.primary.main,
    borderRadius: "50%",
    top: "65px",
    right: "-150px",
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "3px solid ",
    borderColor: theme.palette.primary.main,
    borderRadius: "50%",
    top: "145px",
    right: "-70px",
  },
}));

export interface NoResultsCardProps {
  description: string;
}

export const NoResultsCard = ({ description }: NoResultsCardProps) => {
  return (
    <CardStyle
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CardContent>
        <Grid container>
          <Grid item>
            <Typography variant="h4">{description}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </CardStyle>
  );
};
