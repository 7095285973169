// interface NewsletterType {
//     latestUpdates: boolean;
//     tipsAndTutorials: boolean;
// }

export interface InitialNewsletterState {
    submittingNewsletter: boolean;
    submittingNewsletterError?: any;
}

export const initialNewsletterState: InitialNewsletterState = {
    submittingNewsletter: false,
}