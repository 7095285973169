export interface InitialCartState {
    cart: any[];
    fetchingCart?: boolean;
    addingToCart?: boolean;
    removingFromCart?: boolean;
}

export const initialCartState: InitialCartState = {
    cart: [],
    fetchingCart: false,
    addingToCart: false,
    removingFromCart: false
}