export const CLEAR_LIST = "CLEAR_LIST";
export type CLEAR_LIST = typeof CLEAR_LIST;

export const LIST_PRODUCTS = "LIST_PRODUCTS";
export type LIST_PRODUCTS = typeof LIST_PRODUCTS;

export const LIST_PRODUCT_DETAILS = "LIST_PRODUCT_DETAILS";
export type LIST_PRODUCT_DETAILS = typeof LIST_PRODUCT_DETAILS;

export const LIST_PRODUCT_BUNDLES = "LIST_PRODUCT_BUNDLES";
export type LIST_PRODUCT_BUNDLES = typeof LIST_PRODUCT_BUNDLES;

export const LIST_PRODUCT_COLLECTIONS = "LIST_PRODUCT_COLLECTIONS";
export type LIST_PRODUCT_COLLECTIONS = typeof LIST_PRODUCT_COLLECTIONS;

export const LIST_VIEWED_PRODUCTS = "LIST_VIEWED_PRODUCTS";
export type LIST_VIEWED_PRODUCTS = typeof LIST_VIEWED_PRODUCTS;

export const ADD_VIEWED_PRODUCTS = "ADD_VIEWED_PRODUCTS";
export type ADD_VIEWED_PRODUCTS = typeof ADD_VIEWED_PRODUCTS;
