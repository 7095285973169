// Material Kit 2 React base styles
import colors from "themes/base/colors";
import borders from "themes/base/borders";
import boxShadows from "themes/base/boxShadows";

// Material Kit 2 React helper functions
import pxToRem from "themes/functions/pxToRem";
import { ComponentsOverrides } from "@mui/material/styles";

const { grey, white } = colors;
const { borderRadius } = borders;
const { tabsBoxShadow } = boxShadows;

interface StylesProps<Theme = unknown> {
  styleOverrides: ComponentsOverrides<Theme>['MuiTabs'];
}

const styles: StylesProps = {
  styleOverrides: {
    root: {
      position: "relative",
      backgroundColor: grey[100],
      borderRadius: borderRadius.xl,
      minHeight: "unset",
      padding: pxToRem(4),
    },
    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },
    fixed: {
      overflow: "unset !important",
      // overflowX: "unset !important"
    },
    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },
    indicator: {
      height: "100%",
      borderRadius: borderRadius.lg,
      backgroundColor: white.main,
      boxShadow: tabsBoxShadow.indicator,
      transition: "all 500ms ease",
    },
  },
}

export default styles;
