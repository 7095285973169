import { FC } from "react";
// mui
import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider as Provider } from "@mui/material/styles";
import { ThemeProvider } from "ThemeContext";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";

// defaultTheme
import themes from "themes/index";

import { useSelector } from "react-redux";
import { ApplicationState } from "store/reducers";

interface AppProvidersProps {
  children: React.ReactNode;
}

const AppProviders: FC<AppProvidersProps> = ({ children }) => {
  const customization = useSelector(
    (state: ApplicationState) => state.customization
  );
  const { user } = useSelector((state: ApplicationState) => state.user);

  return (
    <FlagsmithProvider
      options={{
        environmentID: process.env.REACT_APP_FLAGSMITH_ENV || "",
        identity: user?.id || ""
      }}
      flagsmith={flagsmith}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <Provider theme={themes(customization)}>{children}</Provider>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </FlagsmithProvider>
  );
};

export default AppProviders;
