import * as constants from 'store/constants/user';
import { AmplifyAPI } from "app/AmplifyConfigs";
import { createUser as CreateUser, updateUser as UpdateUser } from 'graphql/mutations';
import { getUser as GetUser } from 'graphql/queries';
import { CreateUserInput, GetUserQuery, GetUserQueryVariables } from 'graphql/API';
import { GraphQLResult } from '@aws-amplify/api';

export const setUser = (user) => ({
    type: constants.SET_USER,
    payload: user
})

export const createUser = (user: CreateUserInput) => ({
    type: constants.CREATE_USER,
    payload: async () => {
        try { 
            console.log(user);
            const res = await AmplifyAPI.graphql({ 
                query: CreateUser.replaceAll("__typename", ""),
                variables: {
                  input: user,
                },
                authMode: 'userPool',
            })
            
            if (res) {
                // @ts-ignore
                return res.data.createUsers
            }
        } catch (e) {
            console.error(e);
        }
    }
})

export const updateUser = (user) => ({
    type: constants.UPDATE_USER,
    payload: async () => {
        try { 
            const res = await AmplifyAPI.graphql({ 
                query: UpdateUser.replaceAll("__typename", ""),
                variables: {
                  input: {
                    // ...modelFields,
                  },
                },
                authMode: 'userPool',
            })

            if (res) {
                // @ts-ignore
                return res.data.updateUsers
            }
        } catch (e) {
            console.error(e);
        }
    }
})

export const getUser =
    ({ id }: GetUserQueryVariables, name?: string) =>
    ({
        type: name ? constants.GET_CURRENT_USER : constants.GET_USER,
        payload:
            async () => {
                try {
                    const { data } = await AmplifyAPI.graphql({
                        query: GetUser.replaceAll("__typename", ""),
                        variables: {
                            id
                        }
                    }) as GraphQLResult<GetUserQuery>;

                    return { ...data.getUser, name }
                } catch (e) {
                    return e;
                }
            }
    })