import { listProducts } from "graphql/queries";
import { LIMIT } from "store/constants";
import * as favoriteConstants from "store/constants/favorites"
import { AmplifyAPI } from "app/AmplifyConfigs";
import { getFavoriteItems } from "graphql/queries";

export const getFavoriteProductIds =
    ({ id }, authenticated = false) =>
    ({
        type: favoriteConstants.ADD_FAVORITE_PRODUCT,
        payload: async () => {
            try {
                const res = await AmplifyAPI.graphql({
                    query: getFavoriteItems.replaceAll("__typename", ""),
                    variables: {
                        id
                    },
                    authMode: authenticated ? 'userPool' : 'iam',
                })
                // const res = null;

                if (res) {
                    // @ts-ignore
                    return res.data?.getFavoriteItems
                }
            } catch (e) {
                console.error(e);
            }
        }
    })

export const getFavoriteProducts =
    ({ ids }, authenticated = false) => ({
        type: favoriteConstants.LIST_FAVORITE_PRODUCTS,
        payload: async () => {
            try {
                const filterIds = ids.map((id: string) => {
                    return ({
                        id: {
                            eq: id
                        }
                    })
                })
                const res = await AmplifyAPI.graphql({
                    query: listProducts.replaceAll("__typename", ""),
                    variables: {
                        filter: {
                            or: filterIds
                        },
                        limit: LIMIT
                    },
                    authMode: authenticated ? 'userPool' : 'iam',
                })

                if (res) {
                    // @ts-ignore
                    return res.data?.listProducts
                }
            } catch (e) {
                console.error(e);
            }
        }
    })

// export const addProductToFavorites = 