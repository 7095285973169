import { Reducer } from "redux";
import { InitialHistoryState, initialHistoryState } from "store/state/history";
import * as historyConstants from "store/constants/history";


export const historyReducer: Reducer<InitialHistoryState, any> = 
(state = initialHistoryState, action) => {
    switch (action.type) {
        case `${historyConstants.LIST_BROWSING_HISTORY}_PENDING`: {
            return {
                ...state,
                fetchingBrowsingHistory: true
            }
        }

        case `${historyConstants.LIST_BROWSING_HISTORY}_FULFILLED`: {
            
            return {
                ...state,
                browsing: [],
                fetchingBrowsingHistory: false
            }
        }
        default: 
            return state;
    }
}