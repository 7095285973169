export const LIST_SEARCH_HISTORY = "LIST_SEARCH_HISTORY";
export type LIST_SEARCH_HISTORY = typeof LIST_SEARCH_HISTORY;

export const LIST_BROWSING_HISTORY = "LIST_BROWSING_HISTORY";
export type LIST_BROWSING_HISTORY = typeof LIST_BROWSING_HISTORY;

export const ADD_TO_SEARCH_HISTORY = "ADD_TO_SEARCH_HISTORY";
export type ADD_TO_SEARCH_HISTORY = typeof ADD_TO_SEARCH_HISTORY;

export const ADD_TO_BROWSING_HISTORY = "ADD_TO_BROWSING_HISTORY";
export type ADD_TO_BROWSING_HISTORY = typeof ADD_TO_BROWSING_HISTORY;

export const UPDATE_BROWSING_HISTORY = "UPDATE_BROWSING_HISTORY";
export type UPDATE_BROWSING_HISTORY = typeof UPDATE_BROWSING_HISTORY;

export const UPDATE_SEARCH_HISTORY = "UPDATE_SEARCH_HISTORY";
export type UPDATE_SEARCH_HISTORY = typeof UPDATE_SEARCH_HISTORY;
