import * as constants from "store/constants/notification";
import { initialState } from 'store/state/notification';

export const notificationReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case `${constants.GET_NOTIFICATION}_PENDING`: {
            return {
                ...state,
                fetchingNotification: false
            }
        }
        case `${constants.GET_NOTIFICATION}_FULFILLED`: {
            const data = action.payload;
            console.log(data);

            return {
                ...state,
                ...data,
                fetchingNotification: true
            }
        }
        case `${constants.GET_NOTIFICATION}_REJECTED`: {
            const { errors } = action.payload;

            return {
                ...state,
                errorFetchingNotification: errors[0]
            }
        }
        case `${constants.UPDATE_NOTIFICATION}_PENDING`: {
            return {
                ...state,
                fetchingNotification: false
            }
        }
        case `${constants.UPDATE_NOTIFICATION}_FULFILLED`: {
            const data = action.payload;

            return {
                ...state,
                ...data,
                fetchingNotification: true
            }
        }
        case `${constants.UPDATE_NOTIFICATION}_REJECTED`: {
            const { errors } = action.payload;

            return {
                ...state,
                errorFetchingNotification: errors[0]
            }
        }
        default: 
            return state;
    }
}