import PropTypes from "prop-types";
import { useState } from "react";

// mui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  Popper,
  Typography,
} from "@mui/material";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "../../../ui-component/extended/Transitions";

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    marginTop: 10,
    // '& .MuiInputBase-root': {
    //   borderColor: !theme.palette.orange.main,
    //   '& .MuiOutlinedInput-root': {
    //     borderColor: !theme.palette.orange.main,
    //     '& .Mui-focused': {
    //       borderColor: !theme.palette.orange.main,
    //       '& .MuiOutlinedInput-notchedOutline':{
    //         borderColor: !theme.palette.orange.main,
    //       }
          
    //     }
    //   },
    // },
    
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.orange.main,
      // '& .Mui-focused': {
      //   borderColor: !theme.palette.orange.main,
      // }
    },
    "& input": {
      background: "transparent !important",
      paddingLeft: "15px !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background: "#fff",
    },
  })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    borderRadius: "12px",
    background: theme.palette.orange.light,
    color: theme.palette.orange.dark,
    "&:hover": {
      background: theme.palette.orange.dark,
      color: theme.palette.orange.light,
    },
  })
);

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
  const theme = useTheme();

  return (
    <OutlineInputStyle
      id="input-search-header"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Search"
      startAdornment={
        <InputAdornment position="start">
          <IconSearch
            stroke={1.5}
            size={16}
            color={theme.palette.grey[500]}
          />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <ButtonBase sx={{ borderRadius: "12px" }}>
            <HeaderAvatarStyle variant="rounded">
              <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
            </HeaderAvatarStyle>
          </ButtonBase>
          <Box sx={{ ml: 2 }}>
            <ButtonBase sx={{ borderRadius: "12px" }}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  background: theme.palette.orange.light,
                  color: theme.palette.orange.dark,
                  "&:hover": {
                    background: theme.palette.orange.dark,
                    color: theme.palette.orange.light,
                  },
                }}
                {...bindToggle(popupState)}
              >
                <IconX stroke={1.5} size="1.3rem" />
              </Avatar>
            </ButtonBase>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    />
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState,
};

// ==============================|| SEARCH INPUT ||============================== //

const NewsletterSection = () => {
  const theme = useTheme();
  const [value, setValue] = useState("");

  const handleSubscribe = () => {
    
  }

  return (
    <>
      <Box sx={{ display: { md: "block" } }}>
        <OutlineInputStyle
          id="input-email-address-header"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Email address..."
          endAdornment={
            <InputAdornment position="end">
              <Button
                size="large"
                sx={{
                  borderRadius: "8px",
                  padding: "10px 20px",
                  mr: 0,
                  background: theme.palette.orange.light,
                  color: theme.palette.orange.dark,
                  "&:hover": {
                    background: theme.palette.orange.dark,
                    color: theme.palette.orange.light,
                  },
                }}
                onClick={handleSubscribe}
              >
                <Typography>Subscribe</Typography>
              </Button>
            </InputAdornment>
          }
          aria-describedby="email-address-helper-text"
          inputProps={{ "aria-label": "weight" }}
        />
      </Box>
    </>
  );
};

export default NewsletterSection;
