import { useContext, FC } from "react";

// mui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";

// project import
import { ThemeContext } from "ThemeContext";

// ==============================|| CUSTOM MAIN CARD ||============================== //

interface MainCardProps {
  border?: boolean;
  boxShadow?: boolean;
  children: React.ReactNode;
  content?: boolean;
  contentClass?: string;
  darkTitle?: boolean;
  secondary?: React.ReactNode;
  sx?: React.CSSProperties;
  shadow?: string;
  contentSX?: React.CSSProperties;
  title?: string;
  subtitle?: string;
}

const MainCard: FC<MainCardProps> = ({
  border = true,
  boxShadow,
  children,
  content = true,
  contentClass = "",
  contentSX = {},
  darkTitle,
  secondary,
  shadow,
  sx = {},
  title,
  ...others
}) => {
  const theme = useTheme();
  const context = useContext(ThemeContext);
  const darkMode = context.darkMode;

  const headerSX = {
    "& .MuiCardHeader-action": { mr: 0 },
    color: darkMode ? theme.palette.common.white : theme.palette.text.primary,
  };

  return (
    // @ts-ignore
    <Card
      {...others}
      // @ts-ignore
      sx={{
        border: border ? "1px solid" : "none",
        borderColor: darkMode
          ? theme.palette.secondary.light
          : theme.palette.primary[200] + 75,
        ":hover": {
          boxShadow: boxShadow
            ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)"
            : "inherit",
        },
        backgroundColor: darkMode
          ? theme.palette.secondary.main
          : theme.palette.background.default,
        ...sx,
      }}
    >
      {/* card header and action */}
      {!darkTitle && title && (
        <CardHeader
          sx={headerSX}
          title={
            <Typography
              variant="h3"
              sx={{
                color: darkMode
                  ? theme.palette.secondary.light
                  : theme.palette.text.primary,
              }}
            >
              {title}
            </Typography>
          }
          action={secondary}
        />
      )}
      {darkTitle && title && (
        <CardHeader
          sx={headerSX}
          title={
            <Typography
              variant="h3"
              sx={{
                color: darkMode
                  ? theme.palette.secondary.light
                  : theme.palette.text.primary,
              }}
            >
              {title}
            </Typography>
          }
          action={secondary}
        />
      )}

      {/* content & header divider */}
      {title && (
        <Divider
          sx={{
            borderColor: darkMode ? theme.palette.secondary.light : "inherit",
          }}
        />
      )}

      {/* card content */}
      {content && (
        <CardContent sx={contentSX} className={contentClass}>
          {children}
        </CardContent>
      )}
      {!content && children}
    </Card>
  );
};

export default MainCard;