export const getCurrentMoment = () => {
    const curr = new Date();
    let hour = curr.getHours();
    if (hour >= 20 || hour <= 5) {
        return "Good Night"
    } else if (hour > 5 && hour < 12) {
        return "Good Morning"
    } else if (hour >= 12 || hour < 5) {
        return "Good Afternoon"
    } else {
        return "Good Evening"
    }
};