export const LOAD_CART = "LOAD_CART";
export type LOAD_CART = typeof LOAD_CART;

export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART";
export type ADD_PRODUCT_TO_CART = typeof ADD_PRODUCT_TO_CART;

export const REMOVE_PRODUCT_FROM_CART = "REMOVE_PRODUCT_FROM_CART";
export type REMOVE_PRODUCT_FROM_CART = typeof REMOVE_PRODUCT_FROM_CART;

export const UPDATE_CART = "UPDATE_CART";
export type UPDATE_CART = typeof UPDATE_CART;
