import { lazy } from "react";

// projects imports
import FullLayout from "layout/FullLayout";
import Loadable from "ui-component/Loadable";

import { paths } from "navigation/paths";

// routes
const AboutPage = Loadable(lazy(() => import("views/pages/company/about-us")));
const AffliatesPage = Loadable(
  lazy(() => import("views/pages/company/affliate"))
);
const BecomeRetailerPage = Loadable(
  lazy(() => import("views/pages/company/be-a-retailer"))
);
const ClientsPage = Loadable(lazy(() => import("views/pages/company/clients")));
const JobsPage = Loadable(lazy(() => import("views/pages/company/jobs/main")));
const JobSearchPage = Loadable(
  lazy(() => import("views/pages/company/jobs/listing"))
);
const JobDetailsPage = Loadable(
  lazy(() => import("views/pages/company/jobs/details"))
);
const LegalPage = Loadable(
  lazy(() => import("views/pages/company/legal/legal-info"))
);
const LocationsPage = Loadable(
  lazy(() => import("views/pages/company/locations"))
);
const PrivacyPage = Loadable(
  lazy(() => import("views/pages/company/legal/privacy"))
);
const ThirdPartyPage = Loadable(
  lazy(() => import("views/pages/company/legal/third-party"))
);
const TermsPage = Loadable(
  lazy(() => import("views/pages/company/legal/t-and-c"))
);

// ==============================|| MAIN ROUTING ||============================== //

const CompanyRoutes = {
  path: "/",
  element: <FullLayout />,
  children: [
    {
      path: paths.JobsPage,
      element: <JobsPage />,
    },
    {
      path: paths.JobSearchPage,
      element: <JobSearchPage />,
    },
    {
      path: paths.JobDetailsPage,
      element: <JobDetailsPage />,
    },
    {
      path: paths.PrivacyPage,
      element: <PrivacyPage />,
    },
    {
      path: paths.ThirdPartyPage,
      element: <ThirdPartyPage />,
    },
    {
      path: paths.LegalPage,
      element: <LegalPage />,
    },
    {
      path: paths.TermsPage,
      element: <TermsPage />,
    },
    {
      path: paths.AboutPage,
      element: <AboutPage />,
    },
    {
      path: paths.ClientsPage,
      element: <ClientsPage />,
    },
    {
      path: paths.BecomeRetailerPage,
      element: <BecomeRetailerPage />,
    },
    {
      path: paths.LocationPage,
      element: <LocationsPage />,
    },
    {
      path: paths.AffliatesPage,
      element: <AffliatesPage />,
    },
  ],
};

export default CompanyRoutes;
