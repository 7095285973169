// assets
import { IconDashboard, IconChartBar, IconShoppingCart } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconChartBar, IconShoppingCart };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const shop = {
    id: 'shop',
    title: 'shop',
    type: 'collapse',
    icon: icons.IconShoppingCart,
    children: [
        {
            id: 'powertools',
            title: 'Powertools',
            type: 'item',
            url: '/products?c=powertools',
            breadcrumbs: false
        },
        {
            id: 'cleaning',
            title: 'Cleaning',
            type: 'item',
            url: '/products?c=cleaning',
            breadcrumbs: false
        },
        {
            id: 'compressor-airtools',
            title: 'Air Compressor & Air Tools',
            type: 'item',
            url: '/products?c=compressor-airtools',
            breadcrumbs: false
        },
        {
            id: 'water-pumps',
            title: 'Water Pumps',
            type: 'item',
            url: '/products?c=water-pumps',
            breadcrumbs: false
        },
        {
            id: 'diesel-welder-genset',
            title: 'Engines Gensets Pumps',
            type: 'item',
            url: '/products?c=diesel-welder-genset',
            breadcrumbs: false
        },
        {
            id: 'welding',
            title: 'Welding',
            type: 'item',
            url: '/products?c=welding',
            breadcrumbs: false
        },
        {
            id: 'agriculture',
            title: 'Agriculture Machinery',
            type: 'item',
            url: '/products?c=agriculture',
            breadcrumbs: false
        },
        {
            id: 'lawn-and-garden',
            title: 'Lawn & Garden',
            type: 'item',
            url: '/products?c=lawn-garden',
            breadcrumbs: false
        },
        {
            id: 'accessories',
            title: 'Accessories',
            type: 'item',
            url: '/products?c=accessories',
            breadcrumbs: false
        }
    ]
};

export default shop;
