export const categories = [
  {
    title: "Powertools",
    alias: "powertools",
    subCategories: [
      {
        title: "Grinders",
        alias: "grinders",
        db: "grinder"
      },
      {
        title: "Polishers",
        alias: "polishers",
        db: "polisher"
      },
      {
        title: "Trimmers",
        alias: "trimmers",
        db: "trimmer"
      },
      {
        title: "Sanders",
        alias: "sanders",
        db: "sander"
      },
      {
        title: "Hammers",
        alias: "hammers",
        db: "hammer"
      },
      {
        title: "Drills",
        alias: "drills",
      },
      {
        title: "Wrench",
        alias: "wrench",
      },
      {
        title: "Saw",
        alias: "saw",
      },
      {
        title: "Planar",
        alias: "planar",
      },
      {
        title: "Blower",
        alias: "blower",
      },
      {
        title: "Painting",
        alias: "painting",
      },
      {
        title: "Work Support",
        alias: "work-support",
        db: "worksupport"
      },
    ],
  },
  {
    title: "Cleaning",
    alias: "cleaning",
    subCategories: [
      {
        title: "High Pressure Cleaners",
        alias: "HPCs",
        db: "hpc"
      },
      {
        title: "Steam Cleaners",
        alias: "steam-cleaners",
        db: "steamcleaner"
      },
      {
        title: "Snow Wash",
        alias: "snow-wash",
        db: "snowwash"
      },
      {
        title: "Wet & Dry Vacuums",
        alias: "wet-dry-vacuums",
        db: "vacuum"
      },
      {
        title: "Air Mover",
        alias: "air-mover",
        db: "airmover"
      },
    ],
  },
  {
    title: "Air Compressor & Air Tools",
    alias: "compressor-airtools",
    subCategories: [
      {
        title: "Compressors",
        alias: "compressors",
      },
      {
        title: "Air Impact Wrench",
        alias: "air-impact-wrench",
        db: "airimpactwrench"
      },
      {
        title: "Air Die Grinder",
        alias: "air-die-grinder",
        db: "airdiegrinder"
      },
    ],
  },
  {
    title: "Water Pumps",
    alias: "water-pumps",
    subCategories: [
      {
        title: "Water Pumps",
        alias: "water-pumps",
        db: "waterpumps"
      },
    ],
  },
  {
    title: "Engines Gensets Pumps",
    alias: "diesel-welder-genset",
    subCategories: [
      {
        title: "Engines",
        alias: "engines",
        db: "engine"
      },
      {
        title: "Gensets",
        alias: "gensets",
        db: "genset"
      },
      {
        title: "Welder Gensets",
        alias: "welder-gensets",
        db: "weldergenset"
      },
      {
        title: "Inverter Gensets",
        alias: "inverter-gensets",
        db: "invertergenset"
      },
      {
        title: "Engine Pumps",
        alias: "engine-pumps",
        db: "enginepump"
      },
      {
        title: "Generators",
        alias: "generators",
        db: "generator"
      },
      {
        title: "Welders",
        alias: "welders",
        db: "welder"
      },
      {
        title: "ATS Units",
        alias: "ATS-units",
        db: "ATSUnit"
      },
      {
        title: "Lighting Tower Set",
        alias: "lighting-tower-set",
        db: "LTS"
      },
      {
        title: "Lube",
        alias: "lube",
      },
    ],
  },
  {
    title: "Welding",
    alias: "welding",
    subCategories: [
      {
        title: "MMA IGBT Inverter",
        alias: "MMA-IGBT-inverter",
        db: "MMA/IGBT"
      },
      {
        title: "MMA/MIG Inverter",
        alias: "MMA-MIG-inverter",
        db: "MMA/MIG"
      },
    ],
  },
  {
    title: "Agriculture Machinery",
    alias: "agriculture",
    subCategories: [
      {
        title: "Brush Cutters",
        alias: "brush-cutters",
        db: "brushcutters"
      },
    ],
  },
  {
    title: "Lawn & Garden",
    alias: "lawn-garden",
    subCategories: [
      {
        title: "Chain Saw",
        alias: "chain-saw",
        db: "chainsaw"
      },
    ],
  },
  {
    title: "Accessories",
    alias: "accessories",
    subCategories: [
      {
        title: "Powertools",
        alias: "powertools"
      },
      {
        title: "Cleaning",
        alias: "cleaning",
      },
      {
        title: "Air Compressor & Air Tools",
        alias: "compressor-airtools",
        db: "aircompressor"
      },

      {
        title: "Water Pumps",
        alias: "water-pumps",
        db: "waterpumps"
      },
      {
        title: "Engines Gensets Pumps",
        alias: "diesel-welder-genset",
        db: "engines"
      },
      {
        title: "Welding",
        alias: "welding",
        db: "welding"
      },
      {
        title: "Agriculture Machinery",
        alias: "agriculture",
      },

      {
        title: "Lawn & Garden",
        alias: "lawn-garden",
        db: "lawn"
      },
    ],
  },
];
