// assets
import { IconNews, IconPhone, IconMapPin} from '@tabler/icons';

// constant
const icons = { IconNews, IconPhone, IconMapPin };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'other',
    type: 'group',
    children: [
        // {
        //     id: 'blog',
        //     title: 'Blog',
        //     type: 'item',
        //     external: true,
        //     url: 'https://quasablog.godaddysites.com',
        //     icon: icons.IconNews,
        //     breadcrumbs: false
        // },
        {
            id: 'location',
            title: 'Location',
            type: 'item',
            url: '/company/locations',
            icon: icons.IconMapPin,
            target: true
        },
        {
            id: 'contact',
            title: 'Contact',
            type: 'item',
            url: '/support/contact',
            icon: icons.IconPhone,
            target: true
        }
    ]
};

export default other;
