export const SET_NOTIFICATION = "SET_NOTIFICATION";
export type SET_NOTIFICATION = typeof SET_NOTIFICATION;

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export type CREATE_NOTIFICATION = typeof CREATE_NOTIFICATION;

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export type GET_NOTIFICATION = typeof GET_NOTIFICATION;

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export type UPDATE_NOTIFICATION  = typeof UPDATE_NOTIFICATION;