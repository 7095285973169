import * as newsletterConstants from "store/constants/newsletter";
import { initialNewsletterState } from "store/state/newsletter";

export const newsletterReducer = (state = initialNewsletterState, action) => {
    switch (action.type) {
        case `${newsletterConstants.CREATE_NEWSLETTER_CANDIDATE}_PENDING`: 
            return {
                ...state,
                submittingNewsletter: true
            }
        case `${newsletterConstants.CREATE_NEWSLETTER_CANDIDATE}_FULFILLED`: 
            // console.log(action.payload)
            // const { items: productsList, nextToken } = action.payload;

            return {
                ...state,
                submittingNewsletter: false
            }
        case `${newsletterConstants.UPDATE_CANDIDATE_NEWSLETTER}_PENDING`: 
            return {
                ...state,
                fetchingProductDetails: true
            }
        case `${newsletterConstants.UPDATE_CANDIDATE_NEWSLETTER}_FULFILLED`: 
            const product = action.payload;

            return {
                ...state,
                productDetails: product,
                fetchingProductDetails: false
            }

        default: 
            return state
    }
}