import { Link } from "react-router-dom";

// mui
import { ButtonBase } from "@mui/material";

// project imports
import config from "../../../config";
import { LogoDark, LogoWhite } from "../../../ui-component/Logo";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ dark = false, width, height }) => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    {dark ? (
      <LogoDark width={width} height={height} />
    ) : (
      <LogoWhite width={width} height={height} />
    )}
  </ButtonBase>
);

export default LogoSection;
