import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import AuthLayout from "layout/AuthLayout";
import { paths } from "navigation/paths";

// login option 3 routing
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication/Login"))
);
const AuthRegister = Loadable(
  lazy(() => import("views/pages/authentication/authentication/Register"))
);

const AuthRegistrationConfirmation = Loadable(
  lazy(() => import("views/pages/authentication/authentication/RegistrationConfirmation"))
)

const AuthForgotPassword = Loadable(
  lazy(() => import("views/pages/authentication/authentication/ForgotPassword"))
);
const AuthResetPassword = Loadable(
  lazy(() => import("views/pages/authentication/authentication/ResetPassword"))
);

// general routes
//const LandingPage = Loadable(lazy(() => import('../views/pages/general/landing')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: paths.LoginPage,
      element: <AuthLogin />,
    },
    {
      path: paths.RegisterPage,
      element: <AuthRegister />,
    },
    {
      path: paths.RegistrationConfirmationPage,
      element: <AuthRegistrationConfirmation />,
    },
    {
      path: paths.ForgotPasswordPage,
      element: <AuthForgotPassword />,
    },
    {
      path: paths.ResetPasswordPage,
      element: <AuthResetPassword />,
    },
  ],
};

export default AuthenticationRoutes;
