export interface InitialJobState {
    jobs: any[];
    jobsNextToken: string | null;
    fetchingjobs: boolean;
    jobDetails: any;
    fetchingJobDetails: boolean;
}

export const initialState: InitialJobState = {
    jobs: [],
    jobsNextToken: null,
    fetchingjobs: false,
    jobDetails: null,
    fetchingJobDetails: false,
}