import { lazy } from 'react';

// project imports
import FullLayout from 'layout/FullLayout';
import Loadable from 'ui-component/Loadable';

import { paths } from "navigation/paths";

// general routes
const BlogPage = Loadable(lazy(() => import('views/pages/blog')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const BlogRoutes = {
    path: '/',
    element: <FullLayout />,
    children: [
        {
            path: '/blog',
            element: <BlogPage />
        },
    ]
};

export default BlogRoutes;
