import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';

// page routes
const WIPPage = Loadable(lazy(() => import('views/pages/extra/wip')));

// ==============================|| WIP ROUTING ||============================== //


const WIPRoute = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <WIPPage />,
        },
    ],
}


export default WIPRoute;
