import { Reducer } from "redux";
import * as ordersConstants from "store/constants/orders";
import { initialOrdersState, InitialOrdersState } from "store/state/orders";

export const ordersReducer: Reducer<InitialOrdersState, any> =
(state = initialOrdersState, action) => {
    switch (action.type) {
        case `${ordersConstants.LIST_ORDERS}_PENDING`: {
            return {
                ...state,
                fetchingOrders: true
            }
        }
        case `${ordersConstants.LIST_ORDERS}_FULFILLED`: {
            // const { orders } = action.payload;

            return {
                ...state,
                fetchingOrders: false,
            }
        }
        case `${ordersConstants.CANCEL_ORDER}_PENDING`: {
            return {
                ...state,
                fetchingOrders: true
            }
        }
        case `${ordersConstants.CANCEL_ORDER}_FULFILLED`: {
            // const { orders } = action.payload;

            return {
                ...state,
                fetchingOrders: false,
            }
        }

        default: 
            return state;
    }
}