import { Outlet } from "react-router-dom";
import clsx from "clsx";

// project imports
import Customization from "../Customization";

// styles
import styles from "views/styles/auth/background.module.scss";

// ==============================||  AUTH LAYOUT ||============================== //

const AuthLayout = () => (
  <>
    <div className={clsx(styles.bg, styles.hide)}>
      <div
        className={clsx(
          styles.circle,
          styles.small,
          styles.orange_dark_outline,
          styles.one
        )}
      />
      <div
        className={clsx(
          styles.squircle,
          styles.large,
          styles.orange_dark,
          styles.two
        )}
      />
      <div
        className={clsx(
          styles.circle,
          styles.small,
          styles.orange_dark,
          styles.three
        )}
      />
      <div
        className={clsx(
          styles.circle,
          styles.large,
          styles.orange_dark,
          styles.four
        )}
      />
      <div
        className={clsx(
          styles.squircle,
          styles.large,
          styles.orange_light,
          styles.five
        )}
      />
      <div
        className={clsx(
          styles.circle,
          styles.large,
          styles.orange_dark,
          styles.six
        )}
      />
      <div
        className={clsx(
          styles.squircle,
          styles.medium,
          styles.orange_light,
          styles.seven
        )}
      />
      <div
        className={clsx(
          styles.squircle,
          styles.medium,
          styles.orange_light,
          styles.eight
        )}
      />
      <div
        className={clsx(
          styles.circle,
          styles.small,
          styles.orange_dark,
          styles.nine
        )}
      />
      <div
        className={clsx(
          styles.squircle,
          styles.large,
          styles.orange_dark,
          styles.ten
        )}
      />
      <div
        className={clsx(
          styles.circle,
          styles.small,
          styles.orange_dark,
          styles.eleven
        )}
      />
      <div
        className={clsx(
          styles.circle,
          styles.xlarge,
          styles.orange_dark,
          styles.twelve
        )}
      />
      <div
        className={clsx(
          styles.squircle,
          styles.medium,
          styles.orange_main_outline,
          styles.thirteen
        )}
      />
      <div
        className={clsx(
          styles.circle,
          styles.xlarge,
          styles.orange_dark_outline,
          styles.fourteen
        )}
      />
      <div
        className={clsx(
          styles.circle,
          styles.medium,
          styles.orange_light,
          styles.fifteen
        )}
      />
      <div
        className={clsx(
          styles.circle,
          styles.large,
          styles.orange_light_outline,
          styles.sixteen
        )}
      />
      <div
        className={clsx(
          styles.circle,
          styles.small,
          styles.orange_main,
          styles.seventeen
        )}
      />
      <div
        className={clsx(
          styles.circle,
          styles.small,
          styles.orange_main,
          styles.eighteen
        )}
      />
      <Outlet />
    </div>
  </>
);

export default AuthLayout;
