import { Reducer } from "redux";
import * as cartConstants from "store/constants/cart";
import { initialCartState, InitialCartState } from "store/state/cart";

export const cartReducer: Reducer<InitialCartState, any> = 
(state = initialCartState, action) => {
    switch (action.type) {
        case `${cartConstants.LOAD_CART}_PENDING`: {
            return {
                ...state,
                fetchingCart: true
            }
        }

        case `${cartConstants.LOAD_CART}_FULFILLED`: {
            const { cart } = action.payload;
            return {
                ...state,
                cart: cart ? cart : [],
                fetchingCart: false,
            }
        }

        case `${cartConstants.ADD_PRODUCT_TO_CART}_PENDING`: {
            return {
                ...state,
                addingToCart: true
            }
        }
        
        case `${cartConstants.ADD_PRODUCT_TO_CART}_FULFILLED`: {
            const { cart } = action.payload;

            return {
                ...state,
                cart: cart ? cart : [],
                addingToCart: false
            }
        }

        case `${cartConstants.REMOVE_PRODUCT_FROM_CART}_PENDING`: {
            return {
                ...state,
                removingFromCart: true,
            }
        }

        case `${cartConstants.REMOVE_PRODUCT_FROM_CART}_FULFILLED`: {
            return {
                ...state,
                removingFromCart: false,
            }
        }
        default: 
            return state;
    }
}