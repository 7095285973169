import { Product } from "graphql/API";

export interface InitialFavoritesState {
    favoriteProductIds: any[];
    fetchingFavoriteProductIds?: boolean;
    lastUpdatedTime?: string;
    favoriteProducts: Product[];
    fetchingFavoriteProducts?: boolean;
    removingProductFromList?: boolean;
}

export const initialFavoritesState: InitialFavoritesState = {
    favoriteProductIds: [],
    fetchingFavoriteProductIds: false,
    favoriteProducts: [],
    removingProductFromList: false
}