import * as constants from "store/constants/user";
import { initialState } from "store/state/user";

const userReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case constants.SET_USER: {
            return {
                ...state, 
                user: action.payload
            }
        }
        case `${constants.GET_CURRENT_USER}_PENDING`: {
            return {
                ...state,
                fetchingCurrentUser: true
            }
        }
        case `${constants.GET_CURRENT_USER}_FULFILLED`: {
            const data = action.payload;
            return {
                ...state,
                fetchingCurrentUser: true,
                user: data
            }
        }
        case `${constants.GET_CURRENT_USER}_REJECTED`: {
            const { errors } = action.payload;
            
            return {
                ...state,
                fetchingCurrentUser: false,
                errorFetchingCurrentUser: errors[0]
            }
        }

        case constants.CREATE_USER: 
            return {
                ...state,
            }
        default:
            return state;
    }
}

export default userReducer;