import { lazy } from 'react';

// project imports
import FullLayout from 'layout/FullLayout';
import Loadable from 'ui-component/Loadable';

import { paths } from "navigation/paths";

// general routes
const LandingPage = Loadable(lazy(() => import('views/pages/general/landing')));
const PartServicesPage = Loadable(lazy(() => import('views/pages/general/parts-service')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <FullLayout/>,
    children: [
        {
            path: paths.LandingPage,
            element: <LandingPage />
        },
        {
            path: paths.PartsServices,
            element: <PartServicesPage />,
        }
    ]
};

export default MainRoutes;
