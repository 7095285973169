import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import BlogRoutes from "./BlogRoutes";
import CompanyRoutes from "./CompanyRoutes";
import DashboardRoutes from "./Dashboard";
import ErrorRoutes from "./ErrorRoutes";
import ShopRoutes from "./ShopRoutes";
import SupportRoutes from "./SupportRoutes";

import WIPRoute from "./WIPRoute";

import config from "config";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes(
    config.wip
      ? [WIPRoute]
      : [
          MainRoutes,
          BlogRoutes,
          DashboardRoutes,
          CompanyRoutes,
          ShopRoutes,
          SupportRoutes,
          AuthenticationRoutes,
          ErrorRoutes,
        ],
    config.basename
  );
}
