import { Product } from "graphql/API";

export interface InitialHistoryState {
    browsing: Product[];
    search: string[];
    fetchingBrowsingHistory: boolean;
    fetchingSearchHistory: boolean;
}

export const initialHistoryState: InitialHistoryState = {
    browsing: [],
    search: [],
    fetchingBrowsingHistory: false,
    fetchingSearchHistory: false
}