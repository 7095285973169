// mui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Typography,
} from "@mui/material";

const Section = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: '100%'
      }}
    >
      <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
        70% discount for 1 years <br />
        subscriptions.
      </Typography>
    </Box>
  );
};

export default Section;