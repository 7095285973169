export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_ERROR = 'API_ERROR';
export const CANCEL_API_REQUEST = 'CANCEL_API_REQUEST';
export const AUTHENTICATION_REQUEST = 'AUTHENTICATION_REQUEST';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';

export const apiRequest = ({ url, method, data, headers }) => {
    return {
        type: API_REQUEST,
        meta: { url, method, data, headers }
    };
};

export const cancelApiRequest = () => {
    return {
        type: CANCEL_API_REQUEST
    };
};

export const apiSuccess = ({ response }) => ({
    type: API_SUCCESS,
    payload: response
});

export const apiError = ({ error }) => ({
    type: API_ERROR,
    payload: error
});

export const authenticationRequest = ({ url, method }) => {
    return {
        type: AUTHENTICATION_REQUEST,
        meta: { url, method }
    };
};

export const authenticationSuccess = ({ response }) => ({
    type: AUTHENTICATION_SUCCESS,
    payload: response
});

export const authenticationError = ({ error }) => ({
    type: AUTHENTICATION_ERROR,
    payload: error
});
