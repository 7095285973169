// assets
import { IconPackage, IconCheckbox, IconRefresh, IconTruckDelivery, IconTrashOff, IconClipboardCheck, IconTools, IconShieldHalf } from '@tabler/icons';

import { dashboardPaths } from 'navigation/paths';

// constant
const icons = { IconPackage, IconCheckbox, IconRefresh, IconTruckDelivery, IconTrashOff, IconClipboardCheck, IconTools, IconShieldHalf };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const orders = {
    id: 'overview',
    title: 'My Orders',
    type: 'group',
    children: [
        {
            id: 'orders',
            title: 'Orders',
            type: 'item',
            url: dashboardPaths.OrdersPage,
            icon: icons.IconPackage,
            breadcrumbs: false
        },
        {
            id: 'my-products',
            title: 'My Products',
            type: 'item',
            url: dashboardPaths.MyProductsPage,
            icon: icons.IconTools,
            breadcrumbs: false
        },
        {
            id: 'buy-again',
            title: 'Buy Again',
            type: 'item',
            url: dashboardPaths.BuyAgainPage,
            icon: icons.IconRefresh,
            breadcrumbs: false
        },
        {
            id: 'in-process',
            title: 'In Process',
            type: 'item',
            url: dashboardPaths.InProcessPage,
            icon: icons.IconTruckDelivery,
            breadcrumbs: false
        },
        {
            id: 'cancelled-orders',
            title: 'Cancelled Orders',
            type: 'item',
            url: dashboardPaths.CancelledOrdersPage,
            icon: icons.IconTrashOff,
            breadcrumbs: false
        },
        {
            id: 'subscriptions',
            title: 'Subscriptions',
            type: 'item',
            url: dashboardPaths.SubscriptionsPage,
            icon: icons.IconClipboardCheck,
            breadcrumbs: false
        }
    ]
};

export default orders;
