import { createStore, compose, applyMiddleware } from 'redux';
import thunk  from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import rootReducer from './reducers';
import { apiMiddleware } from "./middlewares/apiMiddleware";
import { authMiddleware } from "./middlewares/authMiddleware";

const initialState = {};

// add midleware after 
const middlewares = [thunk, promiseMiddleware];

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(
    rootReducer,
    initialState,
    compose(
        applyMiddleware(
            ...middlewares,
            authMiddleware,
            apiMiddleware
        )
    )
);
const persister = 'Free';

export { store, persister };
