import { Reducer } from "redux";
import * as favoritesConstants from "store/constants/favorites"
import { initialFavoritesState, InitialFavoritesState } from "store/state/favorites"

export const favoritesReducer: Reducer<InitialFavoritesState, any> = 
(state = initialFavoritesState, action) => {
    switch (action.type) {
        case `${favoritesConstants.ADD_FAVORITE_PRODUCT_ID}_PENDING`: {
            return {
                ...state,
                fetchingFavoriteProductIds: true
            }
        }

        case `${favoritesConstants.ADD_FAVORITE_PRODUCT_ID}_FULFILLED`: {
            const { item } = action.payload;
            const { favoriteProducts } = state;

            return {
                ...state,
                fetchingFavoriteProductIds: false,
                favoriteProducts: [...favoriteProducts, item]
            }
        }

        case `${favoritesConstants.REMOVE_FAVORITE_PRODUCT_ID}_PENDING`: {
            // const { item } = action.payload;
            // const { favoriteProducts } = state;

            return {
                ...state,
                removingProductFromList: true,
            }
        }

        case `${favoritesConstants.REMOVE_FAVORITE_PRODUCT_ID}_FULFILLED`: {
            

            return {
                ...state,
                removingProductFromList: false,

            }
        }

        case `${favoritesConstants.LIST_FAVORITE_PRODUCTS}_PENDING`: {

            return {
                ...state,
                fetchingFavoriteProducts: true
            }
        }

        case `${favoritesConstants.LIST_FAVORITE_PRODUCTS}_FULFILLED`: {
            const { items } = action.payload;

            return {
                ...state,
                favoriteProducts: items,
                fetchingFavoriteProducts: false
            }
        }

        default: 
            return state;
    }
}