import * as React from "react";

// mui
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useTheme } from "@mui/material/styles";

// project imports
import FilterActionButton from "./filter";
import MenuActionButton from "./menu";
import { drawerWidth } from "store/constants/styles";

export default function FABs() {
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={1}
      xs={12}
      md={12}
      display="flex"
      justifyContent="center"
      sx={{
        [theme.breakpoints.down("md")]: {
          width: `100%`,
        },
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
        position: "fixed",
        bottom: 20,
        height: "7.5vh",
        zIndex: 1000,
      }}
    >
      <Grid xs={12} md={12} display="flex"
        sx={{
          [theme.breakpoints.down("md")]: {
            width: `97.5vw`,
          },
        }}
      >
        <FilterActionButton />
        {/* <MenuActionButton /> */}
      </Grid>
    </Grid>
  );
}
