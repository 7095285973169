/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getProduct = /* GraphQL */ `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    title
    description
    brand
    category
    sub_category
    model_no
    product_code
    price
    quantity
    specifications {
      title
      specification
      __typename
    }
    currency
    images
    in_the_box {
      productID
      quantity
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductQueryVariables,
  APITypes.GetProductQuery
>;
export const listProducts = /* GraphQL */ `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      brand
      category
      sub_category
      model_no
      product_code
      price
      quantity
      currency
      images
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsQueryVariables,
  APITypes.ListProductsQuery
>;
export const searchProducts = /* GraphQL */ `query SearchProducts(
  $filter: SearchableProductFilterInput
  $sort: [SearchableProductSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableProductAggregationInput]
) {
  searchProducts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title
      description
      brand
      category
      sub_category
      model_no
      product_code
      price
      quantity
      currency
      images
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductsQueryVariables,
  APITypes.SearchProductsQuery
>;
export const getProductReview = /* GraphQL */ `query GetProductReview($id: ID!) {
  getProductReview(id: $id) {
    id
    productID
    header
    description
    purchased_area
    locationID
    rating
    external_votes {
      upvote
      downvote
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductReviewQueryVariables,
  APITypes.GetProductReviewQuery
>;
export const listProductReviews = /* GraphQL */ `query ListProductReviews(
  $filter: ModelProductReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      productID
      header
      description
      purchased_area
      locationID
      rating
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductReviewsQueryVariables,
  APITypes.ListProductReviewsQuery
>;
export const searchProductReviews = /* GraphQL */ `query SearchProductReviews(
  $filter: SearchableProductReviewFilterInput
  $sort: [SearchableProductReviewSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableProductReviewAggregationInput]
) {
  searchProductReviews(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      productID
      header
      description
      purchased_area
      locationID
      rating
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductReviewsQueryVariables,
  APITypes.SearchProductReviewsQuery
>;
export const getProductCollection = /* GraphQL */ `query GetProductCollection($id: ID!) {
  getProductCollection(id: $id) {
    id
    title
    description
    product_code
    condition_type
    collection
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductCollectionQueryVariables,
  APITypes.GetProductCollectionQuery
>;
export const listProductCollections = /* GraphQL */ `query ListProductCollections(
  $filter: ModelProductCollectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductCollections(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      product_code
      condition_type
      collection
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductCollectionsQueryVariables,
  APITypes.ListProductCollectionsQuery
>;
export const searchProductCollections = /* GraphQL */ `query SearchProductCollections(
  $filter: SearchableProductCollectionFilterInput
  $sort: [SearchableProductCollectionSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableProductCollectionAggregationInput]
) {
  searchProductCollections(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title
      description
      product_code
      condition_type
      collection
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductCollectionsQueryVariables,
  APITypes.SearchProductCollectionsQuery
>;
export const getProductBundle = /* GraphQL */ `query GetProductBundle($id: ID!) {
  getProductBundle(id: $id) {
    id
    title
    sub_title
    description
    product_code
    products {
      product_id
      quantity
      __typename
    }
    price
    usage {
      description
      __typename
    }
    currency
    images
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductBundleQueryVariables,
  APITypes.GetProductBundleQuery
>;
export const listProductBundles = /* GraphQL */ `query ListProductBundles(
  $filter: ModelProductBundleFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductBundles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      sub_title
      description
      product_code
      price
      currency
      images
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductBundlesQueryVariables,
  APITypes.ListProductBundlesQuery
>;
export const searchProductBundles = /* GraphQL */ `query SearchProductBundles(
  $filter: SearchableProductBundleFilterInput
  $sort: [SearchableProductBundleSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableProductBundleAggregationInput]
) {
  searchProductBundles(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title
      sub_title
      description
      product_code
      price
      currency
      images
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductBundlesQueryVariables,
  APITypes.SearchProductBundlesQuery
>;
export const getJob = /* GraphQL */ `query GetJob($id: ID!) {
  getJob(id: $id) {
    id
    title
    description
    responsibilities {
      description
      __typename
    }
    qualifications {
      description
      __typename
    }
    benefits {
      description
      __typename
    }
    type
    category
    location_type
    country
    state
    city
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetJobQueryVariables, APITypes.GetJobQuery>;
export const listJobs = /* GraphQL */ `query ListJobs($filter: ModelJobFilterInput, $limit: Int, $nextToken: String) {
  listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      type
      category
      location_type
      country
      state
      city
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListJobsQueryVariables, APITypes.ListJobsQuery>;
export const searchJobs = /* GraphQL */ `query SearchJobs(
  $filter: SearchableJobFilterInput
  $sort: [SearchableJobSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableJobAggregationInput]
) {
  searchJobs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title
      description
      type
      category
      location_type
      country
      state
      city
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchJobsQueryVariables,
  APITypes.SearchJobsQuery
>;
export const getNewsletterCandidate = /* GraphQL */ `query GetNewsletterCandidate($id: ID!) {
  getNewsletterCandidate(id: $id) {
    id
    email
    active
    reason
    types {
      latest_updates
      tips_and_tutorials
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNewsletterCandidateQueryVariables,
  APITypes.GetNewsletterCandidateQuery
>;
export const listNewsletterCandidates = /* GraphQL */ `query ListNewsletterCandidates(
  $filter: ModelNewsletterCandidateFilterInput
  $limit: Int
  $nextToken: String
) {
  listNewsletterCandidates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      active
      reason
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNewsletterCandidatesQueryVariables,
  APITypes.ListNewsletterCandidatesQuery
>;
export const getMarketingCandidate = /* GraphQL */ `query GetMarketingCandidate($id: ID!) {
  getMarketingCandidate(id: $id) {
    id
    user_id
    active
    reason
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMarketingCandidateQueryVariables,
  APITypes.GetMarketingCandidateQuery
>;
export const listMarketingCandidates = /* GraphQL */ `query ListMarketingCandidates(
  $filter: ModelMarketingCandidateFilterInput
  $limit: Int
  $nextToken: String
) {
  listMarketingCandidates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      active
      reason
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMarketingCandidatesQueryVariables,
  APITypes.ListMarketingCandidatesQuery
>;
export const getRetailer = /* GraphQL */ `query GetRetailer($id: ID!) {
  getRetailer(id: $id) {
    id
    name
    address {
      addr1
      addr2
      postal
      city
      state
      country
      map_location
      contact_num
      opening_time
      opening_days
      email
      __typename
    }
    email
    contact_num
    user_ids
    website
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRetailerQueryVariables,
  APITypes.GetRetailerQuery
>;
export const listRetailers = /* GraphQL */ `query ListRetailers(
  $filter: ModelRetailerFilterInput
  $limit: Int
  $nextToken: String
) {
  listRetailers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      contact_num
      user_ids
      website
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRetailersQueryVariables,
  APITypes.ListRetailersQuery
>;
export const searchRetailers = /* GraphQL */ `query SearchRetailers(
  $filter: SearchableRetailerFilterInput
  $sort: [SearchableRetailerSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableRetailerAggregationInput]
) {
  searchRetailers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      name
      email
      contact_num
      user_ids
      website
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchRetailersQueryVariables,
  APITypes.SearchRetailersQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    customer_id
    username
    email
    family_name
    given_name
    type
    password
    location
    contact_num
    country
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      customer_id
      username
      email
      family_name
      given_name
      type
      password
      location
      contact_num
      country
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const searchUsers = /* GraphQL */ `query SearchUsers(
  $filter: SearchableUserFilterInput
  $sort: [SearchableUserSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableUserAggregationInput]
) {
  searchUsers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      customer_id
      username
      email
      family_name
      given_name
      type
      password
      location
      contact_num
      country
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchUsersQueryVariables,
  APITypes.SearchUsersQuery
>;
export const getEmployee = /* GraphQL */ `query GetEmployee($id: ID!) {
  getEmployee(id: $id) {
    employee_id
    username
    email
    family_name
    given_name
    type
    password
    location
    contact_num
    country
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmployeeQueryVariables,
  APITypes.GetEmployeeQuery
>;
export const listEmployees = /* GraphQL */ `query ListEmployees(
  $filter: ModelEmployeeFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      employee_id
      username
      email
      family_name
      given_name
      type
      password
      location
      contact_num
      country
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmployeesQueryVariables,
  APITypes.ListEmployeesQuery
>;
export const searchEmployees = /* GraphQL */ `query SearchEmployees(
  $filter: SearchableEmployeeFilterInput
  $sort: [SearchableEmployeeSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableEmployeeAggregationInput]
) {
  searchEmployees(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      employee_id
      username
      email
      family_name
      given_name
      type
      password
      location
      contact_num
      country
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchEmployeesQueryVariables,
  APITypes.SearchEmployeesQuery
>;
export const getCustomer = /* GraphQL */ `query GetCustomer($id: ID!) {
  getCustomer(id: $id) {
    id
    type
    name
    phone
    country
    email
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerQueryVariables,
  APITypes.GetCustomerQuery
>;
export const listCustomers = /* GraphQL */ `query ListCustomers(
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      name
      phone
      country
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomersQueryVariables,
  APITypes.ListCustomersQuery
>;
export const searchCustomers = /* GraphQL */ `query SearchCustomers(
  $filter: SearchableCustomerFilterInput
  $sort: [SearchableCustomerSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableCustomerAggregationInput]
) {
  searchCustomers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      type
      name
      phone
      country
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCustomersQueryVariables,
  APITypes.SearchCustomersQuery
>;
export const getCoupon = /* GraphQL */ `query GetCoupon($id: ID!) {
  getCoupon(id: $id) {
    owner
    coupon_code
    promotion_type
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCouponQueryVariables, APITypes.GetCouponQuery>;
export const listCoupons = /* GraphQL */ `query ListCoupons(
  $filter: ModelCouponFilterInput
  $limit: Int
  $nextToken: String
) {
  listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      owner
      coupon_code
      promotion_type
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCouponsQueryVariables,
  APITypes.ListCouponsQuery
>;
export const getPromotion = /* GraphQL */ `query GetPromotion($id: ID!) {
  getPromotion(id: $id) {
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPromotionQueryVariables,
  APITypes.GetPromotionQuery
>;
export const listPromotions = /* GraphQL */ `query ListPromotions(
  $filter: ModelPromotionFilterInput
  $limit: Int
  $nextToken: String
) {
  listPromotions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPromotionsQueryVariables,
  APITypes.ListPromotionsQuery
>;
export const getBrowsingHistory = /* GraphQL */ `query GetBrowsingHistory($id: ID!) {
  getBrowsingHistory(id: $id) {
    id
    customer_id
    viewed {
      product_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBrowsingHistoryQueryVariables,
  APITypes.GetBrowsingHistoryQuery
>;
export const listBrowsingHistories = /* GraphQL */ `query ListBrowsingHistories(
  $filter: ModelBrowsingHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listBrowsingHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      customer_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBrowsingHistoriesQueryVariables,
  APITypes.ListBrowsingHistoriesQuery
>;
export const getSearchHistory = /* GraphQL */ `query GetSearchHistory($id: ID!) {
  getSearchHistory(id: $id) {
    id
    customer_id
    viewed {
      product_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSearchHistoryQueryVariables,
  APITypes.GetSearchHistoryQuery
>;
export const listSearchHistories = /* GraphQL */ `query ListSearchHistories(
  $filter: ModelSearchHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listSearchHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      customer_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSearchHistoriesQueryVariables,
  APITypes.ListSearchHistoriesQuery
>;
export const getFavoriteItems = /* GraphQL */ `query GetFavoriteItems($id: ID!) {
  getFavoriteItems(id: $id) {
    id
    customer_id
    favorite {
      product_id
      createdAt
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFavoriteItemsQueryVariables,
  APITypes.GetFavoriteItemsQuery
>;
export const listFavoriteItems = /* GraphQL */ `query ListFavoriteItems(
  $filter: ModelFavoriteItemsFilterInput
  $limit: Int
  $nextToken: String
) {
  listFavoriteItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      customer_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFavoriteItemsQueryVariables,
  APITypes.ListFavoriteItemsQuery
>;
export const getCart = /* GraphQL */ `query GetCart($id: ID!) {
  getCart(id: $id) {
    id
    products {
      product_id
      quantity
      current_price
      last_seen_price
      __typename
    }
    status
    user_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCartQueryVariables, APITypes.GetCartQuery>;
export const listCarts = /* GraphQL */ `query ListCarts(
  $filter: ModelCartFilterInput
  $limit: Int
  $nextToken: String
) {
  listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      status
      user_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCartsQueryVariables, APITypes.ListCartsQuery>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    cart_id
    paid
    payment_mode
    manual
    date
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cart_id
      paid
      payment_mode
      manual
      date
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const getInvoice = /* GraphQL */ `query GetInvoice($id: ID!) {
  getInvoice(id: $id) {
    id
    user_id
    date
    expire_date
    total
    paid
    status
    created_by
    items {
      product_id
      description
      quantity
      price
      __typename
    }
    note
    currency
    payment_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceQueryVariables,
  APITypes.GetInvoiceQuery
>;
export const listInvoices = /* GraphQL */ `query ListInvoices(
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user_id
      date
      expire_date
      total
      paid
      status
      created_by
      note
      currency
      payment_ids
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoicesQueryVariables,
  APITypes.ListInvoicesQuery
>;
export const getProformaInvoice = /* GraphQL */ `query GetProformaInvoice($id: ID!) {
  getProformaInvoice(id: $id) {
    id
    user_id
    date
    expire_date
    total
    paid
    status
    created_by
    items {
      product_id
      description
      quantity
      price
      __typename
    }
    note
    currency
    payment_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProformaInvoiceQueryVariables,
  APITypes.GetProformaInvoiceQuery
>;
export const listProformaInvoices = /* GraphQL */ `query ListProformaInvoices(
  $filter: ModelProformaInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listProformaInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user_id
      date
      expire_date
      total
      paid
      status
      created_by
      note
      currency
      payment_ids
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProformaInvoicesQueryVariables,
  APITypes.ListProformaInvoicesQuery
>;
export const getPayment = /* GraphQL */ `query GetPayment($id: ID!) {
  getPayment(id: $id) {
    customer_id
    payment_mode
    invoice_id
    amount
    reference
    description
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentQueryVariables,
  APITypes.GetPaymentQuery
>;
export const listPayments = /* GraphQL */ `query ListPayments(
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      customer_id
      payment_mode
      invoice_id
      amount
      reference
      description
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentsQueryVariables,
  APITypes.ListPaymentsQuery
>;
export const getProductRepair = /* GraphQL */ `query GetProductRepair($id: ID!) {
  getProductRepair(id: $id) {
    product_id
    tracking_id
    stage
    price
    under_warranty
    product_user {
      productId
      unitPrice
      quantity
      UOM
      subTotal
      __typename
    }
    discounted_price
    misc
    labourCharges
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductRepairQueryVariables,
  APITypes.GetProductRepairQuery
>;
export const listProductRepairs = /* GraphQL */ `query ListProductRepairs(
  $filter: ModelProductRepairFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductRepairs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      product_id
      tracking_id
      stage
      price
      under_warranty
      discounted_price
      misc
      labourCharges
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductRepairsQueryVariables,
  APITypes.ListProductRepairsQuery
>;
export const searchProductRepairs = /* GraphQL */ `query SearchProductRepairs(
  $filter: SearchableProductRepairFilterInput
  $sort: [SearchableProductRepairSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableProductRepairAggregationInput]
) {
  searchProductRepairs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      product_id
      tracking_id
      stage
      price
      under_warranty
      discounted_price
      misc
      labourCharges
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductRepairsQueryVariables,
  APITypes.SearchProductRepairsQuery
>;
export const getWarranty = /* GraphQL */ `query GetWarranty($id: ID!) {
  getWarranty(id: $id) {
    product_id
    current_price
    user_id
    model_no
    product_code
    retailer_name
    receipts
    status
    reason
    extension_ids
    order_id
    verification_user_id
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWarrantyQueryVariables,
  APITypes.GetWarrantyQuery
>;
export const listWarranties = /* GraphQL */ `query ListWarranties(
  $filter: ModelWarrantyFilterInput
  $limit: Int
  $nextToken: String
) {
  listWarranties(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      product_id
      current_price
      user_id
      model_no
      product_code
      retailer_name
      receipts
      status
      reason
      extension_ids
      order_id
      verification_user_id
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWarrantiesQueryVariables,
  APITypes.ListWarrantiesQuery
>;
export const getWarrantyExtension = /* GraphQL */ `query GetWarrantyExtension($id: ID!) {
  getWarrantyExtension(id: $id) {
    thru_date
    approval_user_id
    approval_reasons
    amount_paid
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWarrantyExtensionQueryVariables,
  APITypes.GetWarrantyExtensionQuery
>;
export const listWarrantyExtensions = /* GraphQL */ `query ListWarrantyExtensions(
  $filter: ModelWarrantyExtensionFilterInput
  $limit: Int
  $nextToken: String
) {
  listWarrantyExtensions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      thru_date
      approval_user_id
      approval_reasons
      amount_paid
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWarrantyExtensionsQueryVariables,
  APITypes.ListWarrantyExtensionsQuery
>;
export const getNotification = /* GraphQL */ `query GetNotification($id: ID!) {
  getNotification(id: $id) {
    email_latest_update
    email_tips_and_tutorials
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationQueryVariables,
  APITypes.GetNotificationQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      email_latest_update
      email_tips_and_tutorials
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const fetchCheckoutURL = /* GraphQL */ `query FetchCheckoutURL($input: AWSJSON) {
  fetchCheckoutURL(input: $input)
}
` as GeneratedQuery<
  APITypes.FetchCheckoutURLQueryVariables,
  APITypes.FetchCheckoutURLQuery
>;
export const fetchLookupData = /* GraphQL */ `query FetchLookupData($input: String) {
  fetchLookupData(input: $input)
}
` as GeneratedQuery<
  APITypes.FetchLookupDataQueryVariables,
  APITypes.FetchLookupDataQuery
>;
