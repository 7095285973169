export const ADD_FAVORITE_PRODUCT = "ADD_FAVORITE_PRODUCT";
export type ADD_FAVORITE_PRODUCT = typeof ADD_FAVORITE_PRODUCT;

export const ADD_FAVORITE_PRODUCT_ID = "ADD_FAVORITE_PRODUCT_ID";
export type ADD_FAVORITE_PRODUCT_ID = typeof ADD_FAVORITE_PRODUCT_ID;

export const REMOVE_FAVORITE_PRODUCT_ID = "REMOVE_FAVORITE_PRODUCT_ID";
export type REMOVE_FAVORITE_PRODUCT_ID = typeof REMOVE_FAVORITE_PRODUCT_ID;

export const LIST_FAVORITE_PRODUCTS = "LIST_FAVORITE_PRODUCTS";
export type LIST_FAVORITE_PRODUCTS = typeof LIST_FAVORITE_PRODUCTS;