import React from "react";
import { createRoot } from "react-dom/client";
import axios from "axios";
// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";

// project imports
import "./index.css";
import App from "./App";
import { store } from "./store";
import { IntlWrapper } from "./i18n/Intl"
import { CartProvider } from "use-shopping-cart";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

axios.defaults.baseURL = "http://www.api.quasaonline.com";
const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.post["Content-Type"] = "application/json";

// const aws_exports_override = { ...config, aws_appsync_authenticationType: "AWS_IAM" }
Amplify.configure(config);
const STRIPE_PUBLISHABLE_KEY = process.env.PUBLIC_STRIPE_PUBLISHABLE_KEY;

const run = () => {
  const container = document.getElementById("root") as HTMLElement;
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <CookiesProvider>
        <CartProvider
          shouldPersist={true}
          cartMode="checkout-session"
          stripe={STRIPE_PUBLISHABLE_KEY || ""}
          currency="MYR"
          loading={<p aria-live="polite">Loading redux-persist...</p>}
        >
          <Provider store={store}>
            <IntlWrapper>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </IntlWrapper>
          </Provider>
        </CartProvider>
      </CookiesProvider>
    </React.StrictMode>
  );
};

const loadedState = ["complete", "loaded", "interactive"];

if (loadedState.indexOf(document.readyState) > -1 && document.body) {
  run();
} else {
  window.addEventListener("DOMContentLoaded", run, false);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
