import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Loadable from "react-loadable";

// mui
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { MobileView } from 'react-device-detect';

// project imports
import Footer from "../Footer";
import Sidebar from "./Sidebar";
import Notification from "./Notification";
import FABs from "ui-component/fab";

// import Sidebar from './Sidebar';
import Customization from "../Customization";
import navigation from "../../menu-items";
import { drawerWidth } from "../../store/constants";
import { SET_MENU } from "../../store/actions";

// assets
import { IconChevronRight } from "@tabler/icons";
import Features from "../../fac/features";
import { paths } from "navigation/paths";

const Header = Loadable({
  loader: () => import("./Header"),
  loading: CircularProgress,
});


// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, backgroundcolor }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      backgroundColor: backgroundcolor
        ? backgroundcolor
        : `${theme.palette.orange.light} !important`,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: "15px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
        marginRight: "10px",
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const PRODUCT_ROUTES = paths.ProductsPage;
const PRODUCT_DETAILS_ROUTE = paths.ProductDetailsPageRaw;

const FullLayout = ({ backgroundColor }) => {
  const theme = useTheme();
  const matchUpSx = useMediaQuery(theme.breakpoints.up("sx"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  const isProductRoute = window.location.href.indexOf(PRODUCT_ROUTES) !== -1 &&
    window.location.href.indexOf(PRODUCT_DETAILS_ROUTE) === -1
  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchUpSx });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchUpSx]);

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", mb: 2.5 }}>
        <CssBaseline />

        {/* header */}
        <AppBar
          enableColorOnDark
          position="absolute"
          color="inherit"
          elevation={0}
          sx={{
            transition: leftDrawerOpened
              ? theme.transitions.create("width")
              : "none",
          }}
        >
          {/* notifications */}
          {Features.isNotificationEnabled() && (
            <Notification enableClose={true} />
          )}
          <Toolbar sx={{
            backgroundColor: theme.palette.grey[900],
          }}>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
            {/* {matchDownMd &&
              <Grid xs={12}>
                <MobileSearch />
              </Grid>
            } */}
          </Toolbar>

        </AppBar>

        {/* drawer */}
        {matchDownMd &&
          <Sidebar
            drawerOpen={leftDrawerOpened}
            drawerToggle={handleLeftDrawerToggle}
          />
        }

        {/* main content */}
        <Main theme={theme} backgroundcolor={backgroundColor}>

          {/* <Breadcrumbs
            separator={IconChevronRight}
            navigation={navigation}
            icon
            title
            rightAlign
          /> */}
          <Outlet />

        </Main>

        {isProductRoute &&
          <FABs />
        }
      </Box>
      <Footer />
    </>
  );
};

export default FullLayout;
