export const LIST_ORDERS = "LIST_ORDERS";
export type LIST_ORDERS = typeof LIST_ORDERS;

export const CANCEL_ORDER = "CANCEL_ORDER";
export type CANCEL_ORDER = typeof CANCEL_ORDER;

// export const REMOVE_PRODUCT_FROM_CART = "REMOVE_PRODUCT_FROM_CART";
// export type REMOVE_PRODUCT_FROM_CART = typeof REMOVE_PRODUCT_FROM_CART;

// export const UPDATE_CART = "UPDATE_CART";
// export type UPDATE_CART = typeof UPDATE_CART;
