import { lazy } from 'react';

// projects imports 
import FullLayout from 'layout/FullLayout';
import Loadable from 'ui-component/Loadable';

import { paths } from "navigation/paths";

// routes
const AccountFAQPage = Loadable(lazy(() => import('views/pages/support/faq-account')));
const ProductFAQPage = Loadable(lazy(() => import('views/pages/support/faq-product')));
const ContactPage = Loadable(lazy(() => import('views/pages/support/contact-us')));
const LiveChatPage = Loadable(lazy(() => import('views/pages/support/live-chat')));
const TrackOrderPage = Loadable(lazy(() => import('views/pages/support/order-tracker')));
const ReturnsPage = Loadable(lazy(() => import('views/pages/support/returns')));
const RegisterProductPage = Loadable(lazy(() => import('views/pages/support/product-registration')));
const WarrantyPage = Loadable(lazy(() => import('views/pages/support/warranty')));
const FindRetailersPage = Loadable(lazy(() => import('views/pages/support/find-retailer')));

// ==============================|| MAIN ROUTING ||============================== //

const SupportRoutes = {
    path: '/',
    element: <FullLayout />,
    children: [
        {
            path: paths.ContactPage,
            element: <ContactPage />
        }, 
        {
            path: paths.LiveChatPage,
            element: <LiveChatPage />
        },
        {
            path: paths.TrackOrderPage,
            element: <TrackOrderPage />
        },
        {
            path: paths.ReturnsPage,
            element: <ReturnsPage />
        },
        {
            path: paths.RegisterProductPage,
            element: <RegisterProductPage />
        },
        {
            path: paths.AccountFAQPage,
            element: <AccountFAQPage />,
        },
        {
            path: paths.ProductFAQPage,
            element: <ProductFAQPage />,
        },
        {
            path: paths.WarrantyPage,
            element: <WarrantyPage />,
        },
        {
            path: paths.FindRetailerPage,
            element: <FindRetailersPage />,
        }
    ]
}

export default SupportRoutes;