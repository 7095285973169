import typography from "themes/base/typography";
import borders from "themes/base/borders";
import colors from "themes/base/colors";

// Material Kit 2 React helper functions
import pxToRem from "themes/functions/pxToRem";
import { ComponentsOverrides } from "@mui/material/styles";

const { size, fontWeightRegular } = typography;
const { borderRadius } = borders;
const { dark } = colors;

interface StylesProps<Theme = unknown> {
  styleOverrides: ComponentsOverrides<Theme>['MuiTab'];
}

const styles: StylesProps = {
  styleOverrides: {
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      flex: "1 1 auto",
      textAlign: "center",
      width: 100,
      maxWidth: "unset !important",
      minWidth: "unset !important",
      minHeight: "unset !important",
      fontSize: size.xs,
      fontWeight: fontWeightRegular,
      textTransform: "none",
      lineHeight: "inherit",
      padding: pxToRem(4),
      borderRadius: borderRadius.lg,
      color: `${dark.main} !important`,
      opacity: "1 !important",

      "& .material-icons, .material-icons-round": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },

      "& svg": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },

      "& i.MuiTab-iconWrapper": {
        marginBottom: 0,
      },
    },

    labelIcon: {
      paddingTop: pxToRem(4),
    },
  },
};

export default styles;
