import Loadable from "react-loadable";
import { dashboardPaths } from "navigation/paths";

import { CircularProgress } from "@mui/material";

const Warranty = Loadable({
  loader: () => import("views/pages/dashboard/services/warranty"),
  loading: CircularProgress,
});

const CheckProduct = Loadable({
  loader: () => import("views/pages/dashboard/services/warranty/verification"),
  loading: CircularProgress,
});

interface Routes {
  path: string;
  element: React.ReactElement;
}

// As part of dashboard routes
const ServicesRoutes: Routes[] = [
  {
    path: dashboardPaths.WarrantyPage,
    element: <Warranty />,
  },
  {
    path: dashboardPaths.CheckProduct,
    element: <CheckProduct />,
  },
];

export default ServicesRoutes;
