import { Theme, ThemeOptions, createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
/**
 * Represent theme style and structure as per mui
 * @param {JsonObject} customization customization parameter object
 */

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        mobile: true; // adds the `mobile` breakpoint
        tablet: true;
        laptop: true;
        desktop: true;
    }
    interface TypographyVariants {
        customInput: any// React.CSSProperties ; 
        settingsInput: any;
        mainContent: any;
        menuCaption: any;
        subMenuCaption: any;
        commonAvatar: React.CSSProperties;
        smallAvatar: React.CSSProperties; 
        mediumAvatar: React.CSSProperties; 
        largeAvatar: React.CSSProperties; 
    }
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        customInput?: any//React.CSSProperties;
        settingsInput?: any;
        mainContent?: any;
        menuCaption?: any;
        subMenuCaption?: any;
        commonAvatar?: React.CSSProperties;
        smallAvatar?: React.CSSProperties; 
        mediumAvatar?: React.CSSProperties; 
        largeAvatar?: React.CSSProperties; 
    }
    interface Palette {
        orange: Palette['primary'];
    }

    interface PaletteOptions {
        orange?: PaletteOptions['primary'];
    }
}

export const theme = (customization) => {
    const color = colors;

    const themeOption = {
        colors: color,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: color.primaryLight,
        darkTextPrimary: color.grey700,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        menuSelected: color.primaryDark,
        menuSelectedBack: color.primaryLight,
        divider: color.grey200,
        customization
    };

    const themeOptions: ThemeOptions = {
        direction: 'ltr',
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        typography: themeTypography(themeOption),
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
                mobile: 0,
                tablet: 640,
                laptop: 1024,
                desktop: 1200,
            },
        },
    };

    const themes: Theme = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;
