import orders from './orders';
import utilities from './utilities';
import services from './services';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [orders, services, utilities]
};

export default menuItems;
