import React, { Component } from "react";
import Cookies from "js-cookie";
import ReactGA from 'react-ga4';
import "views/styles/components/CookieBanner.scss";
import { paths } from "navigation/paths";

interface IComponentProps {
  debug?: boolean;
  privacyLink?: string
  termsLink?: string
  headline: string
  body: React.ReactNode
}

interface IOwnState {
  visible: boolean
}

class CookieBanner extends Component<IComponentProps, IOwnState> {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  initializeGoogleAnalytics = () => {
    ReactGA.initialize([
      {
        trackingId: "G-0L92YXFSG3"
      }
    ])
  }

  componentDidMount() {
    const { debug } = this.props;
    // if cookie undefined or debug
    if (Cookies.get("qckis") === undefined || debug) {
      document.body.style.overflow = "hidden";
      this.setState({ visible: true });
    } else if (Cookies.get("qckis")) {
      // initialize react ga if cookies are set
      this.initializeGoogleAnalytics();
    }
  }

  componentDidUpdate() {
    const { debug } = this.props;
    if (
      window.location.href.includes(paths.PrivacyPage) ||
      window.location.href.includes(paths.TermsPage) ||
      window.location.href.includes(paths.LegalPage)
    ) {
      document.body.style.overflow = "scroll";
    } else if (Cookies.get("qckis") === undefined || debug) {
      document.body.style.overflow = "hidden";
    }
  }

  accept = () => {
    Cookies.set("qckis", true, { sameSite: "strict", expires: 365 });
    this.initializeGoogleAnalytics();
    this.setState({ visible: false });
    document.body.style.overflow = "scroll";
  };

  decline = () => {
    Cookies.set("qckis", false, { sameSite: "strict", expires: 7 });
    this.setState({ visible: false });
    document.body.style.overflow = "scroll";
  };

  render() {
    if (
      !this.state.visible ||
      window.location.href.includes(paths.PrivacyPage) ||
      window.location.href.includes(paths.TermsPage) || 
      window.location.href.includes(paths.LegalPage)
    ) {
      document.body.style.overflow = "scroll";
      return null;
    }

    const { privacyLink, termsLink, headline, body } = this.props;

    return (
      <>
        <div className="biscuit-banner-background" />
        <div className="biscuit-banner-container">
          <div className="biscuit-banner-inner-container">
            <div className="biscuit-banner-text">
              <h1 className="biscuit-banner-headline">{headline}</h1>
              <div className="biscuit-banner-texts">{body}</div>
              <div className="biscuit-banner-links">
                {privacyLink && <a href={privacyLink}>Privacy Policy</a>}
                {termsLink && <a href={termsLink}>Terms and Conditions</a>}
              </div>
            </div>
            <div className="biscuit-cta-container">
              <button onClick={() => this.accept()}>Yes, I Accept</button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CookieBanner;
