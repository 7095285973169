export enum ProductSortBy {
    featured,
    newest,
    priceLowToHigh,
    priceHighToLow
}

export const SORT_BY = {
    featured: "Featured",
    newest: "Newest first",
    priceLowToHigh: "Price: low to high",
    priceHighToLow: "Price: high to low"
}

export interface ProductFilterState {
    sortBy?: string;
    category?: string;
    sub_category?: string[];
    price?: number[];
}

export interface InitialFiltersState {
    products: ProductFilterState
}

export const initialFilterState: InitialFiltersState = {
    products: {}
}