import { jobsConstants } from "store/constants/jobs"
import { InitialJobState, initialState } from "store/state/jobs"

export const jobsReducer = (state = initialState, action): InitialJobState => {
    switch (action.type) {
        case `${jobsConstants.LIST_JOBS}_PENDING`: 
            return {
                ...state,
                fetchingjobs: true
            }
        case `${jobsConstants.LIST_JOBS}_FULFILLED`: 
            const { items: jobsList, nextToken } = action.payload;

            return {
                ...state,
                jobs: [...state.jobs, ...jobsList],
                jobsNextToken: nextToken,
                fetchingjobs: false
            }
        case `${jobsConstants.LIST_JOB_DETAILS}_PENDING`: 
            return {
                ...state,
                fetchingJobDetails: true
            }
        case `${jobsConstants.LIST_JOB_DETAILS}_FULFILLED`: 
            const job = action.payload;

            return {
                ...state,
                jobDetails: job,
                fetchingJobDetails: false
            }

        default: 
            return state
    }
}