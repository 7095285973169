import shop from './shop';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [shop, other]
};

export default menuItems;
