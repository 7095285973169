import FAC from "fac/fac";
import { IS_TEST_ENV } from "utils/environment";

enum Feature {
  ECOMM = "ECOMM",
  MESSENGER_CHAT = "MESSENGER_CHAT",
  NOTIFICATION_BAR = "NOTIFICATION_BAR",
  ORDER_TRACKING = "ORDER_TRACKING",
  FAQ = "FAQ",
}

class Features {
  static isEcomEnabled = () => FAC.isEnabled(Feature.ECOMM) || IS_TEST_ENV;
  static isNotificationEnabled = () => false;
    // FAC.isEnabled(Feature.NOTIFICATION_BAR) || IS_TEST_ENV;
  static isOrderTrackingEnabled = () =>
    FAC.isEnabled(Feature.ORDER_TRACKING) || IS_TEST_ENV;
  static isFAQEnabled = () => FAC.isEnabled(Feature.FAQ) || IS_TEST_ENV;
}

export default Features;
