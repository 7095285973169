// assets
import  { IconShare, IconSettings, IconCreditCard } from '@tabler/icons';

import { dashboardPaths } from 'navigation/paths';

// constant
const icons = {
    IconSettings,
    IconCreditCard,
    IconShare
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'referrals',
            title: 'Referrals',
            type: 'item',
            url: dashboardPaths.ReferralsPage,
            icon: icons.IconShare,
            breadcrumbs: false
        },
        // {
        //     id: 'util-payments',
        //     title: 'Payments',
        //     type: 'item',
        //     url: dashboardPaths.PaymentsPage,
        //     icon: icons.IconCreditCard,
        //     breadcrumbs: false
        // },
        {
            id: 'util-settings',
            title: 'Settings',
            type: 'item',
            url: dashboardPaths.SettingsPage,
            icon: icons.IconSettings,
            breadcrumbs: false
        }
    ]
};

export default utilities;
