import { combineReducers } from 'redux';

// reducer import
import alert from './alert';
import auth from './auth';
import { cartReducer } from "./cart";
import { favoritesReducer } from './favorites';
import { jobsReducer } from "./jobs"
import { ordersReducer } from './orders';
import user from "./user";
import styles from './styles';
import { productsReducer } from './products';
import { newsletterReducer } from './newsletter';
import { historyReducer } from './history';
import { notificationReducer } from './notification';
import { InitialFavoritesState } from 'store/state/favorites';
import { InitialHistoryState } from 'store/state/history';
import { InitialJobState } from 'store/state/jobs';
import { InitialOrdersState } from 'store/state/orders';
import { InitialNewsletterState } from 'store/state/newsletter';
import { InitialUserState } from 'store/state/user';
import { InitialProductState } from 'store/state/products';
import { InitialFiltersState } from 'store/state/filters';
import filterReducer from './filters';
import { InitialNotificationState } from 'store/state/notification';

// ==============================|| COMBINE REDUCER ||============================== //

export interface ApplicationState {
    alert: any;
    auth: any;
    cart: any;
    customization: any;
    favorites: InitialFavoritesState;
    filters: InitialFiltersState;
    history: InitialHistoryState;
    jobs: InitialJobState,
    orders: InitialOrdersState,
    newsletter: InitialNewsletterState,
    notification: InitialNotificationState,
    user: InitialUserState
    productsReducer: InitialProductState
}

const reducers = {
    alert,
    auth,
    cart: cartReducer,
    customization: styles,
    favorites: favoritesReducer,
    filters: filterReducer,
    history: historyReducer,
    jobs: jobsReducer,
    orders: ordersReducer,
    newsletter: newsletterReducer,
    notification: notificationReducer,
    user,
    productsReducer,
}

const rootReducer = combineReducers(reducers);

export default rootReducer;
