import React, { useState } from 'react';
import { IntlProvider, IntlShape, useIntl, MessageDescriptor } from 'react-intl';
import English from './en.json';
import Indonesian from './id.json';
import { ComponentProps } from '../types/common';

interface IntlContextProps {
    locale: string,
    setLocale: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const IntlContext = React.createContext<IntlContextProps>({
    locale: 'en',
    setLocale: () => {}
});

// const RETRYABLE_HTTP_CODES = [400, 500, 502, 503, 504];

const local = navigator.language;

let INTL: IntlShape;

export const IntlGlobalProvider: React.FC<ComponentProps> = ({ children }): any => {
    const intl = useIntl();
    
    INTL = intl;
    return children;
}

function IntlTranslator () {
    return {
        // @ts-ignore
        formatMessage(message: MessageDescriptor, value): string {
            return INTL.formatMessage(message, value);
        }
    }
}

export const intl = IntlTranslator();

const localeToMessagesLocation: {[name: string]: {}} = {
    "en-US": English,
    "id": Indonesian
}

const defaultLocale = 'en-US';
const defaultLocaleMessage = localeToMessagesLocation.en;

function getLocaleMessages(locale: string):  {[name: string]: {}} {
    if (!localeToMessagesLocation[locale]) {
        console.error('Locale is not supported: ', locale);
        return {}
    }

    return localeToMessagesLocation[locale];
}

export const IntlWrapper: React.FC<ComponentProps> = ({ children }) => {
    const [messages, setMessage] = useState<{[name: string]: {}}>({ [defaultLocale]: defaultLocaleMessage })
    const [locale, setLocale] = useState(local);

    const selectLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newLocale = e.target.value;
        setLocale(newLocale);
        setMessage(getLocaleMessages(newLocale));
    }

    return (
        <IntlContext.Provider value={{ locale, setLocale: selectLanguage }}>
            {/** @ts-ignore */}
            <IntlProvider locale={locale} messages={messages}>
                <IntlGlobalProvider>{children}</IntlGlobalProvider>
            </IntlProvider>
        </IntlContext.Provider>
    )
}