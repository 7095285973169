export const SET_USER = "SET_USER";
export type SET_USER = typeof SET_USER;

export const CREATE_USER = "CREATE_USER";
export type CREATE_USER = typeof CREATE_USER;

export const GET_USER = "GET_USER";
export type GET_USER = typeof GET_USER;

export const GET_CURRENT_USER = "GET_CURRENT_USER";
export type GET_CURRENT_USER = typeof GET_CURRENT_USER;

export const UPDATE_USER = "UPDATE_USER";
export type UPDATE_USER  = typeof UPDATE_USER;