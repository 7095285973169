/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateProductInput = {
  id?: string | null,
  title: string,
  description: string,
  brand: string,
  category: string,
  sub_category: string,
  model_no: string,
  product_code: string,
  price: number,
  quantity: number,
  specifications?: Array< SpecificationDataInput | null > | null,
  currency: CURRENCY_ENUM,
  images: Array< string | null >,
  in_the_box?: Array< InTheBoxInput | null > | null,
};

export type SpecificationDataInput = {
  title: string,
  specification: string,
};

export enum CURRENCY_ENUM {
  MYR = "MYR",
  USD = "USD",
  SGD = "SGD",
}


export type InTheBoxInput = {
  productID: string,
  quantity: string,
};

export type ModelProductConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  brand?: ModelStringInput | null,
  category?: ModelStringInput | null,
  sub_category?: ModelStringInput | null,
  model_no?: ModelStringInput | null,
  product_code?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  quantity?: ModelIntInput | null,
  currency?: ModelCURRENCY_ENUMInput | null,
  images?: ModelStringInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelCURRENCY_ENUMInput = {
  eq?: CURRENCY_ENUM | null,
  ne?: CURRENCY_ENUM | null,
};

export type Product = {
  __typename: "Product",
  id: string,
  title: string,
  description: string,
  brand: string,
  category: string,
  sub_category: string,
  model_no: string,
  product_code: string,
  price: number,
  quantity: number,
  specifications?:  Array<SpecificationData | null > | null,
  currency: CURRENCY_ENUM,
  images: Array< string | null >,
  in_the_box?:  Array<InTheBox | null > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type SpecificationData = {
  __typename: "SpecificationData",
  title: string,
  specification: string,
};

export type InTheBox = {
  __typename: "InTheBox",
  productID: string,
  quantity: string,
};

export type UpdateProductInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  brand?: string | null,
  category?: string | null,
  sub_category?: string | null,
  model_no?: string | null,
  product_code?: string | null,
  price?: number | null,
  quantity?: number | null,
  specifications?: Array< SpecificationDataInput | null > | null,
  currency?: CURRENCY_ENUM | null,
  images?: Array< string | null > | null,
  in_the_box?: Array< InTheBoxInput | null > | null,
};

export type DeleteProductInput = {
  id: string,
};

export type CreateProductReviewInput = {
  id?: string | null,
  productID: string,
  header: string,
  description: string,
  purchased_area: string,
  locationID: string,
  rating: number,
  external_votes?: ExternalVotesCountInput | null,
};

export type ExternalVotesCountInput = {
  upvote?: number | null,
  downvote?: number | null,
};

export type ModelProductReviewConditionInput = {
  productID?: ModelIDInput | null,
  header?: ModelStringInput | null,
  description?: ModelStringInput | null,
  purchased_area?: ModelStringInput | null,
  locationID?: ModelStringInput | null,
  rating?: ModelIntInput | null,
  and?: Array< ModelProductReviewConditionInput | null > | null,
  or?: Array< ModelProductReviewConditionInput | null > | null,
  not?: ModelProductReviewConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ProductReview = {
  __typename: "ProductReview",
  id: string,
  productID: string,
  header: string,
  description: string,
  purchased_area: string,
  locationID: string,
  rating: number,
  external_votes?: ExternalVotesCount | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ExternalVotesCount = {
  __typename: "ExternalVotesCount",
  upvote?: number | null,
  downvote?: number | null,
};

export type UpdateProductReviewInput = {
  id: string,
  productID?: string | null,
  header?: string | null,
  description?: string | null,
  purchased_area?: string | null,
  locationID?: string | null,
  rating?: number | null,
  external_votes?: ExternalVotesCountInput | null,
};

export type DeleteProductReviewInput = {
  id: string,
};

export type CreateProductCollectionInput = {
  id?: string | null,
  title: string,
  description: string,
  product_code: string,
  condition_type: COLLECTION_CONDITION_TYPE,
  collection: Array< string | null >,
};

export enum COLLECTION_CONDITION_TYPE {
  manual = "manual",
  automatic = "automatic",
}


export type ModelProductCollectionConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  product_code?: ModelStringInput | null,
  condition_type?: ModelCOLLECTION_CONDITION_TYPEInput | null,
  collection?: ModelIDInput | null,
  and?: Array< ModelProductCollectionConditionInput | null > | null,
  or?: Array< ModelProductCollectionConditionInput | null > | null,
  not?: ModelProductCollectionConditionInput | null,
};

export type ModelCOLLECTION_CONDITION_TYPEInput = {
  eq?: COLLECTION_CONDITION_TYPE | null,
  ne?: COLLECTION_CONDITION_TYPE | null,
};

export type ProductCollection = {
  __typename: "ProductCollection",
  id: string,
  title: string,
  description: string,
  product_code: string,
  condition_type: COLLECTION_CONDITION_TYPE,
  collection: Array< string | null >,
  createdAt: string,
  updatedAt: string,
};

export type UpdateProductCollectionInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  product_code?: string | null,
  condition_type?: COLLECTION_CONDITION_TYPE | null,
  collection?: Array< string | null > | null,
};

export type DeleteProductCollectionInput = {
  id: string,
};

export type CreateProductBundleInput = {
  id?: string | null,
  title: string,
  sub_title?: string | null,
  description: string,
  product_code: string,
  products: Array< ProductBundleCountInput | null >,
  price: number,
  usage: Array< UsageInput | null >,
  currency: CURRENCY_ENUM,
  images?: Array< string | null > | null,
};

export type ProductBundleCountInput = {
  product_id: string,
  quantity: number,
};

export type UsageInput = {
  description: string,
};

export type ModelProductBundleConditionInput = {
  title?: ModelStringInput | null,
  sub_title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  product_code?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  currency?: ModelCURRENCY_ENUMInput | null,
  images?: ModelStringInput | null,
  and?: Array< ModelProductBundleConditionInput | null > | null,
  or?: Array< ModelProductBundleConditionInput | null > | null,
  not?: ModelProductBundleConditionInput | null,
};

export type ProductBundle = {
  __typename: "ProductBundle",
  id: string,
  title: string,
  sub_title?: string | null,
  description: string,
  product_code: string,
  products:  Array<ProductBundleCount | null >,
  price: number,
  usage:  Array<Usage | null >,
  currency: CURRENCY_ENUM,
  images?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type ProductBundleCount = {
  __typename: "ProductBundleCount",
  product_id: string,
  quantity: number,
};

export type Usage = {
  __typename: "Usage",
  description: string,
};

export type UpdateProductBundleInput = {
  id: string,
  title?: string | null,
  sub_title?: string | null,
  description?: string | null,
  product_code?: string | null,
  products?: Array< ProductBundleCountInput | null > | null,
  price?: number | null,
  usage?: Array< UsageInput | null > | null,
  currency?: CURRENCY_ENUM | null,
  images?: Array< string | null > | null,
};

export type DeleteProductBundleInput = {
  id: string,
};

export type CreateJobInput = {
  id?: string | null,
  title: string,
  description: string,
  responsibilities: Array< ResponsibilitiesInput | null >,
  qualifications: Array< QualificationsInput | null >,
  benefits: Array< BenefitsInput | null >,
  type: string,
  category: string,
  location_type: string,
  country: string,
  state: string,
  city: string,
};

export type ResponsibilitiesInput = {
  description: string,
};

export type QualificationsInput = {
  description: string,
};

export type BenefitsInput = {
  description: string,
};

export type ModelJobConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  category?: ModelStringInput | null,
  location_type?: ModelStringInput | null,
  country?: ModelStringInput | null,
  state?: ModelStringInput | null,
  city?: ModelStringInput | null,
  and?: Array< ModelJobConditionInput | null > | null,
  or?: Array< ModelJobConditionInput | null > | null,
  not?: ModelJobConditionInput | null,
};

export type Job = {
  __typename: "Job",
  id: string,
  title: string,
  description: string,
  responsibilities:  Array<Responsibilities | null >,
  qualifications:  Array<Qualifications | null >,
  benefits:  Array<Benefits | null >,
  type: string,
  category: string,
  location_type: string,
  country: string,
  state: string,
  city: string,
  createdAt: string,
  updatedAt: string,
};

export type Responsibilities = {
  __typename: "Responsibilities",
  description: string,
};

export type Qualifications = {
  __typename: "Qualifications",
  description: string,
};

export type Benefits = {
  __typename: "Benefits",
  description: string,
};

export type UpdateJobInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  responsibilities?: Array< ResponsibilitiesInput | null > | null,
  qualifications?: Array< QualificationsInput | null > | null,
  benefits?: Array< BenefitsInput | null > | null,
  type?: string | null,
  category?: string | null,
  location_type?: string | null,
  country?: string | null,
  state?: string | null,
  city?: string | null,
};

export type DeleteJobInput = {
  id: string,
};

export type CreateNewsletterCandidateInput = {
  id?: string | null,
  email: string,
  active: boolean,
  reason?: string | null,
  types: NewsletterTypesInput,
};

export type NewsletterTypesInput = {
  latest_updates: boolean,
  tips_and_tutorials: boolean,
};

export type ModelNewsletterCandidateConditionInput = {
  email?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  reason?: ModelStringInput | null,
  and?: Array< ModelNewsletterCandidateConditionInput | null > | null,
  or?: Array< ModelNewsletterCandidateConditionInput | null > | null,
  not?: ModelNewsletterCandidateConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type NewsletterCandidate = {
  __typename: "NewsletterCandidate",
  id: string,
  email: string,
  active: boolean,
  reason?: string | null,
  types: NewsletterTypes,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type NewsletterTypes = {
  __typename: "NewsletterTypes",
  latest_updates: boolean,
  tips_and_tutorials: boolean,
};

export type UpdateNewsletterCandidateInput = {
  id: string,
  email?: string | null,
  active?: boolean | null,
  reason?: string | null,
  types?: NewsletterTypesInput | null,
};

export type DeleteNewsletterCandidateInput = {
  id: string,
};

export type CreateMarketingCandidateInput = {
  id?: string | null,
  user_id: string,
  active: boolean,
  reason: string,
};

export type ModelMarketingCandidateConditionInput = {
  user_id?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  reason?: ModelStringInput | null,
  and?: Array< ModelMarketingCandidateConditionInput | null > | null,
  or?: Array< ModelMarketingCandidateConditionInput | null > | null,
  not?: ModelMarketingCandidateConditionInput | null,
};

export type MarketingCandidate = {
  __typename: "MarketingCandidate",
  id: string,
  user_id: string,
  active: boolean,
  reason: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMarketingCandidateInput = {
  id: string,
  user_id?: string | null,
  active?: boolean | null,
  reason?: string | null,
};

export type DeleteMarketingCandidateInput = {
  id: string,
};

export type CreateRetailerInput = {
  id?: string | null,
  name: string,
  address: Array< LocationInput | null >,
  email: string,
  contact_num: string,
  user_ids: Array< string | null >,
  website?: string | null,
};

export type LocationInput = {
  addr1: string,
  addr2?: string | null,
  postal: string,
  city: string,
  state: string,
  country: string,
  map_location: string,
  contact_num: string,
  opening_time: string,
  opening_days: string,
  email: string,
};

export type ModelRetailerConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  contact_num?: ModelStringInput | null,
  user_ids?: ModelStringInput | null,
  website?: ModelStringInput | null,
  and?: Array< ModelRetailerConditionInput | null > | null,
  or?: Array< ModelRetailerConditionInput | null > | null,
  not?: ModelRetailerConditionInput | null,
};

export type Retailer = {
  __typename: "Retailer",
  id: string,
  name: string,
  address:  Array<Location | null >,
  email: string,
  contact_num: string,
  user_ids: Array< string | null >,
  website?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Location = {
  __typename: "Location",
  addr1: string,
  addr2?: string | null,
  postal: string,
  city: string,
  state: string,
  country: string,
  map_location: string,
  contact_num: string,
  opening_time: string,
  opening_days: string,
  email: string,
};

export type UpdateRetailerInput = {
  id: string,
  name?: string | null,
  address?: Array< LocationInput | null > | null,
  email?: string | null,
  contact_num?: string | null,
  user_ids?: Array< string | null > | null,
  website?: string | null,
};

export type DeleteRetailerInput = {
  id: string,
};

export type CreateUserInput = {
  customer_id: string,
  username: string,
  email: string,
  family_name: string,
  given_name: string,
  type: USER_TYPE,
  password?: string | null,
  location?: string | null,
  contact_num: string,
  country: string,
  id?: string | null,
};

export enum USER_TYPE {
  Retailer = "Retailer",
  Reseller = "Reseller",
  User = "User",
}


export type ModelUserConditionInput = {
  customer_id?: ModelIDInput | null,
  username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  family_name?: ModelStringInput | null,
  given_name?: ModelStringInput | null,
  type?: ModelUSER_TYPEInput | null,
  password?: ModelStringInput | null,
  location?: ModelStringInput | null,
  contact_num?: ModelStringInput | null,
  country?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUSER_TYPEInput = {
  eq?: USER_TYPE | null,
  ne?: USER_TYPE | null,
};

export type User = {
  __typename: "User",
  customer_id: string,
  username: string,
  email: string,
  family_name: string,
  given_name: string,
  type: USER_TYPE,
  password?: string | null,
  location?: string | null,
  contact_num: string,
  country: string,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateUserInput = {
  customer_id?: string | null,
  username?: string | null,
  email?: string | null,
  family_name?: string | null,
  given_name?: string | null,
  type?: USER_TYPE | null,
  password?: string | null,
  location?: string | null,
  contact_num?: string | null,
  country?: string | null,
  id: string,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateEmployeeInput = {
  employee_id: string,
  username: string,
  email: string,
  family_name: string,
  given_name: string,
  type: EMPLOYEE_TYPE,
  password?: string | null,
  location?: string | null,
  contact_num: string,
  country: string,
  id?: string | null,
};

export enum EMPLOYEE_TYPE {
  SuperAdmin = "SuperAdmin",
  Admin = "Admin",
  SoftwareDevEngineer = "SoftwareDevEngineer",
  SoftwareDevManager = "SoftwareDevManager",
  ProjectManager = "ProjectManager",
  BusinessAnalyst = "BusinessAnalyst",
  AccountExec = "AccountExec",
  Accounting = "Accounting",
  HumanResource = "HumanResource",
  Recruiter = "Recruiter",
}


export type ModelEmployeeConditionInput = {
  employee_id?: ModelIDInput | null,
  username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  family_name?: ModelStringInput | null,
  given_name?: ModelStringInput | null,
  type?: ModelEMPLOYEE_TYPEInput | null,
  password?: ModelStringInput | null,
  location?: ModelStringInput | null,
  contact_num?: ModelStringInput | null,
  country?: ModelStringInput | null,
  and?: Array< ModelEmployeeConditionInput | null > | null,
  or?: Array< ModelEmployeeConditionInput | null > | null,
  not?: ModelEmployeeConditionInput | null,
};

export type ModelEMPLOYEE_TYPEInput = {
  eq?: EMPLOYEE_TYPE | null,
  ne?: EMPLOYEE_TYPE | null,
};

export type Employee = {
  __typename: "Employee",
  employee_id: string,
  username: string,
  email: string,
  family_name: string,
  given_name: string,
  type: EMPLOYEE_TYPE,
  password?: string | null,
  location?: string | null,
  contact_num: string,
  country: string,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateEmployeeInput = {
  employee_id?: string | null,
  username?: string | null,
  email?: string | null,
  family_name?: string | null,
  given_name?: string | null,
  type?: EMPLOYEE_TYPE | null,
  password?: string | null,
  location?: string | null,
  contact_num?: string | null,
  country?: string | null,
  id: string,
};

export type DeleteEmployeeInput = {
  id: string,
};

export type CreateCustomerInput = {
  id?: string | null,
  type: CustomerType,
  name?: string | null,
  phone?: string | null,
  country?: string | null,
  email?: string | null,
};

export enum CustomerType {
  people = "people",
  retailer = "retailer",
}


export type ModelCustomerConditionInput = {
  type?: ModelCustomerTypeInput | null,
  name?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  country?: ModelStringInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelCustomerConditionInput | null > | null,
  or?: Array< ModelCustomerConditionInput | null > | null,
  not?: ModelCustomerConditionInput | null,
};

export type ModelCustomerTypeInput = {
  eq?: CustomerType | null,
  ne?: CustomerType | null,
};

export type Customer = {
  __typename: "Customer",
  id: string,
  type: CustomerType,
  name?: string | null,
  phone?: string | null,
  country?: string | null,
  email?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateCustomerInput = {
  id: string,
  type?: CustomerType | null,
  name?: string | null,
  phone?: string | null,
  country?: string | null,
  email?: string | null,
};

export type DeleteCustomerInput = {
  id: string,
};

export type CreateCouponInput = {
  owner: string,
  coupon_code: string,
  promotion_type?: PromotionType | null,
  id?: string | null,
};

export enum PromotionType {
  StoreWide = "StoreWide",
  Product = "Product",
  Categories = "Categories",
  Retailer = "Retailer",
  Location = "Location",
  Brand = "Brand",
}


export type ModelCouponConditionInput = {
  owner?: ModelIDInput | null,
  coupon_code?: ModelStringInput | null,
  promotion_type?: ModelPromotionTypeInput | null,
  and?: Array< ModelCouponConditionInput | null > | null,
  or?: Array< ModelCouponConditionInput | null > | null,
  not?: ModelCouponConditionInput | null,
};

export type ModelPromotionTypeInput = {
  eq?: PromotionType | null,
  ne?: PromotionType | null,
};

export type Coupon = {
  __typename: "Coupon",
  owner: string,
  coupon_code: string,
  promotion_type?: PromotionType | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCouponInput = {
  owner?: string | null,
  coupon_code?: string | null,
  promotion_type?: PromotionType | null,
  id: string,
};

export type DeleteCouponInput = {
  id: string,
};

export type CreatePromotionInput = {
  id?: string | null,
};

export type ModelPromotionConditionInput = {
  and?: Array< ModelPromotionConditionInput | null > | null,
  or?: Array< ModelPromotionConditionInput | null > | null,
  not?: ModelPromotionConditionInput | null,
};

export type Promotion = {
  __typename: "Promotion",
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdatePromotionInput = {
  id: string,
};

export type DeletePromotionInput = {
  id: string,
};

export type CreateBrowsingHistoryInput = {
  id?: string | null,
  customer_id: string,
  viewed: Array< HistoryItemDetailsInput | null >,
};

export type HistoryItemDetailsInput = {
  product_id: string,
  createdAt: string,
  updatedAt?: string | null,
};

export type ModelBrowsingHistoryConditionInput = {
  customer_id?: ModelIDInput | null,
  and?: Array< ModelBrowsingHistoryConditionInput | null > | null,
  or?: Array< ModelBrowsingHistoryConditionInput | null > | null,
  not?: ModelBrowsingHistoryConditionInput | null,
};

export type BrowsingHistory = {
  __typename: "BrowsingHistory",
  id: string,
  customer_id: string,
  viewed:  Array<HistoryItemDetails | null >,
  createdAt: string,
  updatedAt: string,
};

export type HistoryItemDetails = {
  __typename: "HistoryItemDetails",
  product_id: string,
  createdAt: string,
  updatedAt?: string | null,
};

export type UpdateBrowsingHistoryInput = {
  id: string,
  customer_id?: string | null,
  viewed?: Array< HistoryItemDetailsInput | null > | null,
};

export type DeleteBrowsingHistoryInput = {
  id: string,
};

export type CreateSearchHistoryInput = {
  id?: string | null,
  customer_id: string,
  viewed: Array< HistoryItemDetailsInput | null >,
};

export type ModelSearchHistoryConditionInput = {
  customer_id?: ModelIDInput | null,
  and?: Array< ModelSearchHistoryConditionInput | null > | null,
  or?: Array< ModelSearchHistoryConditionInput | null > | null,
  not?: ModelSearchHistoryConditionInput | null,
};

export type SearchHistory = {
  __typename: "SearchHistory",
  id: string,
  customer_id: string,
  viewed:  Array<HistoryItemDetails | null >,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSearchHistoryInput = {
  id: string,
  customer_id?: string | null,
  viewed?: Array< HistoryItemDetailsInput | null > | null,
};

export type DeleteSearchHistoryInput = {
  id: string,
};

export type CreateFavoriteItemsInput = {
  id?: string | null,
  customer_id: string,
  favorite: Array< FavoriteItemDetailsInput | null >,
};

export type FavoriteItemDetailsInput = {
  product_id: string,
  createdAt: string,
};

export type ModelFavoriteItemsConditionInput = {
  customer_id?: ModelIDInput | null,
  and?: Array< ModelFavoriteItemsConditionInput | null > | null,
  or?: Array< ModelFavoriteItemsConditionInput | null > | null,
  not?: ModelFavoriteItemsConditionInput | null,
};

export type FavoriteItems = {
  __typename: "FavoriteItems",
  id: string,
  customer_id: string,
  favorite:  Array<FavoriteItemDetails | null >,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type FavoriteItemDetails = {
  __typename: "FavoriteItemDetails",
  product_id: string,
  createdAt: string,
};

export type UpdateFavoriteItemsInput = {
  id: string,
  customer_id?: string | null,
  favorite?: Array< FavoriteItemDetailsInput | null > | null,
};

export type DeleteFavoriteItemsInput = {
  id: string,
};

export type CreateCartInput = {
  id?: string | null,
  products?: Array< CartItemInput | null > | null,
  status: CartStatus,
  user_id: string,
};

export type CartItemInput = {
  product_id: string,
  quantity: number,
  current_price: number,
  last_seen_price: number,
};

export enum CartStatus {
  Active = "Active",
  Cancelled = "Cancelled",
  Delivered = "Delivered",
  Ordered = "Ordered",
  InTransit = "InTransit",
  Shipped = "Shipped",
}


export type ModelCartConditionInput = {
  status?: ModelCartStatusInput | null,
  user_id?: ModelIDInput | null,
  and?: Array< ModelCartConditionInput | null > | null,
  or?: Array< ModelCartConditionInput | null > | null,
  not?: ModelCartConditionInput | null,
};

export type ModelCartStatusInput = {
  eq?: CartStatus | null,
  ne?: CartStatus | null,
};

export type Cart = {
  __typename: "Cart",
  id: string,
  products?:  Array<CartItem | null > | null,
  status: CartStatus,
  user_id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type CartItem = {
  __typename: "CartItem",
  product_id: string,
  quantity: number,
  current_price: number,
  last_seen_price: number,
};

export type UpdateCartInput = {
  id: string,
  products?: Array< CartItemInput | null > | null,
  status?: CartStatus | null,
  user_id?: string | null,
};

export type DeleteCartInput = {
  id: string,
};

export type CreateOrderInput = {
  id?: string | null,
  cart_id: string,
  paid: boolean,
  payment_mode?: PaymentMethod | null,
  manual?: boolean | null,
  date?: string | null,
};

export enum PaymentMethod {
  cheque = "cheque",
  cc = "cc",
  debit = "debit",
  fpx = "fpx",
  defaulted = "defaulted",
}


export type ModelOrderConditionInput = {
  cart_id?: ModelIDInput | null,
  paid?: ModelBooleanInput | null,
  payment_mode?: ModelPaymentMethodInput | null,
  manual?: ModelBooleanInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelOrderConditionInput | null > | null,
  or?: Array< ModelOrderConditionInput | null > | null,
  not?: ModelOrderConditionInput | null,
};

export type ModelPaymentMethodInput = {
  eq?: PaymentMethod | null,
  ne?: PaymentMethod | null,
};

export type Order = {
  __typename: "Order",
  id: string,
  cart_id: string,
  paid: boolean,
  payment_mode?: PaymentMethod | null,
  manual?: boolean | null,
  date?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateOrderInput = {
  id: string,
  cart_id?: string | null,
  paid?: boolean | null,
  payment_mode?: PaymentMethod | null,
  manual?: boolean | null,
  date?: string | null,
};

export type DeleteOrderInput = {
  id: string,
};

export type CreateInvoiceInput = {
  id?: string | null,
  user_id: string,
  date: string,
  expire_date: string,
  total: string,
  paid: number,
  status: FormStatus,
  created_by: string,
  items: Array< InvoiceItemsInput | null >,
  note?: string | null,
  currency: CURRENCY_ENUM,
  payment_ids?: Array< string | null > | null,
};

export enum FormStatus {
  draft = "draft",
  pending = "pending",
  sent = "sent",
}


export type InvoiceItemsInput = {
  product_id: string,
  description?: string | null,
  quantity: number,
  price: number,
};

export type ModelInvoiceConditionInput = {
  user_id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  expire_date?: ModelStringInput | null,
  total?: ModelStringInput | null,
  paid?: ModelFloatInput | null,
  status?: ModelFormStatusInput | null,
  created_by?: ModelStringInput | null,
  note?: ModelStringInput | null,
  currency?: ModelCURRENCY_ENUMInput | null,
  payment_ids?: ModelIDInput | null,
  and?: Array< ModelInvoiceConditionInput | null > | null,
  or?: Array< ModelInvoiceConditionInput | null > | null,
  not?: ModelInvoiceConditionInput | null,
};

export type ModelFormStatusInput = {
  eq?: FormStatus | null,
  ne?: FormStatus | null,
};

export type Invoice = {
  __typename: "Invoice",
  id: string,
  user_id: string,
  date: string,
  expire_date: string,
  total: string,
  paid: number,
  status: FormStatus,
  created_by: string,
  items:  Array<InvoiceItems | null >,
  note?: string | null,
  currency: CURRENCY_ENUM,
  payment_ids?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type InvoiceItems = {
  __typename: "InvoiceItems",
  product_id: string,
  description?: string | null,
  quantity: number,
  price: number,
};

export type UpdateInvoiceInput = {
  id: string,
  user_id?: string | null,
  date?: string | null,
  expire_date?: string | null,
  total?: string | null,
  paid?: number | null,
  status?: FormStatus | null,
  created_by?: string | null,
  items?: Array< InvoiceItemsInput | null > | null,
  note?: string | null,
  currency?: CURRENCY_ENUM | null,
  payment_ids?: Array< string | null > | null,
};

export type DeleteInvoiceInput = {
  id: string,
};

export type CreateProformaInvoiceInput = {
  id?: string | null,
  user_id: string,
  date: string,
  expire_date: string,
  total: string,
  paid: number,
  status: ProformaFormStatus,
  created_by: string,
  items: Array< InvoiceItemsInput | null >,
  note?: string | null,
  currency: CURRENCY_ENUM,
  payment_ids?: Array< string | null > | null,
};

export enum ProformaFormStatus {
  draft = "draft",
  pending = "pending",
  sent = "sent",
  accepted = "accepted",
  declined = "declined",
}


export type ModelProformaInvoiceConditionInput = {
  user_id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  expire_date?: ModelStringInput | null,
  total?: ModelStringInput | null,
  paid?: ModelFloatInput | null,
  status?: ModelProformaFormStatusInput | null,
  created_by?: ModelStringInput | null,
  note?: ModelStringInput | null,
  currency?: ModelCURRENCY_ENUMInput | null,
  payment_ids?: ModelIDInput | null,
  and?: Array< ModelProformaInvoiceConditionInput | null > | null,
  or?: Array< ModelProformaInvoiceConditionInput | null > | null,
  not?: ModelProformaInvoiceConditionInput | null,
};

export type ModelProformaFormStatusInput = {
  eq?: ProformaFormStatus | null,
  ne?: ProformaFormStatus | null,
};

export type ProformaInvoice = {
  __typename: "ProformaInvoice",
  id: string,
  user_id: string,
  date: string,
  expire_date: string,
  total: string,
  paid: number,
  status: ProformaFormStatus,
  created_by: string,
  items:  Array<InvoiceItems | null >,
  note?: string | null,
  currency: CURRENCY_ENUM,
  payment_ids?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateProformaInvoiceInput = {
  id: string,
  user_id?: string | null,
  date?: string | null,
  expire_date?: string | null,
  total?: string | null,
  paid?: number | null,
  status?: ProformaFormStatus | null,
  created_by?: string | null,
  items?: Array< InvoiceItemsInput | null > | null,
  note?: string | null,
  currency?: CURRENCY_ENUM | null,
  payment_ids?: Array< string | null > | null,
};

export type DeleteProformaInvoiceInput = {
  id: string,
};

export type CreatePaymentInput = {
  customer_id: string,
  payment_mode: PaymentMethod,
  invoice_id: string,
  amount: number,
  reference?: string | null,
  description?: string | null,
  id?: string | null,
};

export type ModelPaymentConditionInput = {
  customer_id?: ModelIDInput | null,
  payment_mode?: ModelPaymentMethodInput | null,
  invoice_id?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  reference?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelPaymentConditionInput | null > | null,
  or?: Array< ModelPaymentConditionInput | null > | null,
  not?: ModelPaymentConditionInput | null,
};

export type Payment = {
  __typename: "Payment",
  customer_id: string,
  payment_mode: PaymentMethod,
  invoice_id: string,
  amount: number,
  reference?: string | null,
  description?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdatePaymentInput = {
  customer_id?: string | null,
  payment_mode?: PaymentMethod | null,
  invoice_id?: string | null,
  amount?: number | null,
  reference?: string | null,
  description?: string | null,
  id: string,
};

export type DeletePaymentInput = {
  id: string,
};

export type CreateProductRepairInput = {
  product_id: string,
  tracking_id: string,
  stage: RepairStage,
  price: number,
  under_warranty?: boolean | null,
  product_user: Array< PartsForRepairInput | null >,
  discounted_price?: number | null,
  misc?: number | null,
  labourCharges: number,
  id?: string | null,
};

export enum RepairStage {
  InTransit = "InTransit",
  Received = "Received",
  Inspection = "Inspection",
  Repair = "Repair",
  Complete = "Complete",
  Delivered = "Delivered",
  Quotation = "Quotation",
  Returned = "Returned",
  PurchasedNew = "PurchasedNew",
}


export type PartsForRepairInput = {
  productId: string,
  unitPrice: number,
  quantity: number,
  UOM: string,
  subTotal: number,
};

export type ModelProductRepairConditionInput = {
  product_id?: ModelIDInput | null,
  tracking_id?: ModelStringInput | null,
  stage?: ModelRepairStageInput | null,
  price?: ModelFloatInput | null,
  under_warranty?: ModelBooleanInput | null,
  discounted_price?: ModelFloatInput | null,
  misc?: ModelFloatInput | null,
  labourCharges?: ModelFloatInput | null,
  and?: Array< ModelProductRepairConditionInput | null > | null,
  or?: Array< ModelProductRepairConditionInput | null > | null,
  not?: ModelProductRepairConditionInput | null,
};

export type ModelRepairStageInput = {
  eq?: RepairStage | null,
  ne?: RepairStage | null,
};

export type ProductRepair = {
  __typename: "ProductRepair",
  product_id: string,
  tracking_id: string,
  stage: RepairStage,
  price: number,
  under_warranty?: boolean | null,
  product_user:  Array<PartsForRepair | null >,
  discounted_price?: number | null,
  misc?: number | null,
  labourCharges: number,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type PartsForRepair = {
  __typename: "PartsForRepair",
  productId: string,
  unitPrice: number,
  quantity: number,
  UOM: string,
  subTotal: number,
};

export type UpdateProductRepairInput = {
  product_id?: string | null,
  tracking_id?: string | null,
  stage?: RepairStage | null,
  price?: number | null,
  under_warranty?: boolean | null,
  product_user?: Array< PartsForRepairInput | null > | null,
  discounted_price?: number | null,
  misc?: number | null,
  labourCharges?: number | null,
  id: string,
};

export type DeleteProductRepairInput = {
  id: string,
};

export type CreateWarrantyInput = {
  product_id: string,
  current_price: number,
  user_id: string,
  model_no: string,
  product_code: string,
  retailer_name: string,
  receipts: Array< string | null >,
  status: WarrantyStatus,
  reason?: string | null,
  extension_ids?: Array< string | null > | null,
  order_id: string,
  verification_user_id?: string | null,
  id?: string | null,
};

export enum WarrantyStatus {
  accepted = "accepted",
  declined = "declined",
  extended = "extended",
  expired = "expired",
  submitted = "submitted",
}


export type ModelWarrantyConditionInput = {
  product_id?: ModelIDInput | null,
  current_price?: ModelFloatInput | null,
  user_id?: ModelIDInput | null,
  model_no?: ModelStringInput | null,
  product_code?: ModelStringInput | null,
  retailer_name?: ModelStringInput | null,
  receipts?: ModelStringInput | null,
  status?: ModelWarrantyStatusInput | null,
  reason?: ModelStringInput | null,
  extension_ids?: ModelIDInput | null,
  order_id?: ModelStringInput | null,
  verification_user_id?: ModelIDInput | null,
  and?: Array< ModelWarrantyConditionInput | null > | null,
  or?: Array< ModelWarrantyConditionInput | null > | null,
  not?: ModelWarrantyConditionInput | null,
};

export type ModelWarrantyStatusInput = {
  eq?: WarrantyStatus | null,
  ne?: WarrantyStatus | null,
};

export type Warranty = {
  __typename: "Warranty",
  product_id: string,
  current_price: number,
  user_id: string,
  model_no: string,
  product_code: string,
  retailer_name: string,
  receipts: Array< string | null >,
  status: WarrantyStatus,
  reason?: string | null,
  extension_ids?: Array< string | null > | null,
  order_id: string,
  verification_user_id?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateWarrantyInput = {
  product_id?: string | null,
  current_price?: number | null,
  user_id?: string | null,
  model_no?: string | null,
  product_code?: string | null,
  retailer_name?: string | null,
  receipts?: Array< string | null > | null,
  status?: WarrantyStatus | null,
  reason?: string | null,
  extension_ids?: Array< string | null > | null,
  order_id?: string | null,
  verification_user_id?: string | null,
  id: string,
};

export type DeleteWarrantyInput = {
  id: string,
};

export type CreateWarrantyExtensionInput = {
  thru_date: string,
  approval_user_id: string,
  approval_reasons: string,
  amount_paid: number,
  id?: string | null,
};

export type ModelWarrantyExtensionConditionInput = {
  thru_date?: ModelStringInput | null,
  approval_user_id?: ModelIDInput | null,
  approval_reasons?: ModelStringInput | null,
  amount_paid?: ModelFloatInput | null,
  and?: Array< ModelWarrantyExtensionConditionInput | null > | null,
  or?: Array< ModelWarrantyExtensionConditionInput | null > | null,
  not?: ModelWarrantyExtensionConditionInput | null,
};

export type WarrantyExtension = {
  __typename: "WarrantyExtension",
  thru_date: string,
  approval_user_id: string,
  approval_reasons: string,
  amount_paid: number,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateWarrantyExtensionInput = {
  thru_date?: string | null,
  approval_user_id?: string | null,
  approval_reasons?: string | null,
  amount_paid?: number | null,
  id: string,
};

export type DeleteWarrantyExtensionInput = {
  id: string,
};

export type CreateNotificationInput = {
  email_latest_update: boolean,
  email_tips_and_tutorials: boolean,
  id?: string | null,
};

export type ModelNotificationConditionInput = {
  email_latest_update?: ModelBooleanInput | null,
  email_tips_and_tutorials?: ModelBooleanInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type Notification = {
  __typename: "Notification",
  email_latest_update: boolean,
  email_tips_and_tutorials: boolean,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateNotificationInput = {
  email_latest_update?: boolean | null,
  email_tips_and_tutorials?: boolean | null,
  id: string,
};

export type DeleteNotificationInput = {
  id: string,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  brand?: ModelStringInput | null,
  category?: ModelStringInput | null,
  sub_category?: ModelStringInput | null,
  model_no?: ModelStringInput | null,
  product_code?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  quantity?: ModelIntInput | null,
  currency?: ModelCURRENCY_ENUMInput | null,
  images?: ModelStringInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
};

export type SearchableProductFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  brand?: SearchableStringFilterInput | null,
  category?: SearchableStringFilterInput | null,
  sub_category?: SearchableStringFilterInput | null,
  model_no?: SearchableStringFilterInput | null,
  product_code?: SearchableStringFilterInput | null,
  price?: SearchableFloatFilterInput | null,
  quantity?: SearchableIntFilterInput | null,
  images?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  currency?: SearchableStringFilterInput | null,
  and?: Array< SearchableProductFilterInput | null > | null,
  or?: Array< SearchableProductFilterInput | null > | null,
  not?: SearchableProductFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableProductSortInput = {
  field?: SearchableProductSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProductSortableFields {
  id = "id",
  title = "title",
  description = "description",
  brand = "brand",
  category = "category",
  sub_category = "sub_category",
  model_no = "model_no",
  product_code = "product_code",
  price = "price",
  quantity = "quantity",
  images = "images",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableProductAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableProductAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchableProductAggregateField {
  id = "id",
  title = "title",
  description = "description",
  brand = "brand",
  category = "category",
  sub_category = "sub_category",
  model_no = "model_no",
  product_code = "product_code",
  price = "price",
  quantity = "quantity",
  currency = "currency",
  images = "images",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableProductConnection = {
  __typename: "SearchableProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type ModelProductReviewFilterInput = {
  id?: ModelIDInput | null,
  productID?: ModelIDInput | null,
  header?: ModelStringInput | null,
  description?: ModelStringInput | null,
  purchased_area?: ModelStringInput | null,
  locationID?: ModelStringInput | null,
  rating?: ModelIntInput | null,
  and?: Array< ModelProductReviewFilterInput | null > | null,
  or?: Array< ModelProductReviewFilterInput | null > | null,
  not?: ModelProductReviewFilterInput | null,
};

export type ModelProductReviewConnection = {
  __typename: "ModelProductReviewConnection",
  items:  Array<ProductReview | null >,
  nextToken?: string | null,
};

export type SearchableProductReviewFilterInput = {
  id?: SearchableIDFilterInput | null,
  productID?: SearchableIDFilterInput | null,
  header?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  purchased_area?: SearchableStringFilterInput | null,
  locationID?: SearchableStringFilterInput | null,
  rating?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableProductReviewFilterInput | null > | null,
  or?: Array< SearchableProductReviewFilterInput | null > | null,
  not?: SearchableProductReviewFilterInput | null,
};

export type SearchableProductReviewSortInput = {
  field?: SearchableProductReviewSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProductReviewSortableFields {
  id = "id",
  productID = "productID",
  header = "header",
  description = "description",
  purchased_area = "purchased_area",
  locationID = "locationID",
  rating = "rating",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableProductReviewAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableProductReviewAggregateField,
};

export enum SearchableProductReviewAggregateField {
  id = "id",
  productID = "productID",
  header = "header",
  description = "description",
  purchased_area = "purchased_area",
  locationID = "locationID",
  rating = "rating",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableProductReviewConnection = {
  __typename: "SearchableProductReviewConnection",
  items:  Array<ProductReview | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelProductCollectionFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  product_code?: ModelStringInput | null,
  condition_type?: ModelCOLLECTION_CONDITION_TYPEInput | null,
  collection?: ModelIDInput | null,
  and?: Array< ModelProductCollectionFilterInput | null > | null,
  or?: Array< ModelProductCollectionFilterInput | null > | null,
  not?: ModelProductCollectionFilterInput | null,
};

export type ModelProductCollectionConnection = {
  __typename: "ModelProductCollectionConnection",
  items:  Array<ProductCollection | null >,
  nextToken?: string | null,
};

export type SearchableProductCollectionFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  product_code?: SearchableStringFilterInput | null,
  collection?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  condition_type?: SearchableStringFilterInput | null,
  and?: Array< SearchableProductCollectionFilterInput | null > | null,
  or?: Array< SearchableProductCollectionFilterInput | null > | null,
  not?: SearchableProductCollectionFilterInput | null,
};

export type SearchableProductCollectionSortInput = {
  field?: SearchableProductCollectionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProductCollectionSortableFields {
  id = "id",
  title = "title",
  description = "description",
  product_code = "product_code",
  collection = "collection",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableProductCollectionAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableProductCollectionAggregateField,
};

export enum SearchableProductCollectionAggregateField {
  id = "id",
  title = "title",
  description = "description",
  product_code = "product_code",
  condition_type = "condition_type",
  collection = "collection",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableProductCollectionConnection = {
  __typename: "SearchableProductCollectionConnection",
  items:  Array<ProductCollection | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelProductBundleFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  sub_title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  product_code?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  currency?: ModelCURRENCY_ENUMInput | null,
  images?: ModelStringInput | null,
  and?: Array< ModelProductBundleFilterInput | null > | null,
  or?: Array< ModelProductBundleFilterInput | null > | null,
  not?: ModelProductBundleFilterInput | null,
};

export type ModelProductBundleConnection = {
  __typename: "ModelProductBundleConnection",
  items:  Array<ProductBundle | null >,
  nextToken?: string | null,
};

export type SearchableProductBundleFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  sub_title?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  product_code?: SearchableStringFilterInput | null,
  price?: SearchableFloatFilterInput | null,
  images?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  currency?: SearchableStringFilterInput | null,
  and?: Array< SearchableProductBundleFilterInput | null > | null,
  or?: Array< SearchableProductBundleFilterInput | null > | null,
  not?: SearchableProductBundleFilterInput | null,
};

export type SearchableProductBundleSortInput = {
  field?: SearchableProductBundleSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProductBundleSortableFields {
  id = "id",
  title = "title",
  sub_title = "sub_title",
  description = "description",
  product_code = "product_code",
  price = "price",
  images = "images",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableProductBundleAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableProductBundleAggregateField,
};

export enum SearchableProductBundleAggregateField {
  id = "id",
  title = "title",
  sub_title = "sub_title",
  description = "description",
  product_code = "product_code",
  price = "price",
  currency = "currency",
  images = "images",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableProductBundleConnection = {
  __typename: "SearchableProductBundleConnection",
  items:  Array<ProductBundle | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelJobFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  category?: ModelStringInput | null,
  location_type?: ModelStringInput | null,
  country?: ModelStringInput | null,
  state?: ModelStringInput | null,
  city?: ModelStringInput | null,
  and?: Array< ModelJobFilterInput | null > | null,
  or?: Array< ModelJobFilterInput | null > | null,
  not?: ModelJobFilterInput | null,
};

export type ModelJobConnection = {
  __typename: "ModelJobConnection",
  items:  Array<Job | null >,
  nextToken?: string | null,
};

export type SearchableJobFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  category?: SearchableStringFilterInput | null,
  location_type?: SearchableStringFilterInput | null,
  country?: SearchableStringFilterInput | null,
  state?: SearchableStringFilterInput | null,
  city?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableJobFilterInput | null > | null,
  or?: Array< SearchableJobFilterInput | null > | null,
  not?: SearchableJobFilterInput | null,
};

export type SearchableJobSortInput = {
  field?: SearchableJobSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableJobSortableFields {
  id = "id",
  title = "title",
  description = "description",
  type = "type",
  category = "category",
  location_type = "location_type",
  country = "country",
  state = "state",
  city = "city",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableJobAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableJobAggregateField,
};

export enum SearchableJobAggregateField {
  id = "id",
  title = "title",
  description = "description",
  type = "type",
  category = "category",
  location_type = "location_type",
  country = "country",
  state = "state",
  city = "city",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableJobConnection = {
  __typename: "SearchableJobConnection",
  items:  Array<Job | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelNewsletterCandidateFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  reason?: ModelStringInput | null,
  and?: Array< ModelNewsletterCandidateFilterInput | null > | null,
  or?: Array< ModelNewsletterCandidateFilterInput | null > | null,
  not?: ModelNewsletterCandidateFilterInput | null,
};

export type ModelNewsletterCandidateConnection = {
  __typename: "ModelNewsletterCandidateConnection",
  items:  Array<NewsletterCandidate | null >,
  nextToken?: string | null,
};

export type ModelMarketingCandidateFilterInput = {
  id?: ModelIDInput | null,
  user_id?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  reason?: ModelStringInput | null,
  and?: Array< ModelMarketingCandidateFilterInput | null > | null,
  or?: Array< ModelMarketingCandidateFilterInput | null > | null,
  not?: ModelMarketingCandidateFilterInput | null,
};

export type ModelMarketingCandidateConnection = {
  __typename: "ModelMarketingCandidateConnection",
  items:  Array<MarketingCandidate | null >,
  nextToken?: string | null,
};

export type ModelRetailerFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  contact_num?: ModelStringInput | null,
  user_ids?: ModelStringInput | null,
  website?: ModelStringInput | null,
  and?: Array< ModelRetailerFilterInput | null > | null,
  or?: Array< ModelRetailerFilterInput | null > | null,
  not?: ModelRetailerFilterInput | null,
};

export type ModelRetailerConnection = {
  __typename: "ModelRetailerConnection",
  items:  Array<Retailer | null >,
  nextToken?: string | null,
};

export type SearchableRetailerFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  contact_num?: SearchableStringFilterInput | null,
  user_ids?: SearchableStringFilterInput | null,
  website?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableRetailerFilterInput | null > | null,
  or?: Array< SearchableRetailerFilterInput | null > | null,
  not?: SearchableRetailerFilterInput | null,
};

export type SearchableRetailerSortInput = {
  field?: SearchableRetailerSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableRetailerSortableFields {
  id = "id",
  name = "name",
  email = "email",
  contact_num = "contact_num",
  user_ids = "user_ids",
  website = "website",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableRetailerAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableRetailerAggregateField,
};

export enum SearchableRetailerAggregateField {
  id = "id",
  name = "name",
  email = "email",
  contact_num = "contact_num",
  user_ids = "user_ids",
  website = "website",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableRetailerConnection = {
  __typename: "SearchableRetailerConnection",
  items:  Array<Retailer | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelUserFilterInput = {
  customer_id?: ModelIDInput | null,
  username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  family_name?: ModelStringInput | null,
  given_name?: ModelStringInput | null,
  type?: ModelUSER_TYPEInput | null,
  password?: ModelStringInput | null,
  location?: ModelStringInput | null,
  contact_num?: ModelStringInput | null,
  country?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type SearchableUserFilterInput = {
  customer_id?: SearchableIDFilterInput | null,
  username?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  family_name?: SearchableStringFilterInput | null,
  given_name?: SearchableStringFilterInput | null,
  password?: SearchableStringFilterInput | null,
  location?: SearchableStringFilterInput | null,
  contact_num?: SearchableStringFilterInput | null,
  country?: SearchableStringFilterInput | null,
  id?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  and?: Array< SearchableUserFilterInput | null > | null,
  or?: Array< SearchableUserFilterInput | null > | null,
  not?: SearchableUserFilterInput | null,
};

export type SearchableUserSortInput = {
  field?: SearchableUserSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableUserSortableFields {
  customer_id = "customer_id",
  username = "username",
  email = "email",
  family_name = "family_name",
  given_name = "given_name",
  password = "password",
  location = "location",
  contact_num = "contact_num",
  country = "country",
  id = "id",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableUserAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableUserAggregateField,
};

export enum SearchableUserAggregateField {
  customer_id = "customer_id",
  username = "username",
  email = "email",
  family_name = "family_name",
  given_name = "given_name",
  type = "type",
  password = "password",
  location = "location",
  contact_num = "contact_num",
  country = "country",
  id = "id",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableUserConnection = {
  __typename: "SearchableUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelEmployeeFilterInput = {
  employee_id?: ModelIDInput | null,
  username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  family_name?: ModelStringInput | null,
  given_name?: ModelStringInput | null,
  type?: ModelEMPLOYEE_TYPEInput | null,
  password?: ModelStringInput | null,
  location?: ModelStringInput | null,
  contact_num?: ModelStringInput | null,
  country?: ModelStringInput | null,
  and?: Array< ModelEmployeeFilterInput | null > | null,
  or?: Array< ModelEmployeeFilterInput | null > | null,
  not?: ModelEmployeeFilterInput | null,
};

export type ModelEmployeeConnection = {
  __typename: "ModelEmployeeConnection",
  items:  Array<Employee | null >,
  nextToken?: string | null,
};

export type SearchableEmployeeFilterInput = {
  employee_id?: SearchableIDFilterInput | null,
  username?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  family_name?: SearchableStringFilterInput | null,
  given_name?: SearchableStringFilterInput | null,
  password?: SearchableStringFilterInput | null,
  location?: SearchableStringFilterInput | null,
  contact_num?: SearchableStringFilterInput | null,
  country?: SearchableStringFilterInput | null,
  id?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  and?: Array< SearchableEmployeeFilterInput | null > | null,
  or?: Array< SearchableEmployeeFilterInput | null > | null,
  not?: SearchableEmployeeFilterInput | null,
};

export type SearchableEmployeeSortInput = {
  field?: SearchableEmployeeSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableEmployeeSortableFields {
  employee_id = "employee_id",
  username = "username",
  email = "email",
  family_name = "family_name",
  given_name = "given_name",
  password = "password",
  location = "location",
  contact_num = "contact_num",
  country = "country",
  id = "id",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableEmployeeAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableEmployeeAggregateField,
};

export enum SearchableEmployeeAggregateField {
  employee_id = "employee_id",
  username = "username",
  email = "email",
  family_name = "family_name",
  given_name = "given_name",
  type = "type",
  password = "password",
  location = "location",
  contact_num = "contact_num",
  country = "country",
  id = "id",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableEmployeeConnection = {
  __typename: "SearchableEmployeeConnection",
  items:  Array<Employee | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelCustomerFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelCustomerTypeInput | null,
  name?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  country?: ModelStringInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelCustomerFilterInput | null > | null,
  or?: Array< ModelCustomerFilterInput | null > | null,
  not?: ModelCustomerFilterInput | null,
};

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection",
  items:  Array<Customer | null >,
  nextToken?: string | null,
};

export type SearchableCustomerFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  country?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  and?: Array< SearchableCustomerFilterInput | null > | null,
  or?: Array< SearchableCustomerFilterInput | null > | null,
  not?: SearchableCustomerFilterInput | null,
};

export type SearchableCustomerSortInput = {
  field?: SearchableCustomerSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCustomerSortableFields {
  id = "id",
  name = "name",
  phone = "phone",
  country = "country",
  email = "email",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableCustomerAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableCustomerAggregateField,
};

export enum SearchableCustomerAggregateField {
  id = "id",
  type = "type",
  name = "name",
  phone = "phone",
  country = "country",
  email = "email",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableCustomerConnection = {
  __typename: "SearchableCustomerConnection",
  items:  Array<Customer | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelCouponFilterInput = {
  owner?: ModelIDInput | null,
  coupon_code?: ModelStringInput | null,
  promotion_type?: ModelPromotionTypeInput | null,
  and?: Array< ModelCouponFilterInput | null > | null,
  or?: Array< ModelCouponFilterInput | null > | null,
  not?: ModelCouponFilterInput | null,
};

export type ModelCouponConnection = {
  __typename: "ModelCouponConnection",
  items:  Array<Coupon | null >,
  nextToken?: string | null,
};

export type ModelPromotionFilterInput = {
  and?: Array< ModelPromotionFilterInput | null > | null,
  or?: Array< ModelPromotionFilterInput | null > | null,
  not?: ModelPromotionFilterInput | null,
};

export type ModelPromotionConnection = {
  __typename: "ModelPromotionConnection",
  items:  Array<Promotion | null >,
  nextToken?: string | null,
};

export type ModelBrowsingHistoryFilterInput = {
  id?: ModelIDInput | null,
  customer_id?: ModelIDInput | null,
  and?: Array< ModelBrowsingHistoryFilterInput | null > | null,
  or?: Array< ModelBrowsingHistoryFilterInput | null > | null,
  not?: ModelBrowsingHistoryFilterInput | null,
};

export type ModelBrowsingHistoryConnection = {
  __typename: "ModelBrowsingHistoryConnection",
  items:  Array<BrowsingHistory | null >,
  nextToken?: string | null,
};

export type ModelSearchHistoryFilterInput = {
  id?: ModelIDInput | null,
  customer_id?: ModelIDInput | null,
  and?: Array< ModelSearchHistoryFilterInput | null > | null,
  or?: Array< ModelSearchHistoryFilterInput | null > | null,
  not?: ModelSearchHistoryFilterInput | null,
};

export type ModelSearchHistoryConnection = {
  __typename: "ModelSearchHistoryConnection",
  items:  Array<SearchHistory | null >,
  nextToken?: string | null,
};

export type ModelFavoriteItemsFilterInput = {
  id?: ModelIDInput | null,
  customer_id?: ModelIDInput | null,
  and?: Array< ModelFavoriteItemsFilterInput | null > | null,
  or?: Array< ModelFavoriteItemsFilterInput | null > | null,
  not?: ModelFavoriteItemsFilterInput | null,
};

export type ModelFavoriteItemsConnection = {
  __typename: "ModelFavoriteItemsConnection",
  items:  Array<FavoriteItems | null >,
  nextToken?: string | null,
};

export type ModelCartFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelCartStatusInput | null,
  user_id?: ModelIDInput | null,
  and?: Array< ModelCartFilterInput | null > | null,
  or?: Array< ModelCartFilterInput | null > | null,
  not?: ModelCartFilterInput | null,
};

export type ModelCartConnection = {
  __typename: "ModelCartConnection",
  items:  Array<Cart | null >,
  nextToken?: string | null,
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  cart_id?: ModelIDInput | null,
  paid?: ModelBooleanInput | null,
  payment_mode?: ModelPaymentMethodInput | null,
  manual?: ModelBooleanInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
};

export type ModelInvoiceFilterInput = {
  id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  expire_date?: ModelStringInput | null,
  total?: ModelStringInput | null,
  paid?: ModelFloatInput | null,
  status?: ModelFormStatusInput | null,
  created_by?: ModelStringInput | null,
  note?: ModelStringInput | null,
  currency?: ModelCURRENCY_ENUMInput | null,
  payment_ids?: ModelIDInput | null,
  and?: Array< ModelInvoiceFilterInput | null > | null,
  or?: Array< ModelInvoiceFilterInput | null > | null,
  not?: ModelInvoiceFilterInput | null,
};

export type ModelInvoiceConnection = {
  __typename: "ModelInvoiceConnection",
  items:  Array<Invoice | null >,
  nextToken?: string | null,
};

export type ModelProformaInvoiceFilterInput = {
  id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  expire_date?: ModelStringInput | null,
  total?: ModelStringInput | null,
  paid?: ModelFloatInput | null,
  status?: ModelProformaFormStatusInput | null,
  created_by?: ModelStringInput | null,
  note?: ModelStringInput | null,
  currency?: ModelCURRENCY_ENUMInput | null,
  payment_ids?: ModelIDInput | null,
  and?: Array< ModelProformaInvoiceFilterInput | null > | null,
  or?: Array< ModelProformaInvoiceFilterInput | null > | null,
  not?: ModelProformaInvoiceFilterInput | null,
};

export type ModelProformaInvoiceConnection = {
  __typename: "ModelProformaInvoiceConnection",
  items:  Array<ProformaInvoice | null >,
  nextToken?: string | null,
};

export type ModelPaymentFilterInput = {
  customer_id?: ModelIDInput | null,
  payment_mode?: ModelPaymentMethodInput | null,
  invoice_id?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  reference?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelPaymentFilterInput | null > | null,
  or?: Array< ModelPaymentFilterInput | null > | null,
  not?: ModelPaymentFilterInput | null,
};

export type ModelPaymentConnection = {
  __typename: "ModelPaymentConnection",
  items:  Array<Payment | null >,
  nextToken?: string | null,
};

export type ModelProductRepairFilterInput = {
  product_id?: ModelIDInput | null,
  tracking_id?: ModelStringInput | null,
  stage?: ModelRepairStageInput | null,
  price?: ModelFloatInput | null,
  under_warranty?: ModelBooleanInput | null,
  discounted_price?: ModelFloatInput | null,
  misc?: ModelFloatInput | null,
  labourCharges?: ModelFloatInput | null,
  and?: Array< ModelProductRepairFilterInput | null > | null,
  or?: Array< ModelProductRepairFilterInput | null > | null,
  not?: ModelProductRepairFilterInput | null,
};

export type ModelProductRepairConnection = {
  __typename: "ModelProductRepairConnection",
  items:  Array<ProductRepair | null >,
  nextToken?: string | null,
};

export type SearchableProductRepairFilterInput = {
  product_id?: SearchableIDFilterInput | null,
  tracking_id?: SearchableStringFilterInput | null,
  price?: SearchableFloatFilterInput | null,
  under_warranty?: SearchableBooleanFilterInput | null,
  discounted_price?: SearchableFloatFilterInput | null,
  misc?: SearchableFloatFilterInput | null,
  labourCharges?: SearchableFloatFilterInput | null,
  id?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  stage?: SearchableStringFilterInput | null,
  and?: Array< SearchableProductRepairFilterInput | null > | null,
  or?: Array< SearchableProductRepairFilterInput | null > | null,
  not?: SearchableProductRepairFilterInput | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableProductRepairSortInput = {
  field?: SearchableProductRepairSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProductRepairSortableFields {
  product_id = "product_id",
  tracking_id = "tracking_id",
  price = "price",
  under_warranty = "under_warranty",
  discounted_price = "discounted_price",
  misc = "misc",
  labourCharges = "labourCharges",
  id = "id",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableProductRepairAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableProductRepairAggregateField,
};

export enum SearchableProductRepairAggregateField {
  product_id = "product_id",
  tracking_id = "tracking_id",
  stage = "stage",
  price = "price",
  under_warranty = "under_warranty",
  discounted_price = "discounted_price",
  misc = "misc",
  labourCharges = "labourCharges",
  id = "id",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableProductRepairConnection = {
  __typename: "SearchableProductRepairConnection",
  items:  Array<ProductRepair | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelWarrantyFilterInput = {
  product_id?: ModelIDInput | null,
  current_price?: ModelFloatInput | null,
  user_id?: ModelIDInput | null,
  model_no?: ModelStringInput | null,
  product_code?: ModelStringInput | null,
  retailer_name?: ModelStringInput | null,
  receipts?: ModelStringInput | null,
  status?: ModelWarrantyStatusInput | null,
  reason?: ModelStringInput | null,
  extension_ids?: ModelIDInput | null,
  order_id?: ModelStringInput | null,
  verification_user_id?: ModelIDInput | null,
  and?: Array< ModelWarrantyFilterInput | null > | null,
  or?: Array< ModelWarrantyFilterInput | null > | null,
  not?: ModelWarrantyFilterInput | null,
};

export type ModelWarrantyConnection = {
  __typename: "ModelWarrantyConnection",
  items:  Array<Warranty | null >,
  nextToken?: string | null,
};

export type ModelWarrantyExtensionFilterInput = {
  thru_date?: ModelStringInput | null,
  approval_user_id?: ModelIDInput | null,
  approval_reasons?: ModelStringInput | null,
  amount_paid?: ModelFloatInput | null,
  and?: Array< ModelWarrantyExtensionFilterInput | null > | null,
  or?: Array< ModelWarrantyExtensionFilterInput | null > | null,
  not?: ModelWarrantyExtensionFilterInput | null,
};

export type ModelWarrantyExtensionConnection = {
  __typename: "ModelWarrantyExtensionConnection",
  items:  Array<WarrantyExtension | null >,
  nextToken?: string | null,
};

export type ModelNotificationFilterInput = {
  email_latest_update?: ModelBooleanInput | null,
  email_tips_and_tutorials?: ModelBooleanInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  brand?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  sub_category?: ModelSubscriptionStringInput | null,
  model_no?: ModelSubscriptionStringInput | null,
  product_code?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  currency?: ModelSubscriptionStringInput | null,
  images?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionProductReviewFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  productID?: ModelSubscriptionIDInput | null,
  header?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  purchased_area?: ModelSubscriptionStringInput | null,
  locationID?: ModelSubscriptionStringInput | null,
  rating?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionProductReviewFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductReviewFilterInput | null > | null,
};

export type ModelSubscriptionProductCollectionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  product_code?: ModelSubscriptionStringInput | null,
  condition_type?: ModelSubscriptionStringInput | null,
  collection?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionProductCollectionFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductCollectionFilterInput | null > | null,
};

export type ModelSubscriptionProductBundleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  sub_title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  product_code?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  currency?: ModelSubscriptionStringInput | null,
  images?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductBundleFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductBundleFilterInput | null > | null,
};

export type ModelSubscriptionJobFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  location_type?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionJobFilterInput | null > | null,
  or?: Array< ModelSubscriptionJobFilterInput | null > | null,
};

export type ModelSubscriptionNewsletterCandidateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  reason?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNewsletterCandidateFilterInput | null > | null,
  or?: Array< ModelSubscriptionNewsletterCandidateFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionMarketingCandidateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user_id?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  reason?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMarketingCandidateFilterInput | null > | null,
  or?: Array< ModelSubscriptionMarketingCandidateFilterInput | null > | null,
};

export type ModelSubscriptionRetailerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  contact_num?: ModelSubscriptionStringInput | null,
  user_ids?: ModelSubscriptionStringInput | null,
  website?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRetailerFilterInput | null > | null,
  or?: Array< ModelSubscriptionRetailerFilterInput | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  customer_id?: ModelSubscriptionIDInput | null,
  username?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  family_name?: ModelSubscriptionStringInput | null,
  given_name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  password?: ModelSubscriptionStringInput | null,
  location?: ModelSubscriptionStringInput | null,
  contact_num?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionEmployeeFilterInput = {
  employee_id?: ModelSubscriptionIDInput | null,
  username?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  family_name?: ModelSubscriptionStringInput | null,
  given_name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  password?: ModelSubscriptionStringInput | null,
  location?: ModelSubscriptionStringInput | null,
  contact_num?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEmployeeFilterInput | null > | null,
  or?: Array< ModelSubscriptionEmployeeFilterInput | null > | null,
};

export type ModelSubscriptionCustomerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCustomerFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomerFilterInput | null > | null,
};

export type ModelSubscriptionCouponFilterInput = {
  coupon_code?: ModelSubscriptionStringInput | null,
  promotion_type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCouponFilterInput | null > | null,
  or?: Array< ModelSubscriptionCouponFilterInput | null > | null,
};

export type ModelSubscriptionPromotionFilterInput = {
  and?: Array< ModelSubscriptionPromotionFilterInput | null > | null,
  or?: Array< ModelSubscriptionPromotionFilterInput | null > | null,
};

export type ModelSubscriptionBrowsingHistoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  customer_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionBrowsingHistoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionBrowsingHistoryFilterInput | null > | null,
};

export type ModelSubscriptionSearchHistoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  customer_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionSearchHistoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionSearchHistoryFilterInput | null > | null,
};

export type ModelSubscriptionFavoriteItemsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  customer_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionFavoriteItemsFilterInput | null > | null,
  or?: Array< ModelSubscriptionFavoriteItemsFilterInput | null > | null,
};

export type ModelSubscriptionCartFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCartFilterInput | null > | null,
  or?: Array< ModelSubscriptionCartFilterInput | null > | null,
};

export type ModelSubscriptionOrderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cart_id?: ModelSubscriptionIDInput | null,
  paid?: ModelSubscriptionBooleanInput | null,
  payment_mode?: ModelSubscriptionStringInput | null,
  manual?: ModelSubscriptionBooleanInput | null,
  date?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderFilterInput | null > | null,
};

export type ModelSubscriptionInvoiceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  date?: ModelSubscriptionStringInput | null,
  expire_date?: ModelSubscriptionStringInput | null,
  total?: ModelSubscriptionStringInput | null,
  paid?: ModelSubscriptionFloatInput | null,
  status?: ModelSubscriptionStringInput | null,
  created_by?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  payment_ids?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionInvoiceFilterInput | null > | null,
  or?: Array< ModelSubscriptionInvoiceFilterInput | null > | null,
};

export type ModelSubscriptionProformaInvoiceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  date?: ModelSubscriptionStringInput | null,
  expire_date?: ModelSubscriptionStringInput | null,
  total?: ModelSubscriptionStringInput | null,
  paid?: ModelSubscriptionFloatInput | null,
  status?: ModelSubscriptionStringInput | null,
  created_by?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  payment_ids?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionProformaInvoiceFilterInput | null > | null,
  or?: Array< ModelSubscriptionProformaInvoiceFilterInput | null > | null,
};

export type ModelSubscriptionPaymentFilterInput = {
  customer_id?: ModelSubscriptionIDInput | null,
  payment_mode?: ModelSubscriptionStringInput | null,
  invoice_id?: ModelSubscriptionIDInput | null,
  amount?: ModelSubscriptionFloatInput | null,
  reference?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPaymentFilterInput | null > | null,
  or?: Array< ModelSubscriptionPaymentFilterInput | null > | null,
};

export type ModelSubscriptionProductRepairFilterInput = {
  product_id?: ModelSubscriptionIDInput | null,
  tracking_id?: ModelSubscriptionStringInput | null,
  stage?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  under_warranty?: ModelSubscriptionBooleanInput | null,
  discounted_price?: ModelSubscriptionFloatInput | null,
  misc?: ModelSubscriptionFloatInput | null,
  labourCharges?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionProductRepairFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductRepairFilterInput | null > | null,
};

export type ModelSubscriptionWarrantyFilterInput = {
  product_id?: ModelSubscriptionIDInput | null,
  current_price?: ModelSubscriptionFloatInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  model_no?: ModelSubscriptionStringInput | null,
  product_code?: ModelSubscriptionStringInput | null,
  retailer_name?: ModelSubscriptionStringInput | null,
  receipts?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  reason?: ModelSubscriptionStringInput | null,
  extension_ids?: ModelSubscriptionIDInput | null,
  order_id?: ModelSubscriptionStringInput | null,
  verification_user_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionWarrantyFilterInput | null > | null,
  or?: Array< ModelSubscriptionWarrantyFilterInput | null > | null,
};

export type ModelSubscriptionWarrantyExtensionFilterInput = {
  thru_date?: ModelSubscriptionStringInput | null,
  approval_user_id?: ModelSubscriptionIDInput | null,
  approval_reasons?: ModelSubscriptionStringInput | null,
  amount_paid?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionWarrantyExtensionFilterInput | null > | null,
  or?: Array< ModelSubscriptionWarrantyExtensionFilterInput | null > | null,
};

export type ModelSubscriptionNotificationFilterInput = {
  email_latest_update?: ModelSubscriptionBooleanInput | null,
  email_tips_and_tutorials?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    brand: string,
    category: string,
    sub_category: string,
    model_no: string,
    product_code: string,
    price: number,
    quantity: number,
    specifications?:  Array< {
      __typename: "SpecificationData",
      title: string,
      specification: string,
    } | null > | null,
    currency: CURRENCY_ENUM,
    images: Array< string | null >,
    in_the_box?:  Array< {
      __typename: "InTheBox",
      productID: string,
      quantity: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    brand: string,
    category: string,
    sub_category: string,
    model_no: string,
    product_code: string,
    price: number,
    quantity: number,
    specifications?:  Array< {
      __typename: "SpecificationData",
      title: string,
      specification: string,
    } | null > | null,
    currency: CURRENCY_ENUM,
    images: Array< string | null >,
    in_the_box?:  Array< {
      __typename: "InTheBox",
      productID: string,
      quantity: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    brand: string,
    category: string,
    sub_category: string,
    model_no: string,
    product_code: string,
    price: number,
    quantity: number,
    specifications?:  Array< {
      __typename: "SpecificationData",
      title: string,
      specification: string,
    } | null > | null,
    currency: CURRENCY_ENUM,
    images: Array< string | null >,
    in_the_box?:  Array< {
      __typename: "InTheBox",
      productID: string,
      quantity: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProductReviewMutationVariables = {
  input: CreateProductReviewInput,
  condition?: ModelProductReviewConditionInput | null,
};

export type CreateProductReviewMutation = {
  createProductReview?:  {
    __typename: "ProductReview",
    id: string,
    productID: string,
    header: string,
    description: string,
    purchased_area: string,
    locationID: string,
    rating: number,
    external_votes?:  {
      __typename: "ExternalVotesCount",
      upvote?: number | null,
      downvote?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductReviewMutationVariables = {
  input: UpdateProductReviewInput,
  condition?: ModelProductReviewConditionInput | null,
};

export type UpdateProductReviewMutation = {
  updateProductReview?:  {
    __typename: "ProductReview",
    id: string,
    productID: string,
    header: string,
    description: string,
    purchased_area: string,
    locationID: string,
    rating: number,
    external_votes?:  {
      __typename: "ExternalVotesCount",
      upvote?: number | null,
      downvote?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductReviewMutationVariables = {
  input: DeleteProductReviewInput,
  condition?: ModelProductReviewConditionInput | null,
};

export type DeleteProductReviewMutation = {
  deleteProductReview?:  {
    __typename: "ProductReview",
    id: string,
    productID: string,
    header: string,
    description: string,
    purchased_area: string,
    locationID: string,
    rating: number,
    external_votes?:  {
      __typename: "ExternalVotesCount",
      upvote?: number | null,
      downvote?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProductCollectionMutationVariables = {
  input: CreateProductCollectionInput,
  condition?: ModelProductCollectionConditionInput | null,
};

export type CreateProductCollectionMutation = {
  createProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    title: string,
    description: string,
    product_code: string,
    condition_type: COLLECTION_CONDITION_TYPE,
    collection: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductCollectionMutationVariables = {
  input: UpdateProductCollectionInput,
  condition?: ModelProductCollectionConditionInput | null,
};

export type UpdateProductCollectionMutation = {
  updateProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    title: string,
    description: string,
    product_code: string,
    condition_type: COLLECTION_CONDITION_TYPE,
    collection: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductCollectionMutationVariables = {
  input: DeleteProductCollectionInput,
  condition?: ModelProductCollectionConditionInput | null,
};

export type DeleteProductCollectionMutation = {
  deleteProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    title: string,
    description: string,
    product_code: string,
    condition_type: COLLECTION_CONDITION_TYPE,
    collection: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProductBundleMutationVariables = {
  input: CreateProductBundleInput,
  condition?: ModelProductBundleConditionInput | null,
};

export type CreateProductBundleMutation = {
  createProductBundle?:  {
    __typename: "ProductBundle",
    id: string,
    title: string,
    sub_title?: string | null,
    description: string,
    product_code: string,
    products:  Array< {
      __typename: "ProductBundleCount",
      product_id: string,
      quantity: number,
    } | null >,
    price: number,
    usage:  Array< {
      __typename: "Usage",
      description: string,
    } | null >,
    currency: CURRENCY_ENUM,
    images?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductBundleMutationVariables = {
  input: UpdateProductBundleInput,
  condition?: ModelProductBundleConditionInput | null,
};

export type UpdateProductBundleMutation = {
  updateProductBundle?:  {
    __typename: "ProductBundle",
    id: string,
    title: string,
    sub_title?: string | null,
    description: string,
    product_code: string,
    products:  Array< {
      __typename: "ProductBundleCount",
      product_id: string,
      quantity: number,
    } | null >,
    price: number,
    usage:  Array< {
      __typename: "Usage",
      description: string,
    } | null >,
    currency: CURRENCY_ENUM,
    images?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductBundleMutationVariables = {
  input: DeleteProductBundleInput,
  condition?: ModelProductBundleConditionInput | null,
};

export type DeleteProductBundleMutation = {
  deleteProductBundle?:  {
    __typename: "ProductBundle",
    id: string,
    title: string,
    sub_title?: string | null,
    description: string,
    product_code: string,
    products:  Array< {
      __typename: "ProductBundleCount",
      product_id: string,
      quantity: number,
    } | null >,
    price: number,
    usage:  Array< {
      __typename: "Usage",
      description: string,
    } | null >,
    currency: CURRENCY_ENUM,
    images?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateJobMutationVariables = {
  input: CreateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type CreateJobMutation = {
  createJob?:  {
    __typename: "Job",
    id: string,
    title: string,
    description: string,
    responsibilities:  Array< {
      __typename: "Responsibilities",
      description: string,
    } | null >,
    qualifications:  Array< {
      __typename: "Qualifications",
      description: string,
    } | null >,
    benefits:  Array< {
      __typename: "Benefits",
      description: string,
    } | null >,
    type: string,
    category: string,
    location_type: string,
    country: string,
    state: string,
    city: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateJobMutationVariables = {
  input: UpdateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type UpdateJobMutation = {
  updateJob?:  {
    __typename: "Job",
    id: string,
    title: string,
    description: string,
    responsibilities:  Array< {
      __typename: "Responsibilities",
      description: string,
    } | null >,
    qualifications:  Array< {
      __typename: "Qualifications",
      description: string,
    } | null >,
    benefits:  Array< {
      __typename: "Benefits",
      description: string,
    } | null >,
    type: string,
    category: string,
    location_type: string,
    country: string,
    state: string,
    city: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteJobMutationVariables = {
  input: DeleteJobInput,
  condition?: ModelJobConditionInput | null,
};

export type DeleteJobMutation = {
  deleteJob?:  {
    __typename: "Job",
    id: string,
    title: string,
    description: string,
    responsibilities:  Array< {
      __typename: "Responsibilities",
      description: string,
    } | null >,
    qualifications:  Array< {
      __typename: "Qualifications",
      description: string,
    } | null >,
    benefits:  Array< {
      __typename: "Benefits",
      description: string,
    } | null >,
    type: string,
    category: string,
    location_type: string,
    country: string,
    state: string,
    city: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNewsletterCandidateMutationVariables = {
  input: CreateNewsletterCandidateInput,
  condition?: ModelNewsletterCandidateConditionInput | null,
};

export type CreateNewsletterCandidateMutation = {
  createNewsletterCandidate?:  {
    __typename: "NewsletterCandidate",
    id: string,
    email: string,
    active: boolean,
    reason?: string | null,
    types:  {
      __typename: "NewsletterTypes",
      latest_updates: boolean,
      tips_and_tutorials: boolean,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateNewsletterCandidateMutationVariables = {
  input: UpdateNewsletterCandidateInput,
  condition?: ModelNewsletterCandidateConditionInput | null,
};

export type UpdateNewsletterCandidateMutation = {
  updateNewsletterCandidate?:  {
    __typename: "NewsletterCandidate",
    id: string,
    email: string,
    active: boolean,
    reason?: string | null,
    types:  {
      __typename: "NewsletterTypes",
      latest_updates: boolean,
      tips_and_tutorials: boolean,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteNewsletterCandidateMutationVariables = {
  input: DeleteNewsletterCandidateInput,
  condition?: ModelNewsletterCandidateConditionInput | null,
};

export type DeleteNewsletterCandidateMutation = {
  deleteNewsletterCandidate?:  {
    __typename: "NewsletterCandidate",
    id: string,
    email: string,
    active: boolean,
    reason?: string | null,
    types:  {
      __typename: "NewsletterTypes",
      latest_updates: boolean,
      tips_and_tutorials: boolean,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateMarketingCandidateMutationVariables = {
  input: CreateMarketingCandidateInput,
  condition?: ModelMarketingCandidateConditionInput | null,
};

export type CreateMarketingCandidateMutation = {
  createMarketingCandidate?:  {
    __typename: "MarketingCandidate",
    id: string,
    user_id: string,
    active: boolean,
    reason: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMarketingCandidateMutationVariables = {
  input: UpdateMarketingCandidateInput,
  condition?: ModelMarketingCandidateConditionInput | null,
};

export type UpdateMarketingCandidateMutation = {
  updateMarketingCandidate?:  {
    __typename: "MarketingCandidate",
    id: string,
    user_id: string,
    active: boolean,
    reason: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMarketingCandidateMutationVariables = {
  input: DeleteMarketingCandidateInput,
  condition?: ModelMarketingCandidateConditionInput | null,
};

export type DeleteMarketingCandidateMutation = {
  deleteMarketingCandidate?:  {
    __typename: "MarketingCandidate",
    id: string,
    user_id: string,
    active: boolean,
    reason: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRetailerMutationVariables = {
  input: CreateRetailerInput,
  condition?: ModelRetailerConditionInput | null,
};

export type CreateRetailerMutation = {
  createRetailer?:  {
    __typename: "Retailer",
    id: string,
    name: string,
    address:  Array< {
      __typename: "Location",
      addr1: string,
      addr2?: string | null,
      postal: string,
      city: string,
      state: string,
      country: string,
      map_location: string,
      contact_num: string,
      opening_time: string,
      opening_days: string,
      email: string,
    } | null >,
    email: string,
    contact_num: string,
    user_ids: Array< string | null >,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateRetailerMutationVariables = {
  input: UpdateRetailerInput,
  condition?: ModelRetailerConditionInput | null,
};

export type UpdateRetailerMutation = {
  updateRetailer?:  {
    __typename: "Retailer",
    id: string,
    name: string,
    address:  Array< {
      __typename: "Location",
      addr1: string,
      addr2?: string | null,
      postal: string,
      city: string,
      state: string,
      country: string,
      map_location: string,
      contact_num: string,
      opening_time: string,
      opening_days: string,
      email: string,
    } | null >,
    email: string,
    contact_num: string,
    user_ids: Array< string | null >,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteRetailerMutationVariables = {
  input: DeleteRetailerInput,
  condition?: ModelRetailerConditionInput | null,
};

export type DeleteRetailerMutation = {
  deleteRetailer?:  {
    __typename: "Retailer",
    id: string,
    name: string,
    address:  Array< {
      __typename: "Location",
      addr1: string,
      addr2?: string | null,
      postal: string,
      city: string,
      state: string,
      country: string,
      map_location: string,
      contact_num: string,
      opening_time: string,
      opening_days: string,
      email: string,
    } | null >,
    email: string,
    contact_num: string,
    user_ids: Array< string | null >,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    customer_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: USER_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    customer_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: USER_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    customer_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: USER_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateEmployeeMutationVariables = {
  input: CreateEmployeeInput,
  condition?: ModelEmployeeConditionInput | null,
};

export type CreateEmployeeMutation = {
  createEmployee?:  {
    __typename: "Employee",
    employee_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: EMPLOYEE_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateEmployeeMutationVariables = {
  input: UpdateEmployeeInput,
  condition?: ModelEmployeeConditionInput | null,
};

export type UpdateEmployeeMutation = {
  updateEmployee?:  {
    __typename: "Employee",
    employee_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: EMPLOYEE_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteEmployeeMutationVariables = {
  input: DeleteEmployeeInput,
  condition?: ModelEmployeeConditionInput | null,
};

export type DeleteEmployeeMutation = {
  deleteEmployee?:  {
    __typename: "Employee",
    employee_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: EMPLOYEE_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCustomerMutationVariables = {
  input: CreateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    id: string,
    type: CustomerType,
    name?: string | null,
    phone?: string | null,
    country?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input: UpdateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "Customer",
    id: string,
    type: CustomerType,
    name?: string | null,
    phone?: string | null,
    country?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCustomerMutationVariables = {
  input: DeleteCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type DeleteCustomerMutation = {
  deleteCustomer?:  {
    __typename: "Customer",
    id: string,
    type: CustomerType,
    name?: string | null,
    phone?: string | null,
    country?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCouponMutationVariables = {
  input: CreateCouponInput,
  condition?: ModelCouponConditionInput | null,
};

export type CreateCouponMutation = {
  createCoupon?:  {
    __typename: "Coupon",
    owner: string,
    coupon_code: string,
    promotion_type?: PromotionType | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCouponMutationVariables = {
  input: UpdateCouponInput,
  condition?: ModelCouponConditionInput | null,
};

export type UpdateCouponMutation = {
  updateCoupon?:  {
    __typename: "Coupon",
    owner: string,
    coupon_code: string,
    promotion_type?: PromotionType | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCouponMutationVariables = {
  input: DeleteCouponInput,
  condition?: ModelCouponConditionInput | null,
};

export type DeleteCouponMutation = {
  deleteCoupon?:  {
    __typename: "Coupon",
    owner: string,
    coupon_code: string,
    promotion_type?: PromotionType | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePromotionMutationVariables = {
  input: CreatePromotionInput,
  condition?: ModelPromotionConditionInput | null,
};

export type CreatePromotionMutation = {
  createPromotion?:  {
    __typename: "Promotion",
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePromotionMutationVariables = {
  input: UpdatePromotionInput,
  condition?: ModelPromotionConditionInput | null,
};

export type UpdatePromotionMutation = {
  updatePromotion?:  {
    __typename: "Promotion",
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePromotionMutationVariables = {
  input: DeletePromotionInput,
  condition?: ModelPromotionConditionInput | null,
};

export type DeletePromotionMutation = {
  deletePromotion?:  {
    __typename: "Promotion",
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateBrowsingHistoryMutationVariables = {
  input: CreateBrowsingHistoryInput,
  condition?: ModelBrowsingHistoryConditionInput | null,
};

export type CreateBrowsingHistoryMutation = {
  createBrowsingHistory?:  {
    __typename: "BrowsingHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBrowsingHistoryMutationVariables = {
  input: UpdateBrowsingHistoryInput,
  condition?: ModelBrowsingHistoryConditionInput | null,
};

export type UpdateBrowsingHistoryMutation = {
  updateBrowsingHistory?:  {
    __typename: "BrowsingHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBrowsingHistoryMutationVariables = {
  input: DeleteBrowsingHistoryInput,
  condition?: ModelBrowsingHistoryConditionInput | null,
};

export type DeleteBrowsingHistoryMutation = {
  deleteBrowsingHistory?:  {
    __typename: "BrowsingHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSearchHistoryMutationVariables = {
  input: CreateSearchHistoryInput,
  condition?: ModelSearchHistoryConditionInput | null,
};

export type CreateSearchHistoryMutation = {
  createSearchHistory?:  {
    __typename: "SearchHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSearchHistoryMutationVariables = {
  input: UpdateSearchHistoryInput,
  condition?: ModelSearchHistoryConditionInput | null,
};

export type UpdateSearchHistoryMutation = {
  updateSearchHistory?:  {
    __typename: "SearchHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSearchHistoryMutationVariables = {
  input: DeleteSearchHistoryInput,
  condition?: ModelSearchHistoryConditionInput | null,
};

export type DeleteSearchHistoryMutation = {
  deleteSearchHistory?:  {
    __typename: "SearchHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFavoriteItemsMutationVariables = {
  input: CreateFavoriteItemsInput,
  condition?: ModelFavoriteItemsConditionInput | null,
};

export type CreateFavoriteItemsMutation = {
  createFavoriteItems?:  {
    __typename: "FavoriteItems",
    id: string,
    customer_id: string,
    favorite:  Array< {
      __typename: "FavoriteItemDetails",
      product_id: string,
      createdAt: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateFavoriteItemsMutationVariables = {
  input: UpdateFavoriteItemsInput,
  condition?: ModelFavoriteItemsConditionInput | null,
};

export type UpdateFavoriteItemsMutation = {
  updateFavoriteItems?:  {
    __typename: "FavoriteItems",
    id: string,
    customer_id: string,
    favorite:  Array< {
      __typename: "FavoriteItemDetails",
      product_id: string,
      createdAt: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteFavoriteItemsMutationVariables = {
  input: DeleteFavoriteItemsInput,
  condition?: ModelFavoriteItemsConditionInput | null,
};

export type DeleteFavoriteItemsMutation = {
  deleteFavoriteItems?:  {
    __typename: "FavoriteItems",
    id: string,
    customer_id: string,
    favorite:  Array< {
      __typename: "FavoriteItemDetails",
      product_id: string,
      createdAt: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCartMutationVariables = {
  input: CreateCartInput,
  condition?: ModelCartConditionInput | null,
};

export type CreateCartMutation = {
  createCart?:  {
    __typename: "Cart",
    id: string,
    products?:  Array< {
      __typename: "CartItem",
      product_id: string,
      quantity: number,
      current_price: number,
      last_seen_price: number,
    } | null > | null,
    status: CartStatus,
    user_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCartMutationVariables = {
  input: UpdateCartInput,
  condition?: ModelCartConditionInput | null,
};

export type UpdateCartMutation = {
  updateCart?:  {
    __typename: "Cart",
    id: string,
    products?:  Array< {
      __typename: "CartItem",
      product_id: string,
      quantity: number,
      current_price: number,
      last_seen_price: number,
    } | null > | null,
    status: CartStatus,
    user_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCartMutationVariables = {
  input: DeleteCartInput,
  condition?: ModelCartConditionInput | null,
};

export type DeleteCartMutation = {
  deleteCart?:  {
    __typename: "Cart",
    id: string,
    products?:  Array< {
      __typename: "CartItem",
      product_id: string,
      quantity: number,
      current_price: number,
      last_seen_price: number,
    } | null > | null,
    status: CartStatus,
    user_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "Order",
    id: string,
    cart_id: string,
    paid: boolean,
    payment_mode?: PaymentMethod | null,
    manual?: boolean | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    id: string,
    cart_id: string,
    paid: boolean,
    payment_mode?: PaymentMethod | null,
    manual?: boolean | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteOrderMutationVariables = {
  input: DeleteOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type DeleteOrderMutation = {
  deleteOrder?:  {
    __typename: "Order",
    id: string,
    cart_id: string,
    paid: boolean,
    payment_mode?: PaymentMethod | null,
    manual?: boolean | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateInvoiceMutationVariables = {
  input: CreateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type CreateInvoiceMutation = {
  createInvoice?:  {
    __typename: "Invoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: FormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateInvoiceMutationVariables = {
  input: UpdateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type UpdateInvoiceMutation = {
  updateInvoice?:  {
    __typename: "Invoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: FormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteInvoiceMutationVariables = {
  input: DeleteInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type DeleteInvoiceMutation = {
  deleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: FormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProformaInvoiceMutationVariables = {
  input: CreateProformaInvoiceInput,
  condition?: ModelProformaInvoiceConditionInput | null,
};

export type CreateProformaInvoiceMutation = {
  createProformaInvoice?:  {
    __typename: "ProformaInvoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: ProformaFormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProformaInvoiceMutationVariables = {
  input: UpdateProformaInvoiceInput,
  condition?: ModelProformaInvoiceConditionInput | null,
};

export type UpdateProformaInvoiceMutation = {
  updateProformaInvoice?:  {
    __typename: "ProformaInvoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: ProformaFormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProformaInvoiceMutationVariables = {
  input: DeleteProformaInvoiceInput,
  condition?: ModelProformaInvoiceConditionInput | null,
};

export type DeleteProformaInvoiceMutation = {
  deleteProformaInvoice?:  {
    __typename: "ProformaInvoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: ProformaFormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreatePaymentMutationVariables = {
  input: CreatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type CreatePaymentMutation = {
  createPayment?:  {
    __typename: "Payment",
    customer_id: string,
    payment_mode: PaymentMethod,
    invoice_id: string,
    amount: number,
    reference?: string | null,
    description?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePaymentMutationVariables = {
  input: UpdatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type UpdatePaymentMutation = {
  updatePayment?:  {
    __typename: "Payment",
    customer_id: string,
    payment_mode: PaymentMethod,
    invoice_id: string,
    amount: number,
    reference?: string | null,
    description?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePaymentMutationVariables = {
  input: DeletePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type DeletePaymentMutation = {
  deletePayment?:  {
    __typename: "Payment",
    customer_id: string,
    payment_mode: PaymentMethod,
    invoice_id: string,
    amount: number,
    reference?: string | null,
    description?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProductRepairMutationVariables = {
  input: CreateProductRepairInput,
  condition?: ModelProductRepairConditionInput | null,
};

export type CreateProductRepairMutation = {
  createProductRepair?:  {
    __typename: "ProductRepair",
    product_id: string,
    tracking_id: string,
    stage: RepairStage,
    price: number,
    under_warranty?: boolean | null,
    product_user:  Array< {
      __typename: "PartsForRepair",
      productId: string,
      unitPrice: number,
      quantity: number,
      UOM: string,
      subTotal: number,
    } | null >,
    discounted_price?: number | null,
    misc?: number | null,
    labourCharges: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductRepairMutationVariables = {
  input: UpdateProductRepairInput,
  condition?: ModelProductRepairConditionInput | null,
};

export type UpdateProductRepairMutation = {
  updateProductRepair?:  {
    __typename: "ProductRepair",
    product_id: string,
    tracking_id: string,
    stage: RepairStage,
    price: number,
    under_warranty?: boolean | null,
    product_user:  Array< {
      __typename: "PartsForRepair",
      productId: string,
      unitPrice: number,
      quantity: number,
      UOM: string,
      subTotal: number,
    } | null >,
    discounted_price?: number | null,
    misc?: number | null,
    labourCharges: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductRepairMutationVariables = {
  input: DeleteProductRepairInput,
  condition?: ModelProductRepairConditionInput | null,
};

export type DeleteProductRepairMutation = {
  deleteProductRepair?:  {
    __typename: "ProductRepair",
    product_id: string,
    tracking_id: string,
    stage: RepairStage,
    price: number,
    under_warranty?: boolean | null,
    product_user:  Array< {
      __typename: "PartsForRepair",
      productId: string,
      unitPrice: number,
      quantity: number,
      UOM: string,
      subTotal: number,
    } | null >,
    discounted_price?: number | null,
    misc?: number | null,
    labourCharges: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateWarrantyMutationVariables = {
  input: CreateWarrantyInput,
  condition?: ModelWarrantyConditionInput | null,
};

export type CreateWarrantyMutation = {
  createWarranty?:  {
    __typename: "Warranty",
    product_id: string,
    current_price: number,
    user_id: string,
    model_no: string,
    product_code: string,
    retailer_name: string,
    receipts: Array< string | null >,
    status: WarrantyStatus,
    reason?: string | null,
    extension_ids?: Array< string | null > | null,
    order_id: string,
    verification_user_id?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateWarrantyMutationVariables = {
  input: UpdateWarrantyInput,
  condition?: ModelWarrantyConditionInput | null,
};

export type UpdateWarrantyMutation = {
  updateWarranty?:  {
    __typename: "Warranty",
    product_id: string,
    current_price: number,
    user_id: string,
    model_no: string,
    product_code: string,
    retailer_name: string,
    receipts: Array< string | null >,
    status: WarrantyStatus,
    reason?: string | null,
    extension_ids?: Array< string | null > | null,
    order_id: string,
    verification_user_id?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteWarrantyMutationVariables = {
  input: DeleteWarrantyInput,
  condition?: ModelWarrantyConditionInput | null,
};

export type DeleteWarrantyMutation = {
  deleteWarranty?:  {
    __typename: "Warranty",
    product_id: string,
    current_price: number,
    user_id: string,
    model_no: string,
    product_code: string,
    retailer_name: string,
    receipts: Array< string | null >,
    status: WarrantyStatus,
    reason?: string | null,
    extension_ids?: Array< string | null > | null,
    order_id: string,
    verification_user_id?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateWarrantyExtensionMutationVariables = {
  input: CreateWarrantyExtensionInput,
  condition?: ModelWarrantyExtensionConditionInput | null,
};

export type CreateWarrantyExtensionMutation = {
  createWarrantyExtension?:  {
    __typename: "WarrantyExtension",
    thru_date: string,
    approval_user_id: string,
    approval_reasons: string,
    amount_paid: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateWarrantyExtensionMutationVariables = {
  input: UpdateWarrantyExtensionInput,
  condition?: ModelWarrantyExtensionConditionInput | null,
};

export type UpdateWarrantyExtensionMutation = {
  updateWarrantyExtension?:  {
    __typename: "WarrantyExtension",
    thru_date: string,
    approval_user_id: string,
    approval_reasons: string,
    amount_paid: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteWarrantyExtensionMutationVariables = {
  input: DeleteWarrantyExtensionInput,
  condition?: ModelWarrantyExtensionConditionInput | null,
};

export type DeleteWarrantyExtensionMutation = {
  deleteWarrantyExtension?:  {
    __typename: "WarrantyExtension",
    thru_date: string,
    approval_user_id: string,
    approval_reasons: string,
    amount_paid: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    email_latest_update: boolean,
    email_tips_and_tutorials: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    email_latest_update: boolean,
    email_tips_and_tutorials: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    email_latest_update: boolean,
    email_tips_and_tutorials: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ProductVerificationMutationVariables = {
  product_id: string,
  user_id: string,
  model_no: string,
  product_code: string,
  retailer_name: string,
  receipts: Array< string | null >,
  status: WarrantyStatus,
  reason?: string | null,
  extension_ids?: Array< string | null > | null,
  order_id: string,
  verification_user_id?: string | null,
};

export type ProductVerificationMutation = {
  productVerification?: string | null,
};

export type ModifyProductVerificationMutationVariables = {
  product_id: string,
  user_id: string,
  retailer_name: string,
  receipts: Array< string | null >,
  status: WarrantyStatus,
  reason?: string | null,
  extension_ids?: Array< string | null > | null,
  order_id: string,
  verification_user_id?: string | null,
};

export type ModifyProductVerificationMutation = {
  modifyProductVerification?: string | null,
};

export type GetProductQueryVariables = {
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    brand: string,
    category: string,
    sub_category: string,
    model_no: string,
    product_code: string,
    price: number,
    quantity: number,
    specifications?:  Array< {
      __typename: "SpecificationData",
      title: string,
      specification: string,
    } | null > | null,
    currency: CURRENCY_ENUM,
    images: Array< string | null >,
    in_the_box?:  Array< {
      __typename: "InTheBox",
      productID: string,
      quantity: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      brand: string,
      category: string,
      sub_category: string,
      model_no: string,
      product_code: string,
      price: number,
      quantity: number,
      currency: CURRENCY_ENUM,
      images: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProductsQueryVariables = {
  filter?: SearchableProductFilterInput | null,
  sort?: Array< SearchableProductSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableProductAggregationInput | null > | null,
};

export type SearchProductsQuery = {
  searchProducts?:  {
    __typename: "SearchableProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      brand: string,
      category: string,
      sub_category: string,
      model_no: string,
      product_code: string,
      price: number,
      quantity: number,
      currency: CURRENCY_ENUM,
      images: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetProductReviewQueryVariables = {
  id: string,
};

export type GetProductReviewQuery = {
  getProductReview?:  {
    __typename: "ProductReview",
    id: string,
    productID: string,
    header: string,
    description: string,
    purchased_area: string,
    locationID: string,
    rating: number,
    external_votes?:  {
      __typename: "ExternalVotesCount",
      upvote?: number | null,
      downvote?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductReviewsQueryVariables = {
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductReviewsQuery = {
  listProductReviews?:  {
    __typename: "ModelProductReviewConnection",
    items:  Array< {
      __typename: "ProductReview",
      id: string,
      productID: string,
      header: string,
      description: string,
      purchased_area: string,
      locationID: string,
      rating: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProductReviewsQueryVariables = {
  filter?: SearchableProductReviewFilterInput | null,
  sort?: Array< SearchableProductReviewSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableProductReviewAggregationInput | null > | null,
};

export type SearchProductReviewsQuery = {
  searchProductReviews?:  {
    __typename: "SearchableProductReviewConnection",
    items:  Array< {
      __typename: "ProductReview",
      id: string,
      productID: string,
      header: string,
      description: string,
      purchased_area: string,
      locationID: string,
      rating: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetProductCollectionQueryVariables = {
  id: string,
};

export type GetProductCollectionQuery = {
  getProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    title: string,
    description: string,
    product_code: string,
    condition_type: COLLECTION_CONDITION_TYPE,
    collection: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductCollectionsQueryVariables = {
  filter?: ModelProductCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductCollectionsQuery = {
  listProductCollections?:  {
    __typename: "ModelProductCollectionConnection",
    items:  Array< {
      __typename: "ProductCollection",
      id: string,
      title: string,
      description: string,
      product_code: string,
      condition_type: COLLECTION_CONDITION_TYPE,
      collection: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProductCollectionsQueryVariables = {
  filter?: SearchableProductCollectionFilterInput | null,
  sort?: Array< SearchableProductCollectionSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableProductCollectionAggregationInput | null > | null,
};

export type SearchProductCollectionsQuery = {
  searchProductCollections?:  {
    __typename: "SearchableProductCollectionConnection",
    items:  Array< {
      __typename: "ProductCollection",
      id: string,
      title: string,
      description: string,
      product_code: string,
      condition_type: COLLECTION_CONDITION_TYPE,
      collection: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetProductBundleQueryVariables = {
  id: string,
};

export type GetProductBundleQuery = {
  getProductBundle?:  {
    __typename: "ProductBundle",
    id: string,
    title: string,
    sub_title?: string | null,
    description: string,
    product_code: string,
    products:  Array< {
      __typename: "ProductBundleCount",
      product_id: string,
      quantity: number,
    } | null >,
    price: number,
    usage:  Array< {
      __typename: "Usage",
      description: string,
    } | null >,
    currency: CURRENCY_ENUM,
    images?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductBundlesQueryVariables = {
  filter?: ModelProductBundleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductBundlesQuery = {
  listProductBundles?:  {
    __typename: "ModelProductBundleConnection",
    items:  Array< {
      __typename: "ProductBundle",
      id: string,
      title: string,
      sub_title?: string | null,
      description: string,
      product_code: string,
      price: number,
      currency: CURRENCY_ENUM,
      images?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProductBundlesQueryVariables = {
  filter?: SearchableProductBundleFilterInput | null,
  sort?: Array< SearchableProductBundleSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableProductBundleAggregationInput | null > | null,
};

export type SearchProductBundlesQuery = {
  searchProductBundles?:  {
    __typename: "SearchableProductBundleConnection",
    items:  Array< {
      __typename: "ProductBundle",
      id: string,
      title: string,
      sub_title?: string | null,
      description: string,
      product_code: string,
      price: number,
      currency: CURRENCY_ENUM,
      images?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetJobQueryVariables = {
  id: string,
};

export type GetJobQuery = {
  getJob?:  {
    __typename: "Job",
    id: string,
    title: string,
    description: string,
    responsibilities:  Array< {
      __typename: "Responsibilities",
      description: string,
    } | null >,
    qualifications:  Array< {
      __typename: "Qualifications",
      description: string,
    } | null >,
    benefits:  Array< {
      __typename: "Benefits",
      description: string,
    } | null >,
    type: string,
    category: string,
    location_type: string,
    country: string,
    state: string,
    city: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListJobsQueryVariables = {
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobsQuery = {
  listJobs?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      title: string,
      description: string,
      type: string,
      category: string,
      location_type: string,
      country: string,
      state: string,
      city: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchJobsQueryVariables = {
  filter?: SearchableJobFilterInput | null,
  sort?: Array< SearchableJobSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableJobAggregationInput | null > | null,
};

export type SearchJobsQuery = {
  searchJobs?:  {
    __typename: "SearchableJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      title: string,
      description: string,
      type: string,
      category: string,
      location_type: string,
      country: string,
      state: string,
      city: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetNewsletterCandidateQueryVariables = {
  id: string,
};

export type GetNewsletterCandidateQuery = {
  getNewsletterCandidate?:  {
    __typename: "NewsletterCandidate",
    id: string,
    email: string,
    active: boolean,
    reason?: string | null,
    types:  {
      __typename: "NewsletterTypes",
      latest_updates: boolean,
      tips_and_tutorials: boolean,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListNewsletterCandidatesQueryVariables = {
  filter?: ModelNewsletterCandidateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNewsletterCandidatesQuery = {
  listNewsletterCandidates?:  {
    __typename: "ModelNewsletterCandidateConnection",
    items:  Array< {
      __typename: "NewsletterCandidate",
      id: string,
      email: string,
      active: boolean,
      reason?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMarketingCandidateQueryVariables = {
  id: string,
};

export type GetMarketingCandidateQuery = {
  getMarketingCandidate?:  {
    __typename: "MarketingCandidate",
    id: string,
    user_id: string,
    active: boolean,
    reason: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMarketingCandidatesQueryVariables = {
  filter?: ModelMarketingCandidateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMarketingCandidatesQuery = {
  listMarketingCandidates?:  {
    __typename: "ModelMarketingCandidateConnection",
    items:  Array< {
      __typename: "MarketingCandidate",
      id: string,
      user_id: string,
      active: boolean,
      reason: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRetailerQueryVariables = {
  id: string,
};

export type GetRetailerQuery = {
  getRetailer?:  {
    __typename: "Retailer",
    id: string,
    name: string,
    address:  Array< {
      __typename: "Location",
      addr1: string,
      addr2?: string | null,
      postal: string,
      city: string,
      state: string,
      country: string,
      map_location: string,
      contact_num: string,
      opening_time: string,
      opening_days: string,
      email: string,
    } | null >,
    email: string,
    contact_num: string,
    user_ids: Array< string | null >,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListRetailersQueryVariables = {
  filter?: ModelRetailerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRetailersQuery = {
  listRetailers?:  {
    __typename: "ModelRetailerConnection",
    items:  Array< {
      __typename: "Retailer",
      id: string,
      name: string,
      email: string,
      contact_num: string,
      user_ids: Array< string | null >,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchRetailersQueryVariables = {
  filter?: SearchableRetailerFilterInput | null,
  sort?: Array< SearchableRetailerSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableRetailerAggregationInput | null > | null,
};

export type SearchRetailersQuery = {
  searchRetailers?:  {
    __typename: "SearchableRetailerConnection",
    items:  Array< {
      __typename: "Retailer",
      id: string,
      name: string,
      email: string,
      contact_num: string,
      user_ids: Array< string | null >,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    customer_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: USER_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      customer_id: string,
      username: string,
      email: string,
      family_name: string,
      given_name: string,
      type: USER_TYPE,
      password?: string | null,
      location?: string | null,
      contact_num: string,
      country: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchUsersQueryVariables = {
  filter?: SearchableUserFilterInput | null,
  sort?: Array< SearchableUserSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableUserAggregationInput | null > | null,
};

export type SearchUsersQuery = {
  searchUsers?:  {
    __typename: "SearchableUserConnection",
    items:  Array< {
      __typename: "User",
      customer_id: string,
      username: string,
      email: string,
      family_name: string,
      given_name: string,
      type: USER_TYPE,
      password?: string | null,
      location?: string | null,
      contact_num: string,
      country: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetEmployeeQueryVariables = {
  id: string,
};

export type GetEmployeeQuery = {
  getEmployee?:  {
    __typename: "Employee",
    employee_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: EMPLOYEE_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListEmployeesQueryVariables = {
  filter?: ModelEmployeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmployeesQuery = {
  listEmployees?:  {
    __typename: "ModelEmployeeConnection",
    items:  Array< {
      __typename: "Employee",
      employee_id: string,
      username: string,
      email: string,
      family_name: string,
      given_name: string,
      type: EMPLOYEE_TYPE,
      password?: string | null,
      location?: string | null,
      contact_num: string,
      country: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchEmployeesQueryVariables = {
  filter?: SearchableEmployeeFilterInput | null,
  sort?: Array< SearchableEmployeeSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableEmployeeAggregationInput | null > | null,
};

export type SearchEmployeesQuery = {
  searchEmployees?:  {
    __typename: "SearchableEmployeeConnection",
    items:  Array< {
      __typename: "Employee",
      employee_id: string,
      username: string,
      email: string,
      family_name: string,
      given_name: string,
      type: EMPLOYEE_TYPE,
      password?: string | null,
      location?: string | null,
      contact_num: string,
      country: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetCustomerQueryVariables = {
  id: string,
};

export type GetCustomerQuery = {
  getCustomer?:  {
    __typename: "Customer",
    id: string,
    type: CustomerType,
    name?: string | null,
    phone?: string | null,
    country?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCustomersQueryVariables = {
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomersQuery = {
  listCustomers?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      type: CustomerType,
      name?: string | null,
      phone?: string | null,
      country?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCustomersQueryVariables = {
  filter?: SearchableCustomerFilterInput | null,
  sort?: Array< SearchableCustomerSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableCustomerAggregationInput | null > | null,
};

export type SearchCustomersQuery = {
  searchCustomers?:  {
    __typename: "SearchableCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      type: CustomerType,
      name?: string | null,
      phone?: string | null,
      country?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetCouponQueryVariables = {
  id: string,
};

export type GetCouponQuery = {
  getCoupon?:  {
    __typename: "Coupon",
    owner: string,
    coupon_code: string,
    promotion_type?: PromotionType | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCouponsQueryVariables = {
  filter?: ModelCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCouponsQuery = {
  listCoupons?:  {
    __typename: "ModelCouponConnection",
    items:  Array< {
      __typename: "Coupon",
      owner: string,
      coupon_code: string,
      promotion_type?: PromotionType | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPromotionQueryVariables = {
  id: string,
};

export type GetPromotionQuery = {
  getPromotion?:  {
    __typename: "Promotion",
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPromotionsQueryVariables = {
  filter?: ModelPromotionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromotionsQuery = {
  listPromotions?:  {
    __typename: "ModelPromotionConnection",
    items:  Array< {
      __typename: "Promotion",
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBrowsingHistoryQueryVariables = {
  id: string,
};

export type GetBrowsingHistoryQuery = {
  getBrowsingHistory?:  {
    __typename: "BrowsingHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBrowsingHistoriesQueryVariables = {
  filter?: ModelBrowsingHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrowsingHistoriesQuery = {
  listBrowsingHistories?:  {
    __typename: "ModelBrowsingHistoryConnection",
    items:  Array< {
      __typename: "BrowsingHistory",
      id: string,
      customer_id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSearchHistoryQueryVariables = {
  id: string,
};

export type GetSearchHistoryQuery = {
  getSearchHistory?:  {
    __typename: "SearchHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSearchHistoriesQueryVariables = {
  filter?: ModelSearchHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSearchHistoriesQuery = {
  listSearchHistories?:  {
    __typename: "ModelSearchHistoryConnection",
    items:  Array< {
      __typename: "SearchHistory",
      id: string,
      customer_id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFavoriteItemsQueryVariables = {
  id: string,
};

export type GetFavoriteItemsQuery = {
  getFavoriteItems?:  {
    __typename: "FavoriteItems",
    id: string,
    customer_id: string,
    favorite:  Array< {
      __typename: "FavoriteItemDetails",
      product_id: string,
      createdAt: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListFavoriteItemsQueryVariables = {
  filter?: ModelFavoriteItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFavoriteItemsQuery = {
  listFavoriteItems?:  {
    __typename: "ModelFavoriteItemsConnection",
    items:  Array< {
      __typename: "FavoriteItems",
      id: string,
      customer_id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCartQueryVariables = {
  id: string,
};

export type GetCartQuery = {
  getCart?:  {
    __typename: "Cart",
    id: string,
    products?:  Array< {
      __typename: "CartItem",
      product_id: string,
      quantity: number,
      current_price: number,
      last_seen_price: number,
    } | null > | null,
    status: CartStatus,
    user_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCartsQueryVariables = {
  filter?: ModelCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCartsQuery = {
  listCarts?:  {
    __typename: "ModelCartConnection",
    items:  Array< {
      __typename: "Cart",
      id: string,
      status: CartStatus,
      user_id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    cart_id: string,
    paid: boolean,
    payment_mode?: PaymentMethod | null,
    manual?: boolean | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      cart_id: string,
      paid: boolean,
      payment_mode?: PaymentMethod | null,
      manual?: boolean | null,
      date?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInvoiceQueryVariables = {
  id: string,
};

export type GetInvoiceQuery = {
  getInvoice?:  {
    __typename: "Invoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: FormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListInvoicesQueryVariables = {
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvoicesQuery = {
  listInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      user_id: string,
      date: string,
      expire_date: string,
      total: string,
      paid: number,
      status: FormStatus,
      created_by: string,
      note?: string | null,
      currency: CURRENCY_ENUM,
      payment_ids?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProformaInvoiceQueryVariables = {
  id: string,
};

export type GetProformaInvoiceQuery = {
  getProformaInvoice?:  {
    __typename: "ProformaInvoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: ProformaFormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProformaInvoicesQueryVariables = {
  filter?: ModelProformaInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProformaInvoicesQuery = {
  listProformaInvoices?:  {
    __typename: "ModelProformaInvoiceConnection",
    items:  Array< {
      __typename: "ProformaInvoice",
      id: string,
      user_id: string,
      date: string,
      expire_date: string,
      total: string,
      paid: number,
      status: ProformaFormStatus,
      created_by: string,
      note?: string | null,
      currency: CURRENCY_ENUM,
      payment_ids?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentQueryVariables = {
  id: string,
};

export type GetPaymentQuery = {
  getPayment?:  {
    __typename: "Payment",
    customer_id: string,
    payment_mode: PaymentMethod,
    invoice_id: string,
    amount: number,
    reference?: string | null,
    description?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPaymentsQueryVariables = {
  filter?: ModelPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentsQuery = {
  listPayments?:  {
    __typename: "ModelPaymentConnection",
    items:  Array< {
      __typename: "Payment",
      customer_id: string,
      payment_mode: PaymentMethod,
      invoice_id: string,
      amount: number,
      reference?: string | null,
      description?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductRepairQueryVariables = {
  id: string,
};

export type GetProductRepairQuery = {
  getProductRepair?:  {
    __typename: "ProductRepair",
    product_id: string,
    tracking_id: string,
    stage: RepairStage,
    price: number,
    under_warranty?: boolean | null,
    product_user:  Array< {
      __typename: "PartsForRepair",
      productId: string,
      unitPrice: number,
      quantity: number,
      UOM: string,
      subTotal: number,
    } | null >,
    discounted_price?: number | null,
    misc?: number | null,
    labourCharges: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductRepairsQueryVariables = {
  filter?: ModelProductRepairFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductRepairsQuery = {
  listProductRepairs?:  {
    __typename: "ModelProductRepairConnection",
    items:  Array< {
      __typename: "ProductRepair",
      product_id: string,
      tracking_id: string,
      stage: RepairStage,
      price: number,
      under_warranty?: boolean | null,
      discounted_price?: number | null,
      misc?: number | null,
      labourCharges: number,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProductRepairsQueryVariables = {
  filter?: SearchableProductRepairFilterInput | null,
  sort?: Array< SearchableProductRepairSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableProductRepairAggregationInput | null > | null,
};

export type SearchProductRepairsQuery = {
  searchProductRepairs?:  {
    __typename: "SearchableProductRepairConnection",
    items:  Array< {
      __typename: "ProductRepair",
      product_id: string,
      tracking_id: string,
      stage: RepairStage,
      price: number,
      under_warranty?: boolean | null,
      discounted_price?: number | null,
      misc?: number | null,
      labourCharges: number,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetWarrantyQueryVariables = {
  id: string,
};

export type GetWarrantyQuery = {
  getWarranty?:  {
    __typename: "Warranty",
    product_id: string,
    current_price: number,
    user_id: string,
    model_no: string,
    product_code: string,
    retailer_name: string,
    receipts: Array< string | null >,
    status: WarrantyStatus,
    reason?: string | null,
    extension_ids?: Array< string | null > | null,
    order_id: string,
    verification_user_id?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListWarrantiesQueryVariables = {
  filter?: ModelWarrantyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWarrantiesQuery = {
  listWarranties?:  {
    __typename: "ModelWarrantyConnection",
    items:  Array< {
      __typename: "Warranty",
      product_id: string,
      current_price: number,
      user_id: string,
      model_no: string,
      product_code: string,
      retailer_name: string,
      receipts: Array< string | null >,
      status: WarrantyStatus,
      reason?: string | null,
      extension_ids?: Array< string | null > | null,
      order_id: string,
      verification_user_id?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWarrantyExtensionQueryVariables = {
  id: string,
};

export type GetWarrantyExtensionQuery = {
  getWarrantyExtension?:  {
    __typename: "WarrantyExtension",
    thru_date: string,
    approval_user_id: string,
    approval_reasons: string,
    amount_paid: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListWarrantyExtensionsQueryVariables = {
  filter?: ModelWarrantyExtensionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWarrantyExtensionsQuery = {
  listWarrantyExtensions?:  {
    __typename: "ModelWarrantyExtensionConnection",
    items:  Array< {
      __typename: "WarrantyExtension",
      thru_date: string,
      approval_user_id: string,
      approval_reasons: string,
      amount_paid: number,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    email_latest_update: boolean,
    email_tips_and_tutorials: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      email_latest_update: boolean,
      email_tips_and_tutorials: boolean,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FetchCheckoutURLQueryVariables = {
  input?: string | null,
};

export type FetchCheckoutURLQuery = {
  fetchCheckoutURL?: string | null,
};

export type FetchLookupDataQueryVariables = {
  input?: string | null,
};

export type FetchLookupDataQuery = {
  fetchLookupData?: string | null,
};

export type OnCreateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    brand: string,
    category: string,
    sub_category: string,
    model_no: string,
    product_code: string,
    price: number,
    quantity: number,
    specifications?:  Array< {
      __typename: "SpecificationData",
      title: string,
      specification: string,
    } | null > | null,
    currency: CURRENCY_ENUM,
    images: Array< string | null >,
    in_the_box?:  Array< {
      __typename: "InTheBox",
      productID: string,
      quantity: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    brand: string,
    category: string,
    sub_category: string,
    model_no: string,
    product_code: string,
    price: number,
    quantity: number,
    specifications?:  Array< {
      __typename: "SpecificationData",
      title: string,
      specification: string,
    } | null > | null,
    currency: CURRENCY_ENUM,
    images: Array< string | null >,
    in_the_box?:  Array< {
      __typename: "InTheBox",
      productID: string,
      quantity: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    brand: string,
    category: string,
    sub_category: string,
    model_no: string,
    product_code: string,
    price: number,
    quantity: number,
    specifications?:  Array< {
      __typename: "SpecificationData",
      title: string,
      specification: string,
    } | null > | null,
    currency: CURRENCY_ENUM,
    images: Array< string | null >,
    in_the_box?:  Array< {
      __typename: "InTheBox",
      productID: string,
      quantity: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateProductReviewSubscriptionVariables = {
  filter?: ModelSubscriptionProductReviewFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductReviewSubscription = {
  onCreateProductReview?:  {
    __typename: "ProductReview",
    id: string,
    productID: string,
    header: string,
    description: string,
    purchased_area: string,
    locationID: string,
    rating: number,
    external_votes?:  {
      __typename: "ExternalVotesCount",
      upvote?: number | null,
      downvote?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductReviewSubscriptionVariables = {
  filter?: ModelSubscriptionProductReviewFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductReviewSubscription = {
  onUpdateProductReview?:  {
    __typename: "ProductReview",
    id: string,
    productID: string,
    header: string,
    description: string,
    purchased_area: string,
    locationID: string,
    rating: number,
    external_votes?:  {
      __typename: "ExternalVotesCount",
      upvote?: number | null,
      downvote?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductReviewSubscriptionVariables = {
  filter?: ModelSubscriptionProductReviewFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductReviewSubscription = {
  onDeleteProductReview?:  {
    __typename: "ProductReview",
    id: string,
    productID: string,
    header: string,
    description: string,
    purchased_area: string,
    locationID: string,
    rating: number,
    external_votes?:  {
      __typename: "ExternalVotesCount",
      upvote?: number | null,
      downvote?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateProductCollectionSubscriptionVariables = {
  filter?: ModelSubscriptionProductCollectionFilterInput | null,
};

export type OnCreateProductCollectionSubscription = {
  onCreateProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    title: string,
    description: string,
    product_code: string,
    condition_type: COLLECTION_CONDITION_TYPE,
    collection: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductCollectionSubscriptionVariables = {
  filter?: ModelSubscriptionProductCollectionFilterInput | null,
};

export type OnUpdateProductCollectionSubscription = {
  onUpdateProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    title: string,
    description: string,
    product_code: string,
    condition_type: COLLECTION_CONDITION_TYPE,
    collection: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductCollectionSubscriptionVariables = {
  filter?: ModelSubscriptionProductCollectionFilterInput | null,
};

export type OnDeleteProductCollectionSubscription = {
  onDeleteProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    title: string,
    description: string,
    product_code: string,
    condition_type: COLLECTION_CONDITION_TYPE,
    collection: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProductBundleSubscriptionVariables = {
  filter?: ModelSubscriptionProductBundleFilterInput | null,
};

export type OnCreateProductBundleSubscription = {
  onCreateProductBundle?:  {
    __typename: "ProductBundle",
    id: string,
    title: string,
    sub_title?: string | null,
    description: string,
    product_code: string,
    products:  Array< {
      __typename: "ProductBundleCount",
      product_id: string,
      quantity: number,
    } | null >,
    price: number,
    usage:  Array< {
      __typename: "Usage",
      description: string,
    } | null >,
    currency: CURRENCY_ENUM,
    images?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductBundleSubscriptionVariables = {
  filter?: ModelSubscriptionProductBundleFilterInput | null,
};

export type OnUpdateProductBundleSubscription = {
  onUpdateProductBundle?:  {
    __typename: "ProductBundle",
    id: string,
    title: string,
    sub_title?: string | null,
    description: string,
    product_code: string,
    products:  Array< {
      __typename: "ProductBundleCount",
      product_id: string,
      quantity: number,
    } | null >,
    price: number,
    usage:  Array< {
      __typename: "Usage",
      description: string,
    } | null >,
    currency: CURRENCY_ENUM,
    images?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductBundleSubscriptionVariables = {
  filter?: ModelSubscriptionProductBundleFilterInput | null,
};

export type OnDeleteProductBundleSubscription = {
  onDeleteProductBundle?:  {
    __typename: "ProductBundle",
    id: string,
    title: string,
    sub_title?: string | null,
    description: string,
    product_code: string,
    products:  Array< {
      __typename: "ProductBundleCount",
      product_id: string,
      quantity: number,
    } | null >,
    price: number,
    usage:  Array< {
      __typename: "Usage",
      description: string,
    } | null >,
    currency: CURRENCY_ENUM,
    images?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnCreateJobSubscription = {
  onCreateJob?:  {
    __typename: "Job",
    id: string,
    title: string,
    description: string,
    responsibilities:  Array< {
      __typename: "Responsibilities",
      description: string,
    } | null >,
    qualifications:  Array< {
      __typename: "Qualifications",
      description: string,
    } | null >,
    benefits:  Array< {
      __typename: "Benefits",
      description: string,
    } | null >,
    type: string,
    category: string,
    location_type: string,
    country: string,
    state: string,
    city: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnUpdateJobSubscription = {
  onUpdateJob?:  {
    __typename: "Job",
    id: string,
    title: string,
    description: string,
    responsibilities:  Array< {
      __typename: "Responsibilities",
      description: string,
    } | null >,
    qualifications:  Array< {
      __typename: "Qualifications",
      description: string,
    } | null >,
    benefits:  Array< {
      __typename: "Benefits",
      description: string,
    } | null >,
    type: string,
    category: string,
    location_type: string,
    country: string,
    state: string,
    city: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnDeleteJobSubscription = {
  onDeleteJob?:  {
    __typename: "Job",
    id: string,
    title: string,
    description: string,
    responsibilities:  Array< {
      __typename: "Responsibilities",
      description: string,
    } | null >,
    qualifications:  Array< {
      __typename: "Qualifications",
      description: string,
    } | null >,
    benefits:  Array< {
      __typename: "Benefits",
      description: string,
    } | null >,
    type: string,
    category: string,
    location_type: string,
    country: string,
    state: string,
    city: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNewsletterCandidateSubscriptionVariables = {
  filter?: ModelSubscriptionNewsletterCandidateFilterInput | null,
  owner?: string | null,
};

export type OnCreateNewsletterCandidateSubscription = {
  onCreateNewsletterCandidate?:  {
    __typename: "NewsletterCandidate",
    id: string,
    email: string,
    active: boolean,
    reason?: string | null,
    types:  {
      __typename: "NewsletterTypes",
      latest_updates: boolean,
      tips_and_tutorials: boolean,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateNewsletterCandidateSubscriptionVariables = {
  filter?: ModelSubscriptionNewsletterCandidateFilterInput | null,
  owner?: string | null,
};

export type OnUpdateNewsletterCandidateSubscription = {
  onUpdateNewsletterCandidate?:  {
    __typename: "NewsletterCandidate",
    id: string,
    email: string,
    active: boolean,
    reason?: string | null,
    types:  {
      __typename: "NewsletterTypes",
      latest_updates: boolean,
      tips_and_tutorials: boolean,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteNewsletterCandidateSubscriptionVariables = {
  filter?: ModelSubscriptionNewsletterCandidateFilterInput | null,
  owner?: string | null,
};

export type OnDeleteNewsletterCandidateSubscription = {
  onDeleteNewsletterCandidate?:  {
    __typename: "NewsletterCandidate",
    id: string,
    email: string,
    active: boolean,
    reason?: string | null,
    types:  {
      __typename: "NewsletterTypes",
      latest_updates: boolean,
      tips_and_tutorials: boolean,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateMarketingCandidateSubscriptionVariables = {
  filter?: ModelSubscriptionMarketingCandidateFilterInput | null,
};

export type OnCreateMarketingCandidateSubscription = {
  onCreateMarketingCandidate?:  {
    __typename: "MarketingCandidate",
    id: string,
    user_id: string,
    active: boolean,
    reason: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMarketingCandidateSubscriptionVariables = {
  filter?: ModelSubscriptionMarketingCandidateFilterInput | null,
};

export type OnUpdateMarketingCandidateSubscription = {
  onUpdateMarketingCandidate?:  {
    __typename: "MarketingCandidate",
    id: string,
    user_id: string,
    active: boolean,
    reason: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMarketingCandidateSubscriptionVariables = {
  filter?: ModelSubscriptionMarketingCandidateFilterInput | null,
};

export type OnDeleteMarketingCandidateSubscription = {
  onDeleteMarketingCandidate?:  {
    __typename: "MarketingCandidate",
    id: string,
    user_id: string,
    active: boolean,
    reason: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRetailerSubscriptionVariables = {
  filter?: ModelSubscriptionRetailerFilterInput | null,
  owner?: string | null,
};

export type OnCreateRetailerSubscription = {
  onCreateRetailer?:  {
    __typename: "Retailer",
    id: string,
    name: string,
    address:  Array< {
      __typename: "Location",
      addr1: string,
      addr2?: string | null,
      postal: string,
      city: string,
      state: string,
      country: string,
      map_location: string,
      contact_num: string,
      opening_time: string,
      opening_days: string,
      email: string,
    } | null >,
    email: string,
    contact_num: string,
    user_ids: Array< string | null >,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateRetailerSubscriptionVariables = {
  filter?: ModelSubscriptionRetailerFilterInput | null,
  owner?: string | null,
};

export type OnUpdateRetailerSubscription = {
  onUpdateRetailer?:  {
    __typename: "Retailer",
    id: string,
    name: string,
    address:  Array< {
      __typename: "Location",
      addr1: string,
      addr2?: string | null,
      postal: string,
      city: string,
      state: string,
      country: string,
      map_location: string,
      contact_num: string,
      opening_time: string,
      opening_days: string,
      email: string,
    } | null >,
    email: string,
    contact_num: string,
    user_ids: Array< string | null >,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteRetailerSubscriptionVariables = {
  filter?: ModelSubscriptionRetailerFilterInput | null,
  owner?: string | null,
};

export type OnDeleteRetailerSubscription = {
  onDeleteRetailer?:  {
    __typename: "Retailer",
    id: string,
    name: string,
    address:  Array< {
      __typename: "Location",
      addr1: string,
      addr2?: string | null,
      postal: string,
      city: string,
      state: string,
      country: string,
      map_location: string,
      contact_num: string,
      opening_time: string,
      opening_days: string,
      email: string,
    } | null >,
    email: string,
    contact_num: string,
    user_ids: Array< string | null >,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    customer_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: USER_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    customer_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: USER_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    customer_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: USER_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateEmployeeSubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeFilterInput | null,
  owner?: string | null,
};

export type OnCreateEmployeeSubscription = {
  onCreateEmployee?:  {
    __typename: "Employee",
    employee_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: EMPLOYEE_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateEmployeeSubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeFilterInput | null,
  owner?: string | null,
};

export type OnUpdateEmployeeSubscription = {
  onUpdateEmployee?:  {
    __typename: "Employee",
    employee_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: EMPLOYEE_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteEmployeeSubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeFilterInput | null,
  owner?: string | null,
};

export type OnDeleteEmployeeSubscription = {
  onDeleteEmployee?:  {
    __typename: "Employee",
    employee_id: string,
    username: string,
    email: string,
    family_name: string,
    given_name: string,
    type: EMPLOYEE_TYPE,
    password?: string | null,
    location?: string | null,
    contact_num: string,
    country: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
  owner?: string | null,
};

export type OnCreateCustomerSubscription = {
  onCreateCustomer?:  {
    __typename: "Customer",
    id: string,
    type: CustomerType,
    name?: string | null,
    phone?: string | null,
    country?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCustomerSubscription = {
  onUpdateCustomer?:  {
    __typename: "Customer",
    id: string,
    type: CustomerType,
    name?: string | null,
    phone?: string | null,
    country?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCustomerSubscription = {
  onDeleteCustomer?:  {
    __typename: "Customer",
    id: string,
    type: CustomerType,
    name?: string | null,
    phone?: string | null,
    country?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCouponSubscriptionVariables = {
  filter?: ModelSubscriptionCouponFilterInput | null,
  owner?: string | null,
};

export type OnCreateCouponSubscription = {
  onCreateCoupon?:  {
    __typename: "Coupon",
    owner: string,
    coupon_code: string,
    promotion_type?: PromotionType | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCouponSubscriptionVariables = {
  filter?: ModelSubscriptionCouponFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCouponSubscription = {
  onUpdateCoupon?:  {
    __typename: "Coupon",
    owner: string,
    coupon_code: string,
    promotion_type?: PromotionType | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCouponSubscriptionVariables = {
  filter?: ModelSubscriptionCouponFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCouponSubscription = {
  onDeleteCoupon?:  {
    __typename: "Coupon",
    owner: string,
    coupon_code: string,
    promotion_type?: PromotionType | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePromotionSubscriptionVariables = {
  filter?: ModelSubscriptionPromotionFilterInput | null,
};

export type OnCreatePromotionSubscription = {
  onCreatePromotion?:  {
    __typename: "Promotion",
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePromotionSubscriptionVariables = {
  filter?: ModelSubscriptionPromotionFilterInput | null,
};

export type OnUpdatePromotionSubscription = {
  onUpdatePromotion?:  {
    __typename: "Promotion",
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePromotionSubscriptionVariables = {
  filter?: ModelSubscriptionPromotionFilterInput | null,
};

export type OnDeletePromotionSubscription = {
  onDeletePromotion?:  {
    __typename: "Promotion",
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateBrowsingHistorySubscriptionVariables = {
  filter?: ModelSubscriptionBrowsingHistoryFilterInput | null,
};

export type OnCreateBrowsingHistorySubscription = {
  onCreateBrowsingHistory?:  {
    __typename: "BrowsingHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBrowsingHistorySubscriptionVariables = {
  filter?: ModelSubscriptionBrowsingHistoryFilterInput | null,
};

export type OnUpdateBrowsingHistorySubscription = {
  onUpdateBrowsingHistory?:  {
    __typename: "BrowsingHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBrowsingHistorySubscriptionVariables = {
  filter?: ModelSubscriptionBrowsingHistoryFilterInput | null,
};

export type OnDeleteBrowsingHistorySubscription = {
  onDeleteBrowsingHistory?:  {
    __typename: "BrowsingHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSearchHistorySubscriptionVariables = {
  filter?: ModelSubscriptionSearchHistoryFilterInput | null,
};

export type OnCreateSearchHistorySubscription = {
  onCreateSearchHistory?:  {
    __typename: "SearchHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSearchHistorySubscriptionVariables = {
  filter?: ModelSubscriptionSearchHistoryFilterInput | null,
};

export type OnUpdateSearchHistorySubscription = {
  onUpdateSearchHistory?:  {
    __typename: "SearchHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSearchHistorySubscriptionVariables = {
  filter?: ModelSubscriptionSearchHistoryFilterInput | null,
};

export type OnDeleteSearchHistorySubscription = {
  onDeleteSearchHistory?:  {
    __typename: "SearchHistory",
    id: string,
    customer_id: string,
    viewed:  Array< {
      __typename: "HistoryItemDetails",
      product_id: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFavoriteItemsSubscriptionVariables = {
  filter?: ModelSubscriptionFavoriteItemsFilterInput | null,
  owner?: string | null,
};

export type OnCreateFavoriteItemsSubscription = {
  onCreateFavoriteItems?:  {
    __typename: "FavoriteItems",
    id: string,
    customer_id: string,
    favorite:  Array< {
      __typename: "FavoriteItemDetails",
      product_id: string,
      createdAt: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateFavoriteItemsSubscriptionVariables = {
  filter?: ModelSubscriptionFavoriteItemsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFavoriteItemsSubscription = {
  onUpdateFavoriteItems?:  {
    __typename: "FavoriteItems",
    id: string,
    customer_id: string,
    favorite:  Array< {
      __typename: "FavoriteItemDetails",
      product_id: string,
      createdAt: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteFavoriteItemsSubscriptionVariables = {
  filter?: ModelSubscriptionFavoriteItemsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFavoriteItemsSubscription = {
  onDeleteFavoriteItems?:  {
    __typename: "FavoriteItems",
    id: string,
    customer_id: string,
    favorite:  Array< {
      __typename: "FavoriteItemDetails",
      product_id: string,
      createdAt: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCartSubscriptionVariables = {
  filter?: ModelSubscriptionCartFilterInput | null,
  owner?: string | null,
};

export type OnCreateCartSubscription = {
  onCreateCart?:  {
    __typename: "Cart",
    id: string,
    products?:  Array< {
      __typename: "CartItem",
      product_id: string,
      quantity: number,
      current_price: number,
      last_seen_price: number,
    } | null > | null,
    status: CartStatus,
    user_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCartSubscriptionVariables = {
  filter?: ModelSubscriptionCartFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCartSubscription = {
  onUpdateCart?:  {
    __typename: "Cart",
    id: string,
    products?:  Array< {
      __typename: "CartItem",
      product_id: string,
      quantity: number,
      current_price: number,
      last_seen_price: number,
    } | null > | null,
    status: CartStatus,
    user_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCartSubscriptionVariables = {
  filter?: ModelSubscriptionCartFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCartSubscription = {
  onDeleteCart?:  {
    __typename: "Cart",
    id: string,
    products?:  Array< {
      __typename: "CartItem",
      product_id: string,
      quantity: number,
      current_price: number,
      last_seen_price: number,
    } | null > | null,
    status: CartStatus,
    user_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
  owner?: string | null,
};

export type OnCreateOrderSubscription = {
  onCreateOrder?:  {
    __typename: "Order",
    id: string,
    cart_id: string,
    paid: boolean,
    payment_mode?: PaymentMethod | null,
    manual?: boolean | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
  owner?: string | null,
};

export type OnUpdateOrderSubscription = {
  onUpdateOrder?:  {
    __typename: "Order",
    id: string,
    cart_id: string,
    paid: boolean,
    payment_mode?: PaymentMethod | null,
    manual?: boolean | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
  owner?: string | null,
};

export type OnDeleteOrderSubscription = {
  onDeleteOrder?:  {
    __typename: "Order",
    id: string,
    cart_id: string,
    paid: boolean,
    payment_mode?: PaymentMethod | null,
    manual?: boolean | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
  owner?: string | null,
};

export type OnCreateInvoiceSubscription = {
  onCreateInvoice?:  {
    __typename: "Invoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: FormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
  owner?: string | null,
};

export type OnUpdateInvoiceSubscription = {
  onUpdateInvoice?:  {
    __typename: "Invoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: FormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
  owner?: string | null,
};

export type OnDeleteInvoiceSubscription = {
  onDeleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: FormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateProformaInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionProformaInvoiceFilterInput | null,
  owner?: string | null,
};

export type OnCreateProformaInvoiceSubscription = {
  onCreateProformaInvoice?:  {
    __typename: "ProformaInvoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: ProformaFormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProformaInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionProformaInvoiceFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProformaInvoiceSubscription = {
  onUpdateProformaInvoice?:  {
    __typename: "ProformaInvoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: ProformaFormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProformaInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionProformaInvoiceFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProformaInvoiceSubscription = {
  onDeleteProformaInvoice?:  {
    __typename: "ProformaInvoice",
    id: string,
    user_id: string,
    date: string,
    expire_date: string,
    total: string,
    paid: number,
    status: ProformaFormStatus,
    created_by: string,
    items:  Array< {
      __typename: "InvoiceItems",
      product_id: string,
      description?: string | null,
      quantity: number,
      price: number,
    } | null >,
    note?: string | null,
    currency: CURRENCY_ENUM,
    payment_ids?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreatePaymentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentFilterInput | null,
  owner?: string | null,
};

export type OnCreatePaymentSubscription = {
  onCreatePayment?:  {
    __typename: "Payment",
    customer_id: string,
    payment_mode: PaymentMethod,
    invoice_id: string,
    amount: number,
    reference?: string | null,
    description?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePaymentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePaymentSubscription = {
  onUpdatePayment?:  {
    __typename: "Payment",
    customer_id: string,
    payment_mode: PaymentMethod,
    invoice_id: string,
    amount: number,
    reference?: string | null,
    description?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePaymentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentFilterInput | null,
  owner?: string | null,
};

export type OnDeletePaymentSubscription = {
  onDeletePayment?:  {
    __typename: "Payment",
    customer_id: string,
    payment_mode: PaymentMethod,
    invoice_id: string,
    amount: number,
    reference?: string | null,
    description?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateProductRepairSubscriptionVariables = {
  filter?: ModelSubscriptionProductRepairFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductRepairSubscription = {
  onCreateProductRepair?:  {
    __typename: "ProductRepair",
    product_id: string,
    tracking_id: string,
    stage: RepairStage,
    price: number,
    under_warranty?: boolean | null,
    product_user:  Array< {
      __typename: "PartsForRepair",
      productId: string,
      unitPrice: number,
      quantity: number,
      UOM: string,
      subTotal: number,
    } | null >,
    discounted_price?: number | null,
    misc?: number | null,
    labourCharges: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductRepairSubscriptionVariables = {
  filter?: ModelSubscriptionProductRepairFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductRepairSubscription = {
  onUpdateProductRepair?:  {
    __typename: "ProductRepair",
    product_id: string,
    tracking_id: string,
    stage: RepairStage,
    price: number,
    under_warranty?: boolean | null,
    product_user:  Array< {
      __typename: "PartsForRepair",
      productId: string,
      unitPrice: number,
      quantity: number,
      UOM: string,
      subTotal: number,
    } | null >,
    discounted_price?: number | null,
    misc?: number | null,
    labourCharges: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductRepairSubscriptionVariables = {
  filter?: ModelSubscriptionProductRepairFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductRepairSubscription = {
  onDeleteProductRepair?:  {
    __typename: "ProductRepair",
    product_id: string,
    tracking_id: string,
    stage: RepairStage,
    price: number,
    under_warranty?: boolean | null,
    product_user:  Array< {
      __typename: "PartsForRepair",
      productId: string,
      unitPrice: number,
      quantity: number,
      UOM: string,
      subTotal: number,
    } | null >,
    discounted_price?: number | null,
    misc?: number | null,
    labourCharges: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateWarrantySubscriptionVariables = {
  filter?: ModelSubscriptionWarrantyFilterInput | null,
  owner?: string | null,
};

export type OnCreateWarrantySubscription = {
  onCreateWarranty?:  {
    __typename: "Warranty",
    product_id: string,
    current_price: number,
    user_id: string,
    model_no: string,
    product_code: string,
    retailer_name: string,
    receipts: Array< string | null >,
    status: WarrantyStatus,
    reason?: string | null,
    extension_ids?: Array< string | null > | null,
    order_id: string,
    verification_user_id?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateWarrantySubscriptionVariables = {
  filter?: ModelSubscriptionWarrantyFilterInput | null,
  owner?: string | null,
};

export type OnUpdateWarrantySubscription = {
  onUpdateWarranty?:  {
    __typename: "Warranty",
    product_id: string,
    current_price: number,
    user_id: string,
    model_no: string,
    product_code: string,
    retailer_name: string,
    receipts: Array< string | null >,
    status: WarrantyStatus,
    reason?: string | null,
    extension_ids?: Array< string | null > | null,
    order_id: string,
    verification_user_id?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteWarrantySubscriptionVariables = {
  filter?: ModelSubscriptionWarrantyFilterInput | null,
  owner?: string | null,
};

export type OnDeleteWarrantySubscription = {
  onDeleteWarranty?:  {
    __typename: "Warranty",
    product_id: string,
    current_price: number,
    user_id: string,
    model_no: string,
    product_code: string,
    retailer_name: string,
    receipts: Array< string | null >,
    status: WarrantyStatus,
    reason?: string | null,
    extension_ids?: Array< string | null > | null,
    order_id: string,
    verification_user_id?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateWarrantyExtensionSubscriptionVariables = {
  filter?: ModelSubscriptionWarrantyExtensionFilterInput | null,
  owner?: string | null,
};

export type OnCreateWarrantyExtensionSubscription = {
  onCreateWarrantyExtension?:  {
    __typename: "WarrantyExtension",
    thru_date: string,
    approval_user_id: string,
    approval_reasons: string,
    amount_paid: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateWarrantyExtensionSubscriptionVariables = {
  filter?: ModelSubscriptionWarrantyExtensionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateWarrantyExtensionSubscription = {
  onUpdateWarrantyExtension?:  {
    __typename: "WarrantyExtension",
    thru_date: string,
    approval_user_id: string,
    approval_reasons: string,
    amount_paid: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteWarrantyExtensionSubscriptionVariables = {
  filter?: ModelSubscriptionWarrantyExtensionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteWarrantyExtensionSubscription = {
  onDeleteWarrantyExtension?:  {
    __typename: "WarrantyExtension",
    thru_date: string,
    approval_user_id: string,
    approval_reasons: string,
    amount_paid: number,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
  owner?: string | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    email_latest_update: boolean,
    email_tips_and_tutorials: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    email_latest_update: boolean,
    email_tips_and_tutorials: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    email_latest_update: boolean,
    email_tips_and_tutorials: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};
