import queryString from "query-string";

/*
    WARNING: Do not import anything into this file other than what exists here. The only thing that can be changed
    is the paths
*/

const basePaths = {
  company: '/company',
  shop: '/shop',
  products: '/products',
  support: '/support',
  sales: '/sales/guest',
  dashboard: '/user/account',
  dashboardService: '/user/service',
  dashboardUtils: '/user/utils'
}

const companyUrl = (postfix: string) => `${basePaths.company}${postfix}`;
const shopUrl = (postfix: string) => `${basePaths.shop}${postfix}`;
const productsUrl = (postfix: string) => `${basePaths.products}${postfix}`;
const supportUrl = (postfix: string) => `${basePaths.support}${postfix}`;
const salesUrl = (postfix: string) => `${basePaths.sales}${postfix}`;
const dashboardUrl = (postfix: string) => `${basePaths.dashboard}${postfix}`;
const dashboardUtilsUrl = (postfix: string) => `${basePaths.dashboardUtils}${postfix}`;

export const paths = {
  LandingPage: "/",
  PartsServices: "/parts-services",
  LoginPage: "/login",
  RegisterPage: "/register",
  RegistrationConfirmationPage: "/register/confirm",
  ForgotPasswordPage: "/forgot-password",
  ResetPasswordPage: "/reset-password/:id",
  JobsPage: companyUrl("/jobs"),
  JobSearchPage: companyUrl("/job-search"),
  JobDetailsPage: companyUrl("/job/:id"),
  PrivacyPage: companyUrl("/privacy-policy"),
  ThirdPartyPage: companyUrl("/disclaimer-third-party"),
  LegalPage: companyUrl("/legal"),
  TermsPage: companyUrl("/terms-condition"),
  AboutPage: companyUrl("/about"),
  ClientsPage: companyUrl("/clients"),
  BecomeRetailerPage: companyUrl("/be-a-retailer"),
  LocationPage: companyUrl("/locations"),
  AffliatesPage: companyUrl("/affliate"),
  ShopPage: basePaths.shop,
  UnityCelPage: shopUrl("/unitycel-20"),
  CatalogsPage: shopUrl("/catalogs"),
  ProductsPage: basePaths.products,
  ProductDetailsPageRaw: productsUrl("/d/"),
  ProductDetailsPage: productsUrl("/d/:model_no"),
  SuccessPurchasedPage: "/success",
  ContactPage: supportUrl("/contact"),
  LiveChatPage: supportUrl("/live-chat"),
  TrackOrderPage: salesUrl("/track"),
  ReturnsPage: salesUrl("/returns"),
  RegisterProductPage: salesUrl("/register"),
  AccountFAQPage: "/faq-account",
  ProductFAQPage: "/faq-product",
  WarrantyPage: "/warranty",
  FindRetailerPage: "/find-retailers",
  UnsubscribedPage: "/unsubscribe/:campaign/:id",
  ErrorsPage: "*",
};

export const dashboardPaths = {
  OrdersPage: dashboardUrl("/orders"),
  AddOrdersPage: dashboardUrl("/orders/add"),
  BuyAgainPage: dashboardUrl("/buy-again"),
  InProcessPage: dashboardUrl("/in-process"),
  CancelledOrdersPage: dashboardUrl("/cancelled-orders"),
  MyProductsPage: dashboardUrl("/my-products"),
  SubscriptionsPage: dashboardUrl("/subscriptions"),
  WarrantyPage: dashboardUrl("/warranty"),
  CheckProduct: dashboardUrl("/check-product"),
  ReferralsPage: dashboardUrl("/referrals"),
  PaymentsPage: dashboardUtilsUrl("/payments"),
  SettingsPage: dashboardUtilsUrl("/settings"),
};

export const externalPaths = {
  messenger: 'https://m.me/TigatanResources'
}

export const pathWithQuery = (path, queryValues) => {
  const str = `?${queryString.stringify(queryValues)}`;
  if (Object.keys(queryValues).length === 0) {
    return path;
  }
  return `${path}${str}`;
};

export const mergePaths = (ext, path) => {
  return `/${ext}${path}`;
};
