import { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Box,
  Button,
  Checkbox,
  // Card,
  // CardContent,
  // Chip,
  ClickAwayListener,
  // Divider,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  // InputAdornment,
  // Link,
  // List,
  // ListItemButton,
  // ListItemIcon,
  // ListItemText,
  // OutlinedInput,
  Paper,
  Popper,
  Radio,
  // Stack,
  // Switch,
  RadioGroup,

  // Typography,
} from "@mui/material";
import Transitions from "ui-component/extended/Transitions";

import TuneIcon from "@mui/icons-material/Tune";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import * as filterConstants from "store/constants/filters";
import { ApplicationState } from "store/reducers";
import { ProductFilterState, SORT_BY } from "store/state/filters";
import { categories } from "utils/categories";
import { productFilteredRoute } from "navigation/constants";

const initialFilter: ProductFilterState = {
  sortBy: "featured"
}

export default function FilterActionButton() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // @ts-ignore
  const { products } = useSelector((state: ApplicationState) => state.filters);
  // for name
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [subCategories, setSubcategories] = useState<any[]>([]);
  const [filter, setFilter] = useState<ProductFilterState>(initialFilter);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleToggle = () => {
    // find products with filter set
    if (open) {
      dispatch({
        type: filterConstants.SET_PRODUCT_FILTER,
        payload: { filter },
      });

      if (filter.category && !filter.sub_category) {
        navigate(productFilteredRoute(filter.category))
      }
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    // @ts-ignore
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // @ts-ignore
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClearFilters = () => {
    setSelectedCategory("");
    setSubcategories([]);
    setFilter(initialFilter);
    dispatch({
      type: filterConstants.SET_PRODUCT_FILTER,
      payload: { filter: {} },
    });
  };

  const getInitialFilter = () => {  
    const category = searchParams.get("c") || undefined;
    setFilter({
      ...filter,
      category
    })
  }

  useEffect(() => {
    getInitialFilter();
  }, [searchParams])

  const header = (
    <Grid
      className="product-category-breadcrumb"
      sx={{
        py: 1,
        px: 1,
        borderRadius: 2.5,
        // @ts-ignore
        backgroundColor: theme.palette.orange.dark,
      }}
      container
    >
      <Grid
        xs={6}
        display="flex"
        justifyContent="flexStart"
        alignItems="center"
        sx={{ color: theme.palette.common.white }}
      >
        <TuneIcon sx={{ mr: 1 }} />
        {"Filter & Sort"}
      </Grid>
      <Grid xs={6} display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          size="small"
          color="primary"
          sx={{
            borderRadius: "25px",
            padding: "5px 20px",
            mr: 0,
            color: theme.palette.common.white,
            borderWidth: "1px",
            borderStyle: "solid",
          }}
          className="clear-filters-button"
          onClick={handleClearFilters}
        >
          Clear filters
        </Button>
      </Grid>
    </Grid>
  );

  const handleChangeSort = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      sortBy: event.target.value,
    });
  };

  const sortContainer = (
    <Grid xs={12}>
      <FormControl
        sx={{
          p: "5px",
        }}
      >
        <FormLabel
          sx={{
            color: theme.palette.common.white,
            fontSize: 18,
            fontWeight: "bold",
            px: "5px",
          }}
          id="sort-by-radio-buttons-group-label"
        >
          Sort by
        </FormLabel>
        <RadioGroup
          aria-labelledby="sort-by-radio-buttons-group-label"
          name="radio-buttons-group"
          value={filter?.sortBy}
          onChange={handleChangeSort}
        >
          {Object.entries(SORT_BY).map(([key, value]) => (
            <FormControlLabel
              sx={{ color: theme.palette.common.white, m: "-5px" }}
              value={key}
              control={<Radio size="small" />}
              label={value}
              onClick={() => {
                setFilter({
                  ...filter,
                  sortBy: key,
                });
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );

  const handleChangeCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      category: event.target.value,
      sub_category: undefined
    });
  };

  const categoryContainer = (
    <Grid xs={12}>
      <FormControl sx={{ p: "5px" }} component="fieldset" variant="standard">
        <FormLabel
          sx={{
            color: theme.palette.common.white,
            fontSize: 18,
            fontWeight: "bold",
            px: "5px",
          }}
          id="product-type-checkbox-group-label"
        >
          Product categories
        </FormLabel>
        <RadioGroup
          aria-labelledby="sort-by-radio-buttons-group-label"
          defaultValue="featured"
          name="radio-buttons-group"
          value={filter?.category || null}
          onChange={handleChangeCategory}
        >
          {categories.map((c) => (
            <FormControlLabel
              sx={{ color: theme.palette.common.white, m: "-5px" }}
              value={c.alias}
              control={<Radio size="small" />}
              label={c.title}
              onClick={() => {
                setSelectedCategory(c.title);
                setSubcategories(c.subCategories);
              }}
            />
          ))}
        </RadioGroup>
        {/* <FormGroup>
          {categories.map((c, key) => (
            <FormControlLabel
              sx={{ color: theme.palette.common.white, m: "-5px" }}
              key={key}
              control={
                <Checkbox
                  onClick={
                    () => console.log(c.subCategories)
                    // navigate(`/products?c=${c.db ? c.db : c.alias}`)
                  }
                  // checked={checked === c.alias}
                  // onChange={handleCategoriesChange}
                  name={c.alias}
                />
              }
              label={c.title}
            />
          ))}
        </FormGroup> */}
      </FormControl>
    </Grid>
  );

  const subCategoriesContainer = (
    <Grid xs={12}>
      <FormControl sx={{ p: "5px" }} component="fieldset" variant="standard">
        <FormLabel
          sx={{
            color: theme.palette.common.white,
            fontSize: 18,
            fontWeight: "bold",
            px: "5px",
          }}
          id="product-type-checkbox-group-label"
        >
          {selectedCategory} (optional)
        </FormLabel>
        <FormGroup>
          {subCategories.map((c, key) => (
            <FormControlLabel
              sx={{ color: theme.palette.common.white, m: "-5px" }}
              key={key}
              control={
                <Checkbox
                  onChange={
                    () => {
                      const scFilter: string[] = filter.sub_category || [];
                      let newFilter: string[] = [];
                      const dbField = c.db ? c.db : c.alias;
                      if (scFilter?.includes(dbField)) {
                        newFilter = scFilter.filter((val) => val !== dbField);
                      } else {
                        newFilter = scFilter;
                        newFilter.push(dbField);
                      }
                      setFilter({
                        ...filter,
                        sub_category: newFilter,
                      });
                    }
                    // navigate(`/products?c=${c.db ? c.db : c.alias}`)
                  }
                  checked={filter?.sub_category?.includes(c.alias)}
                  name={c.alias}
                />
              }
              label={c.title}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Grid>
  );

  return (
    <>
      <Grid
        xs={9}
        sm={9}
        md={9}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Fab
          component="div"
          onClick={handleToggle}
          size="large"
          variant="extended"
          sx={{
            borderRadius: "25px",
            // @ts-ignore
            borderColor: theme.palette.orange.dark,
            borderStyle: "solid",
            borderWidth: "5px",
            // @ts-ignore
            background: theme.palette.orange.light,
            width: "100%",
          }}
          ref={anchorRef}
        >
          <TuneIcon sx={{ mr: 1 }} />
          {open ? "View results" : "Filter + Sort"}
        </Fab>
      </Grid>
      <Popper
        placement="bottom-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          // @ts-ignore
          <Transitions {...TransitionProps}>
            <Paper
              sx={{
                width: "95vw",
                maxHeight: "80vh",
                borderRadius: "15px",
                backgroundColor: theme.palette.secondary.dark,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  {header}
                  <PerfectScrollbar
                    component="div"
                    style={{
                      height: "70vh",
                    }}
                  >
                    <Grid container>
                      {sortContainer}
                      {categoryContainer}
                      {subCategories.length > 0 && subCategoriesContainer}
                    </Grid>
                  </PerfectScrollbar>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
}
