import { createContext, useContext, useReducer, useMemo } from "react";

interface ThemeContextType {
  darkMode: boolean;
  miniSidenav: boolean;
  transparentSidenav: boolean;
  sidenavColor: any;
  transparentNavbar: boolean;
  fixedNavbar: boolean;
  openConfigurator: boolean;
}

const initialState = {
  miniSidenav: false,
  transparentSidenav: true,
  sidenavColor: "info",
  transparentNavbar: true,
  fixedNavbar: true,
  openConfigurator: false,
  // direction: 'ltr',
  // layout: 'dashboard',
  darkMode: false,
};

export const ThemeContext = createContext<ThemeContextType>(initialState);

ThemeContext.displayName = "QUIContext";

const themeReducer = (state, action) => {
  switch (action.type) {
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    // case 'DIRECTION': {
    //     return { ...state, direction: action.value };
    // }
    // case 'LAYOUT': {
    //     return { ...state, layout: action.value };
    // }
    default:
      return state;
  }
};

// QUI Dashboard React context provider
function ThemeProvider({ children }) {
  const [controller, dispatch] = useReducer(themeReducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return (
  // @ts-ignore
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}

function useController() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error(
      "useQUIController should be used inside the QUIControllerProvider."
    );
  }

  return context;
}

// Context module functions
const setMiniSidenav = (dispatch, value) =>
  dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setSidenavColor = (dispatch, value) =>
  dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) =>
  dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) =>
  dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) =>
  dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
  ThemeProvider,
  useController,
  setMiniSidenav,
  setTransparentNavbar,
  setTransparentSidenav,
  setSidenavColor,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
};
