import { lazy } from 'react';

// project imports
import UserLayout from 'layout/UserLayout';
import CustomLoadable from 'ui-component/Loadable';
// import { CircularProgress } from "@mui/material";

import { dashboardPaths } from "navigation/paths";

// Services routing 
import ServicesRoutes from './Services';

// // orders routing
const Orders = CustomLoadable(lazy(() => import('views/pages/dashboard/purchases/orders')));
const OrdersCrud = CustomLoadable(lazy(() => import('views/pages/dashboard/purchases/orders/crud')));
const BuyAgain = CustomLoadable(lazy(() => import('views/pages/dashboard/purchases/buy-again')));
const MyProducts = CustomLoadable(lazy(() => import('views/pages/dashboard/purchases/my-products')));
const InProcess = CustomLoadable(lazy(() => import('views/pages/dashboard/purchases/in-process')));
const CancelledOrders = CustomLoadable(lazy(() => import('views/pages/dashboard/purchases/cancelled-order')));
const Subscriptions = CustomLoadable(lazy(() => import('views/pages/dashboard/purchases/subscriptions')));
const Referrals = CustomLoadable(lazy(() => import('views/pages/dashboard/purchases/referrals')));

// // utilities routing
const UtilsPayments = CustomLoadable(lazy(() => import('views/pages/dashboard/utilities/Payments')));
const UtilsSettings = CustomLoadable(lazy(() => import('views/pages/dashboard/utilities/Settings')));


// ==============================|| MAIN ROUTING ||============================== //

const DashboardRoutes = {
    path: '/',
    element: <UserLayout />,
    children: [
        {
            path: dashboardPaths.OrdersPage,
            element: <Orders />
        },
        {
            path: dashboardPaths.AddOrdersPage,
            element: <OrdersCrud />
        },
        {
            path: dashboardPaths.BuyAgainPage,
            element: <BuyAgain />
        },
        {
            path: dashboardPaths.MyProductsPage,
            element: <MyProducts />
        },
        {
            path: dashboardPaths.InProcessPage,
            element: <InProcess />
        },
        {
            path: dashboardPaths.CancelledOrdersPage,
            element: <CancelledOrders />
        },
        {
            path: dashboardPaths.SubscriptionsPage,
            element: <Subscriptions />
        },
        {
            path: dashboardPaths.ReferralsPage,
            element: <Referrals />
        },
        // {
        //     path: dashboardPaths.PaymentsPage,
        //     element: <UtilsPayments />
        // },
        {
            path: dashboardPaths.SettingsPage,
            element: <UtilsSettings />
        },
        ...ServicesRoutes
    ]
};

export default DashboardRoutes;
