import { isLocal } from "utils/environment";


interface FeatureMap {
    [name: string]: boolean;
}

// const parseFeatures = (): FeatureMap => {
//     if (!document) {
//         throw new Error("Fac is supposed to be used in browser environment");
//     }

//     const featureMetaTag = document.getElementById(FAC_ELEMENT_ID);
//     if (!featureMetaTag) {
//         throw new Error('Features meta tag is not present');
//     }

//     const featureMetaTagAttributes = featureMetaTag.getAttribute(FAC_ATTRIBUTE);
//     if (!featureMetaTagAttributes) {
//         throw new Error("Features meta tag attribute is missing");
//     }

//     try {
//         const features: FeatureMap = {};
//         const featuresJson = JSON.parse(featureMetaTagAttributes);

//         for (const i in featuresJson) {
//             const feature = featuresJson[i];
            
//             if (feature && feature.externalId && feature.enabled !== undefined) {
//                 features[feature.externalId] = feature.enabled;
//             }
//         }

//         return features;
//     } catch (e) {
//         throw new Error("Failed to parse FAC");
//     }
// }


class FAC {
    private static instance: FAC;
    private readonly features: FeatureMap = {};

    private constructor() {
        this.features = isLocal() ? {} : {}// parseFeatures()
    }

    static getInstance = (): FAC => {
        if (!FAC.instance) {
            FAC.instance = new FAC();
        }

        return FAC.instance;
    }

    isEnabled = (featureName: string) => isLocal() ? true : this.features[featureName] || false;
}

export default FAC.getInstance();