import toast from 'react-hot-toast';
import { ErrorCases, alertConstants } from '../constants';

const success = (response) => ({
    type: alertConstants.SUCCESS,
    payload: response
});

const error = (err) => ({
    type: alertConstants.ERROR,
    payload: err
});

const clear = () => ({
    type: alertConstants.CLEAR
});

const onError = (e) => {
    if (e.name) {
        if (e.name === ErrorCases.LimitExceededException) {
            toast.error(e.message);
        }
    }
}

export const alertActions = {
    success,
    error,
    clear
};

export const alertToast = {
    onError
}