import { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";

// mui
import { useTheme } from "@mui/material/styles";
import { Box, Button, Grid } from "@mui/material";
import CartProduct from "./CartProduct";

// AWS
import { AmplifyAPI } from "app/AmplifyConfigs";
import { fetchCheckoutURL } from "graphql/queries";

// project imports
import AnimateButton from "ui-component/extended/AnimateButton";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

// shopping cart imports
import {
  useShoppingCart,
} from "use-shopping-cart";
import { NoResultsCard } from "ui-component/cards/NoResults";

// ==============================|| PROFILE MENU - CART CARD ||============================== //

const CartCard = (props) => {
  const theme = useTheme();
  const cart = useShoppingCart();
  const {
    removeItem,
    cartDetails,
    formattedTotalPrice,
    incrementItem,
    decrementItem,
  } = cart;

  const handleCheckout = async (e) => {
    e.preventDefault();
    // console.log("checking out");
    // console.log(cartDetails);
    const { data } = await AmplifyAPI.graphql({
      query: fetchCheckoutURL,
      authMode: "iam",
      variables: {
        input: JSON.stringify(cartDetails),
      },
    }).catch((err) => console.log("the returned error", err));
    console.log(data);
    const { sessionId } = JSON.parse(data.fetchCheckoutURL);

    window.location.href = sessionId
  };

  const cartEntries = Object.values(cartDetails ?? {}).map((entry) => (
    <CartProduct
      entry={entry}
      key={entry.id}
      removeItem={removeItem}
      incrementItem={incrementItem}
      decrementItem={decrementItem}
    />
  ));

  return (
    <>
      {cartEntries.length === 0 && (
        <Grid>
          <NoResultsCard 
            description="Add some products in the cart"
          />
        </Grid>
      )}
      <PerfectScrollbar
        style={{
          maxHeight: "50vh",
          overflowX: "hidden",
          overflowY: "scroll",
        }}
      >
        {cartEntries.length > 0 && (
          <>
            <Box
              sx={{
                overflowX: "hidden",
                overflowY: "scroll",
              }}
            >
              {cartEntries}
            </Box>
            <AnimateButton>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  mb: 2,
                  backgroundColor: theme.palette.orange.dark,
                  "&:hover": {
                    backgroundColor: theme.palette.orange.main,
                  },
                }}
                onClick={handleCheckout}
              >
                Checkout: {formattedTotalPrice}
              </Button>
            </AnimateButton>
          </>
        )}
      </PerfectScrollbar>
    </>
  );
};

export default CartCard;
