import React, { useContext, forwardRef } from "react";

// mui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";

// project import
import { ThemeContext } from "ThemeContext";

// ==============================|| CUSTOM SUB CARD ||============================== //

interface SubCardProps {
  children: React.ReactNode;
  content?: boolean;
  contentClass?: string;
  darkTitle?: boolean;
  secondary?: React.ReactNode;
  sx?: React.CSSProperties;
  contentSX?: React.CSSProperties;
  title: string;
  subtitle: string;
}

const SubCard = forwardRef(
  (
    {
      children,
      content = true,
      contentClass,
      darkTitle,
      secondary,
      sx = {},
      contentSX = {},
      title,
      subtitle,
      ...others
    }: SubCardProps,
    ref
  ) => {
    const theme = useTheme();
    const context = useContext(ThemeContext);
    const darkMode = context.darkMode;

    return (
      // @ts-ignore
      <Card
        ref={ref}
        sx={{
          border: "1px solid",
          borderColor: darkMode
            ? theme.palette.secondary.light
            : theme.palette.primary.light,
          ":hover": {
            boxShadow: "0 2px 14px 0 rgb(32 40 45 / 8%)",
          },
          backgroundColor: darkMode
            ? theme.palette.secondary.main
            : theme.palette.background.default,
          ...sx,
        }}
        {...others}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader
            sx={{ p: 2.5 }}
            title={
              <Typography
                variant="h5"
                sx={{
                  color: darkMode
                    ? theme.palette.secondary.light
                    : theme.palette.text.primary,
                }}
              >
                {title}
              </Typography>
            }
            action={secondary}
            subheader={
              subtitle ? (
                <Typography variant="subtitle2">{subtitle}</Typography>
              ) : null
            }
          />
        )}
        {darkTitle && title && (
          <CardHeader
            sx={{ p: 2.5 }}
            title={
              <Typography
                variant="h4"
                sx={{
                  color: darkMode
                    ? theme.palette.secondary.light
                    : theme.palette.text.primary,
                }}
              >
                {title}
              </Typography>
            }
            action={secondary}
            subheader={
              subtitle ? (
                <Typography variant="subtitle1">{title}</Typography>
              ) : null
            }
          />
        )}

        {/* content & header divider */}
        {title && (
          <Divider
            sx={{
              opacity: 1,
              borderColor: darkMode
                ? theme.palette.secondary.light
                : theme.palette.primary.light
            }}
          />
        )}

        {/* card content */}
        {content && (
          <CardContent
            sx={{ p: 2.5, ...contentSX }}
            className={contentClass || ""}
          >
            {children}
          </CardContent>
        )}

        {!content && children}
      </Card>
    );
  }
);

export default SubCard;
