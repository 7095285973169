/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createProduct = /* GraphQL */ `mutation CreateProduct(
  $input: CreateProductInput!
  $condition: ModelProductConditionInput
) {
  createProduct(input: $input, condition: $condition) {
    id
    title
    description
    brand
    category
    sub_category
    model_no
    product_code
    price
    quantity
    specifications {
      title
      specification
      __typename
    }
    currency
    images
    in_the_box {
      productID
      quantity
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductMutationVariables,
  APITypes.CreateProductMutation
>;
export const updateProduct = /* GraphQL */ `mutation UpdateProduct(
  $input: UpdateProductInput!
  $condition: ModelProductConditionInput
) {
  updateProduct(input: $input, condition: $condition) {
    id
    title
    description
    brand
    category
    sub_category
    model_no
    product_code
    price
    quantity
    specifications {
      title
      specification
      __typename
    }
    currency
    images
    in_the_box {
      productID
      quantity
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductMutationVariables,
  APITypes.UpdateProductMutation
>;
export const deleteProduct = /* GraphQL */ `mutation DeleteProduct(
  $input: DeleteProductInput!
  $condition: ModelProductConditionInput
) {
  deleteProduct(input: $input, condition: $condition) {
    id
    title
    description
    brand
    category
    sub_category
    model_no
    product_code
    price
    quantity
    specifications {
      title
      specification
      __typename
    }
    currency
    images
    in_the_box {
      productID
      quantity
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductMutationVariables,
  APITypes.DeleteProductMutation
>;
export const createProductReview = /* GraphQL */ `mutation CreateProductReview(
  $input: CreateProductReviewInput!
  $condition: ModelProductReviewConditionInput
) {
  createProductReview(input: $input, condition: $condition) {
    id
    productID
    header
    description
    purchased_area
    locationID
    rating
    external_votes {
      upvote
      downvote
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductReviewMutationVariables,
  APITypes.CreateProductReviewMutation
>;
export const updateProductReview = /* GraphQL */ `mutation UpdateProductReview(
  $input: UpdateProductReviewInput!
  $condition: ModelProductReviewConditionInput
) {
  updateProductReview(input: $input, condition: $condition) {
    id
    productID
    header
    description
    purchased_area
    locationID
    rating
    external_votes {
      upvote
      downvote
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductReviewMutationVariables,
  APITypes.UpdateProductReviewMutation
>;
export const deleteProductReview = /* GraphQL */ `mutation DeleteProductReview(
  $input: DeleteProductReviewInput!
  $condition: ModelProductReviewConditionInput
) {
  deleteProductReview(input: $input, condition: $condition) {
    id
    productID
    header
    description
    purchased_area
    locationID
    rating
    external_votes {
      upvote
      downvote
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductReviewMutationVariables,
  APITypes.DeleteProductReviewMutation
>;
export const createProductCollection = /* GraphQL */ `mutation CreateProductCollection(
  $input: CreateProductCollectionInput!
  $condition: ModelProductCollectionConditionInput
) {
  createProductCollection(input: $input, condition: $condition) {
    id
    title
    description
    product_code
    condition_type
    collection
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductCollectionMutationVariables,
  APITypes.CreateProductCollectionMutation
>;
export const updateProductCollection = /* GraphQL */ `mutation UpdateProductCollection(
  $input: UpdateProductCollectionInput!
  $condition: ModelProductCollectionConditionInput
) {
  updateProductCollection(input: $input, condition: $condition) {
    id
    title
    description
    product_code
    condition_type
    collection
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductCollectionMutationVariables,
  APITypes.UpdateProductCollectionMutation
>;
export const deleteProductCollection = /* GraphQL */ `mutation DeleteProductCollection(
  $input: DeleteProductCollectionInput!
  $condition: ModelProductCollectionConditionInput
) {
  deleteProductCollection(input: $input, condition: $condition) {
    id
    title
    description
    product_code
    condition_type
    collection
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductCollectionMutationVariables,
  APITypes.DeleteProductCollectionMutation
>;
export const createProductBundle = /* GraphQL */ `mutation CreateProductBundle(
  $input: CreateProductBundleInput!
  $condition: ModelProductBundleConditionInput
) {
  createProductBundle(input: $input, condition: $condition) {
    id
    title
    sub_title
    description
    product_code
    products {
      product_id
      quantity
      __typename
    }
    price
    usage {
      description
      __typename
    }
    currency
    images
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductBundleMutationVariables,
  APITypes.CreateProductBundleMutation
>;
export const updateProductBundle = /* GraphQL */ `mutation UpdateProductBundle(
  $input: UpdateProductBundleInput!
  $condition: ModelProductBundleConditionInput
) {
  updateProductBundle(input: $input, condition: $condition) {
    id
    title
    sub_title
    description
    product_code
    products {
      product_id
      quantity
      __typename
    }
    price
    usage {
      description
      __typename
    }
    currency
    images
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductBundleMutationVariables,
  APITypes.UpdateProductBundleMutation
>;
export const deleteProductBundle = /* GraphQL */ `mutation DeleteProductBundle(
  $input: DeleteProductBundleInput!
  $condition: ModelProductBundleConditionInput
) {
  deleteProductBundle(input: $input, condition: $condition) {
    id
    title
    sub_title
    description
    product_code
    products {
      product_id
      quantity
      __typename
    }
    price
    usage {
      description
      __typename
    }
    currency
    images
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductBundleMutationVariables,
  APITypes.DeleteProductBundleMutation
>;
export const createJob = /* GraphQL */ `mutation CreateJob(
  $input: CreateJobInput!
  $condition: ModelJobConditionInput
) {
  createJob(input: $input, condition: $condition) {
    id
    title
    description
    responsibilities {
      description
      __typename
    }
    qualifications {
      description
      __typename
    }
    benefits {
      description
      __typename
    }
    type
    category
    location_type
    country
    state
    city
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJobMutationVariables,
  APITypes.CreateJobMutation
>;
export const updateJob = /* GraphQL */ `mutation UpdateJob(
  $input: UpdateJobInput!
  $condition: ModelJobConditionInput
) {
  updateJob(input: $input, condition: $condition) {
    id
    title
    description
    responsibilities {
      description
      __typename
    }
    qualifications {
      description
      __typename
    }
    benefits {
      description
      __typename
    }
    type
    category
    location_type
    country
    state
    city
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobMutationVariables,
  APITypes.UpdateJobMutation
>;
export const deleteJob = /* GraphQL */ `mutation DeleteJob(
  $input: DeleteJobInput!
  $condition: ModelJobConditionInput
) {
  deleteJob(input: $input, condition: $condition) {
    id
    title
    description
    responsibilities {
      description
      __typename
    }
    qualifications {
      description
      __typename
    }
    benefits {
      description
      __typename
    }
    type
    category
    location_type
    country
    state
    city
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJobMutationVariables,
  APITypes.DeleteJobMutation
>;
export const createNewsletterCandidate = /* GraphQL */ `mutation CreateNewsletterCandidate(
  $input: CreateNewsletterCandidateInput!
  $condition: ModelNewsletterCandidateConditionInput
) {
  createNewsletterCandidate(input: $input, condition: $condition) {
    id
    email
    active
    reason
    types {
      latest_updates
      tips_and_tutorials
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNewsletterCandidateMutationVariables,
  APITypes.CreateNewsletterCandidateMutation
>;
export const updateNewsletterCandidate = /* GraphQL */ `mutation UpdateNewsletterCandidate(
  $input: UpdateNewsletterCandidateInput!
  $condition: ModelNewsletterCandidateConditionInput
) {
  updateNewsletterCandidate(input: $input, condition: $condition) {
    id
    email
    active
    reason
    types {
      latest_updates
      tips_and_tutorials
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNewsletterCandidateMutationVariables,
  APITypes.UpdateNewsletterCandidateMutation
>;
export const deleteNewsletterCandidate = /* GraphQL */ `mutation DeleteNewsletterCandidate(
  $input: DeleteNewsletterCandidateInput!
  $condition: ModelNewsletterCandidateConditionInput
) {
  deleteNewsletterCandidate(input: $input, condition: $condition) {
    id
    email
    active
    reason
    types {
      latest_updates
      tips_and_tutorials
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNewsletterCandidateMutationVariables,
  APITypes.DeleteNewsletterCandidateMutation
>;
export const createMarketingCandidate = /* GraphQL */ `mutation CreateMarketingCandidate(
  $input: CreateMarketingCandidateInput!
  $condition: ModelMarketingCandidateConditionInput
) {
  createMarketingCandidate(input: $input, condition: $condition) {
    id
    user_id
    active
    reason
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMarketingCandidateMutationVariables,
  APITypes.CreateMarketingCandidateMutation
>;
export const updateMarketingCandidate = /* GraphQL */ `mutation UpdateMarketingCandidate(
  $input: UpdateMarketingCandidateInput!
  $condition: ModelMarketingCandidateConditionInput
) {
  updateMarketingCandidate(input: $input, condition: $condition) {
    id
    user_id
    active
    reason
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMarketingCandidateMutationVariables,
  APITypes.UpdateMarketingCandidateMutation
>;
export const deleteMarketingCandidate = /* GraphQL */ `mutation DeleteMarketingCandidate(
  $input: DeleteMarketingCandidateInput!
  $condition: ModelMarketingCandidateConditionInput
) {
  deleteMarketingCandidate(input: $input, condition: $condition) {
    id
    user_id
    active
    reason
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMarketingCandidateMutationVariables,
  APITypes.DeleteMarketingCandidateMutation
>;
export const createRetailer = /* GraphQL */ `mutation CreateRetailer(
  $input: CreateRetailerInput!
  $condition: ModelRetailerConditionInput
) {
  createRetailer(input: $input, condition: $condition) {
    id
    name
    address {
      addr1
      addr2
      postal
      city
      state
      country
      map_location
      contact_num
      opening_time
      opening_days
      email
      __typename
    }
    email
    contact_num
    user_ids
    website
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRetailerMutationVariables,
  APITypes.CreateRetailerMutation
>;
export const updateRetailer = /* GraphQL */ `mutation UpdateRetailer(
  $input: UpdateRetailerInput!
  $condition: ModelRetailerConditionInput
) {
  updateRetailer(input: $input, condition: $condition) {
    id
    name
    address {
      addr1
      addr2
      postal
      city
      state
      country
      map_location
      contact_num
      opening_time
      opening_days
      email
      __typename
    }
    email
    contact_num
    user_ids
    website
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRetailerMutationVariables,
  APITypes.UpdateRetailerMutation
>;
export const deleteRetailer = /* GraphQL */ `mutation DeleteRetailer(
  $input: DeleteRetailerInput!
  $condition: ModelRetailerConditionInput
) {
  deleteRetailer(input: $input, condition: $condition) {
    id
    name
    address {
      addr1
      addr2
      postal
      city
      state
      country
      map_location
      contact_num
      opening_time
      opening_days
      email
      __typename
    }
    email
    contact_num
    user_ids
    website
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRetailerMutationVariables,
  APITypes.DeleteRetailerMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    customer_id
    username
    email
    family_name
    given_name
    type
    password
    location
    contact_num
    country
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    customer_id
    username
    email
    family_name
    given_name
    type
    password
    location
    contact_num
    country
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    customer_id
    username
    email
    family_name
    given_name
    type
    password
    location
    contact_num
    country
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createEmployee = /* GraphQL */ `mutation CreateEmployee(
  $input: CreateEmployeeInput!
  $condition: ModelEmployeeConditionInput
) {
  createEmployee(input: $input, condition: $condition) {
    employee_id
    username
    email
    family_name
    given_name
    type
    password
    location
    contact_num
    country
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmployeeMutationVariables,
  APITypes.CreateEmployeeMutation
>;
export const updateEmployee = /* GraphQL */ `mutation UpdateEmployee(
  $input: UpdateEmployeeInput!
  $condition: ModelEmployeeConditionInput
) {
  updateEmployee(input: $input, condition: $condition) {
    employee_id
    username
    email
    family_name
    given_name
    type
    password
    location
    contact_num
    country
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmployeeMutationVariables,
  APITypes.UpdateEmployeeMutation
>;
export const deleteEmployee = /* GraphQL */ `mutation DeleteEmployee(
  $input: DeleteEmployeeInput!
  $condition: ModelEmployeeConditionInput
) {
  deleteEmployee(input: $input, condition: $condition) {
    employee_id
    username
    email
    family_name
    given_name
    type
    password
    location
    contact_num
    country
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmployeeMutationVariables,
  APITypes.DeleteEmployeeMutation
>;
export const createCustomer = /* GraphQL */ `mutation CreateCustomer(
  $input: CreateCustomerInput!
  $condition: ModelCustomerConditionInput
) {
  createCustomer(input: $input, condition: $condition) {
    id
    type
    name
    phone
    country
    email
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomerMutationVariables,
  APITypes.CreateCustomerMutation
>;
export const updateCustomer = /* GraphQL */ `mutation UpdateCustomer(
  $input: UpdateCustomerInput!
  $condition: ModelCustomerConditionInput
) {
  updateCustomer(input: $input, condition: $condition) {
    id
    type
    name
    phone
    country
    email
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerMutationVariables,
  APITypes.UpdateCustomerMutation
>;
export const deleteCustomer = /* GraphQL */ `mutation DeleteCustomer(
  $input: DeleteCustomerInput!
  $condition: ModelCustomerConditionInput
) {
  deleteCustomer(input: $input, condition: $condition) {
    id
    type
    name
    phone
    country
    email
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomerMutationVariables,
  APITypes.DeleteCustomerMutation
>;
export const createCoupon = /* GraphQL */ `mutation CreateCoupon(
  $input: CreateCouponInput!
  $condition: ModelCouponConditionInput
) {
  createCoupon(input: $input, condition: $condition) {
    owner
    coupon_code
    promotion_type
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCouponMutationVariables,
  APITypes.CreateCouponMutation
>;
export const updateCoupon = /* GraphQL */ `mutation UpdateCoupon(
  $input: UpdateCouponInput!
  $condition: ModelCouponConditionInput
) {
  updateCoupon(input: $input, condition: $condition) {
    owner
    coupon_code
    promotion_type
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCouponMutationVariables,
  APITypes.UpdateCouponMutation
>;
export const deleteCoupon = /* GraphQL */ `mutation DeleteCoupon(
  $input: DeleteCouponInput!
  $condition: ModelCouponConditionInput
) {
  deleteCoupon(input: $input, condition: $condition) {
    owner
    coupon_code
    promotion_type
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCouponMutationVariables,
  APITypes.DeleteCouponMutation
>;
export const createPromotion = /* GraphQL */ `mutation CreatePromotion(
  $input: CreatePromotionInput!
  $condition: ModelPromotionConditionInput
) {
  createPromotion(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePromotionMutationVariables,
  APITypes.CreatePromotionMutation
>;
export const updatePromotion = /* GraphQL */ `mutation UpdatePromotion(
  $input: UpdatePromotionInput!
  $condition: ModelPromotionConditionInput
) {
  updatePromotion(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePromotionMutationVariables,
  APITypes.UpdatePromotionMutation
>;
export const deletePromotion = /* GraphQL */ `mutation DeletePromotion(
  $input: DeletePromotionInput!
  $condition: ModelPromotionConditionInput
) {
  deletePromotion(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePromotionMutationVariables,
  APITypes.DeletePromotionMutation
>;
export const createBrowsingHistory = /* GraphQL */ `mutation CreateBrowsingHistory(
  $input: CreateBrowsingHistoryInput!
  $condition: ModelBrowsingHistoryConditionInput
) {
  createBrowsingHistory(input: $input, condition: $condition) {
    id
    customer_id
    viewed {
      product_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBrowsingHistoryMutationVariables,
  APITypes.CreateBrowsingHistoryMutation
>;
export const updateBrowsingHistory = /* GraphQL */ `mutation UpdateBrowsingHistory(
  $input: UpdateBrowsingHistoryInput!
  $condition: ModelBrowsingHistoryConditionInput
) {
  updateBrowsingHistory(input: $input, condition: $condition) {
    id
    customer_id
    viewed {
      product_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBrowsingHistoryMutationVariables,
  APITypes.UpdateBrowsingHistoryMutation
>;
export const deleteBrowsingHistory = /* GraphQL */ `mutation DeleteBrowsingHistory(
  $input: DeleteBrowsingHistoryInput!
  $condition: ModelBrowsingHistoryConditionInput
) {
  deleteBrowsingHistory(input: $input, condition: $condition) {
    id
    customer_id
    viewed {
      product_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBrowsingHistoryMutationVariables,
  APITypes.DeleteBrowsingHistoryMutation
>;
export const createSearchHistory = /* GraphQL */ `mutation CreateSearchHistory(
  $input: CreateSearchHistoryInput!
  $condition: ModelSearchHistoryConditionInput
) {
  createSearchHistory(input: $input, condition: $condition) {
    id
    customer_id
    viewed {
      product_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSearchHistoryMutationVariables,
  APITypes.CreateSearchHistoryMutation
>;
export const updateSearchHistory = /* GraphQL */ `mutation UpdateSearchHistory(
  $input: UpdateSearchHistoryInput!
  $condition: ModelSearchHistoryConditionInput
) {
  updateSearchHistory(input: $input, condition: $condition) {
    id
    customer_id
    viewed {
      product_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSearchHistoryMutationVariables,
  APITypes.UpdateSearchHistoryMutation
>;
export const deleteSearchHistory = /* GraphQL */ `mutation DeleteSearchHistory(
  $input: DeleteSearchHistoryInput!
  $condition: ModelSearchHistoryConditionInput
) {
  deleteSearchHistory(input: $input, condition: $condition) {
    id
    customer_id
    viewed {
      product_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSearchHistoryMutationVariables,
  APITypes.DeleteSearchHistoryMutation
>;
export const createFavoriteItems = /* GraphQL */ `mutation CreateFavoriteItems(
  $input: CreateFavoriteItemsInput!
  $condition: ModelFavoriteItemsConditionInput
) {
  createFavoriteItems(input: $input, condition: $condition) {
    id
    customer_id
    favorite {
      product_id
      createdAt
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFavoriteItemsMutationVariables,
  APITypes.CreateFavoriteItemsMutation
>;
export const updateFavoriteItems = /* GraphQL */ `mutation UpdateFavoriteItems(
  $input: UpdateFavoriteItemsInput!
  $condition: ModelFavoriteItemsConditionInput
) {
  updateFavoriteItems(input: $input, condition: $condition) {
    id
    customer_id
    favorite {
      product_id
      createdAt
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFavoriteItemsMutationVariables,
  APITypes.UpdateFavoriteItemsMutation
>;
export const deleteFavoriteItems = /* GraphQL */ `mutation DeleteFavoriteItems(
  $input: DeleteFavoriteItemsInput!
  $condition: ModelFavoriteItemsConditionInput
) {
  deleteFavoriteItems(input: $input, condition: $condition) {
    id
    customer_id
    favorite {
      product_id
      createdAt
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFavoriteItemsMutationVariables,
  APITypes.DeleteFavoriteItemsMutation
>;
export const createCart = /* GraphQL */ `mutation CreateCart(
  $input: CreateCartInput!
  $condition: ModelCartConditionInput
) {
  createCart(input: $input, condition: $condition) {
    id
    products {
      product_id
      quantity
      current_price
      last_seen_price
      __typename
    }
    status
    user_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCartMutationVariables,
  APITypes.CreateCartMutation
>;
export const updateCart = /* GraphQL */ `mutation UpdateCart(
  $input: UpdateCartInput!
  $condition: ModelCartConditionInput
) {
  updateCart(input: $input, condition: $condition) {
    id
    products {
      product_id
      quantity
      current_price
      last_seen_price
      __typename
    }
    status
    user_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCartMutationVariables,
  APITypes.UpdateCartMutation
>;
export const deleteCart = /* GraphQL */ `mutation DeleteCart(
  $input: DeleteCartInput!
  $condition: ModelCartConditionInput
) {
  deleteCart(input: $input, condition: $condition) {
    id
    products {
      product_id
      quantity
      current_price
      last_seen_price
      __typename
    }
    status
    user_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCartMutationVariables,
  APITypes.DeleteCartMutation
>;
export const createOrder = /* GraphQL */ `mutation CreateOrder(
  $input: CreateOrderInput!
  $condition: ModelOrderConditionInput
) {
  createOrder(input: $input, condition: $condition) {
    id
    cart_id
    paid
    payment_mode
    manual
    date
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderMutationVariables,
  APITypes.CreateOrderMutation
>;
export const updateOrder = /* GraphQL */ `mutation UpdateOrder(
  $input: UpdateOrderInput!
  $condition: ModelOrderConditionInput
) {
  updateOrder(input: $input, condition: $condition) {
    id
    cart_id
    paid
    payment_mode
    manual
    date
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderMutationVariables,
  APITypes.UpdateOrderMutation
>;
export const deleteOrder = /* GraphQL */ `mutation DeleteOrder(
  $input: DeleteOrderInput!
  $condition: ModelOrderConditionInput
) {
  deleteOrder(input: $input, condition: $condition) {
    id
    cart_id
    paid
    payment_mode
    manual
    date
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderMutationVariables,
  APITypes.DeleteOrderMutation
>;
export const createInvoice = /* GraphQL */ `mutation CreateInvoice(
  $input: CreateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  createInvoice(input: $input, condition: $condition) {
    id
    user_id
    date
    expire_date
    total
    paid
    status
    created_by
    items {
      product_id
      description
      quantity
      price
      __typename
    }
    note
    currency
    payment_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvoiceMutationVariables,
  APITypes.CreateInvoiceMutation
>;
export const updateInvoice = /* GraphQL */ `mutation UpdateInvoice(
  $input: UpdateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  updateInvoice(input: $input, condition: $condition) {
    id
    user_id
    date
    expire_date
    total
    paid
    status
    created_by
    items {
      product_id
      description
      quantity
      price
      __typename
    }
    note
    currency
    payment_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvoiceMutationVariables,
  APITypes.UpdateInvoiceMutation
>;
export const deleteInvoice = /* GraphQL */ `mutation DeleteInvoice(
  $input: DeleteInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  deleteInvoice(input: $input, condition: $condition) {
    id
    user_id
    date
    expire_date
    total
    paid
    status
    created_by
    items {
      product_id
      description
      quantity
      price
      __typename
    }
    note
    currency
    payment_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvoiceMutationVariables,
  APITypes.DeleteInvoiceMutation
>;
export const createProformaInvoice = /* GraphQL */ `mutation CreateProformaInvoice(
  $input: CreateProformaInvoiceInput!
  $condition: ModelProformaInvoiceConditionInput
) {
  createProformaInvoice(input: $input, condition: $condition) {
    id
    user_id
    date
    expire_date
    total
    paid
    status
    created_by
    items {
      product_id
      description
      quantity
      price
      __typename
    }
    note
    currency
    payment_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProformaInvoiceMutationVariables,
  APITypes.CreateProformaInvoiceMutation
>;
export const updateProformaInvoice = /* GraphQL */ `mutation UpdateProformaInvoice(
  $input: UpdateProformaInvoiceInput!
  $condition: ModelProformaInvoiceConditionInput
) {
  updateProformaInvoice(input: $input, condition: $condition) {
    id
    user_id
    date
    expire_date
    total
    paid
    status
    created_by
    items {
      product_id
      description
      quantity
      price
      __typename
    }
    note
    currency
    payment_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProformaInvoiceMutationVariables,
  APITypes.UpdateProformaInvoiceMutation
>;
export const deleteProformaInvoice = /* GraphQL */ `mutation DeleteProformaInvoice(
  $input: DeleteProformaInvoiceInput!
  $condition: ModelProformaInvoiceConditionInput
) {
  deleteProformaInvoice(input: $input, condition: $condition) {
    id
    user_id
    date
    expire_date
    total
    paid
    status
    created_by
    items {
      product_id
      description
      quantity
      price
      __typename
    }
    note
    currency
    payment_ids
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProformaInvoiceMutationVariables,
  APITypes.DeleteProformaInvoiceMutation
>;
export const createPayment = /* GraphQL */ `mutation CreatePayment(
  $input: CreatePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  createPayment(input: $input, condition: $condition) {
    customer_id
    payment_mode
    invoice_id
    amount
    reference
    description
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentMutationVariables,
  APITypes.CreatePaymentMutation
>;
export const updatePayment = /* GraphQL */ `mutation UpdatePayment(
  $input: UpdatePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  updatePayment(input: $input, condition: $condition) {
    customer_id
    payment_mode
    invoice_id
    amount
    reference
    description
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentMutationVariables,
  APITypes.UpdatePaymentMutation
>;
export const deletePayment = /* GraphQL */ `mutation DeletePayment(
  $input: DeletePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  deletePayment(input: $input, condition: $condition) {
    customer_id
    payment_mode
    invoice_id
    amount
    reference
    description
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentMutationVariables,
  APITypes.DeletePaymentMutation
>;
export const createProductRepair = /* GraphQL */ `mutation CreateProductRepair(
  $input: CreateProductRepairInput!
  $condition: ModelProductRepairConditionInput
) {
  createProductRepair(input: $input, condition: $condition) {
    product_id
    tracking_id
    stage
    price
    under_warranty
    product_user {
      productId
      unitPrice
      quantity
      UOM
      subTotal
      __typename
    }
    discounted_price
    misc
    labourCharges
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductRepairMutationVariables,
  APITypes.CreateProductRepairMutation
>;
export const updateProductRepair = /* GraphQL */ `mutation UpdateProductRepair(
  $input: UpdateProductRepairInput!
  $condition: ModelProductRepairConditionInput
) {
  updateProductRepair(input: $input, condition: $condition) {
    product_id
    tracking_id
    stage
    price
    under_warranty
    product_user {
      productId
      unitPrice
      quantity
      UOM
      subTotal
      __typename
    }
    discounted_price
    misc
    labourCharges
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductRepairMutationVariables,
  APITypes.UpdateProductRepairMutation
>;
export const deleteProductRepair = /* GraphQL */ `mutation DeleteProductRepair(
  $input: DeleteProductRepairInput!
  $condition: ModelProductRepairConditionInput
) {
  deleteProductRepair(input: $input, condition: $condition) {
    product_id
    tracking_id
    stage
    price
    under_warranty
    product_user {
      productId
      unitPrice
      quantity
      UOM
      subTotal
      __typename
    }
    discounted_price
    misc
    labourCharges
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductRepairMutationVariables,
  APITypes.DeleteProductRepairMutation
>;
export const createWarranty = /* GraphQL */ `mutation CreateWarranty(
  $input: CreateWarrantyInput!
  $condition: ModelWarrantyConditionInput
) {
  createWarranty(input: $input, condition: $condition) {
    product_id
    current_price
    user_id
    model_no
    product_code
    retailer_name
    receipts
    status
    reason
    extension_ids
    order_id
    verification_user_id
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWarrantyMutationVariables,
  APITypes.CreateWarrantyMutation
>;
export const updateWarranty = /* GraphQL */ `mutation UpdateWarranty(
  $input: UpdateWarrantyInput!
  $condition: ModelWarrantyConditionInput
) {
  updateWarranty(input: $input, condition: $condition) {
    product_id
    current_price
    user_id
    model_no
    product_code
    retailer_name
    receipts
    status
    reason
    extension_ids
    order_id
    verification_user_id
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWarrantyMutationVariables,
  APITypes.UpdateWarrantyMutation
>;
export const deleteWarranty = /* GraphQL */ `mutation DeleteWarranty(
  $input: DeleteWarrantyInput!
  $condition: ModelWarrantyConditionInput
) {
  deleteWarranty(input: $input, condition: $condition) {
    product_id
    current_price
    user_id
    model_no
    product_code
    retailer_name
    receipts
    status
    reason
    extension_ids
    order_id
    verification_user_id
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWarrantyMutationVariables,
  APITypes.DeleteWarrantyMutation
>;
export const createWarrantyExtension = /* GraphQL */ `mutation CreateWarrantyExtension(
  $input: CreateWarrantyExtensionInput!
  $condition: ModelWarrantyExtensionConditionInput
) {
  createWarrantyExtension(input: $input, condition: $condition) {
    thru_date
    approval_user_id
    approval_reasons
    amount_paid
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWarrantyExtensionMutationVariables,
  APITypes.CreateWarrantyExtensionMutation
>;
export const updateWarrantyExtension = /* GraphQL */ `mutation UpdateWarrantyExtension(
  $input: UpdateWarrantyExtensionInput!
  $condition: ModelWarrantyExtensionConditionInput
) {
  updateWarrantyExtension(input: $input, condition: $condition) {
    thru_date
    approval_user_id
    approval_reasons
    amount_paid
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWarrantyExtensionMutationVariables,
  APITypes.UpdateWarrantyExtensionMutation
>;
export const deleteWarrantyExtension = /* GraphQL */ `mutation DeleteWarrantyExtension(
  $input: DeleteWarrantyExtensionInput!
  $condition: ModelWarrantyExtensionConditionInput
) {
  deleteWarrantyExtension(input: $input, condition: $condition) {
    thru_date
    approval_user_id
    approval_reasons
    amount_paid
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWarrantyExtensionMutationVariables,
  APITypes.DeleteWarrantyExtensionMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $input: CreateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  createNotification(input: $input, condition: $condition) {
    email_latest_update
    email_tips_and_tutorials
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification(
  $input: UpdateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  updateNotification(input: $input, condition: $condition) {
    email_latest_update
    email_tips_and_tutorials
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification(
  $input: DeleteNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  deleteNotification(input: $input, condition: $condition) {
    email_latest_update
    email_tips_and_tutorials
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
export const productVerification = /* GraphQL */ `mutation ProductVerification(
  $product_id: ID!
  $user_id: ID!
  $model_no: String!
  $product_code: String!
  $retailer_name: String!
  $receipts: [AWSURL]!
  $status: WarrantyStatus!
  $reason: String
  $extension_ids: [ID]
  $order_id: String!
  $verification_user_id: ID
) {
  productVerification(
    product_id: $product_id
    user_id: $user_id
    model_no: $model_no
    product_code: $product_code
    retailer_name: $retailer_name
    receipts: $receipts
    status: $status
    reason: $reason
    extension_ids: $extension_ids
    order_id: $order_id
    verification_user_id: $verification_user_id
  )
}
` as GeneratedMutation<
  APITypes.ProductVerificationMutationVariables,
  APITypes.ProductVerificationMutation
>;
export const modifyProductVerification = /* GraphQL */ `mutation ModifyProductVerification(
  $product_id: ID!
  $user_id: ID!
  $retailer_name: String!
  $receipts: [AWSURL]!
  $status: WarrantyStatus!
  $reason: String
  $extension_ids: [ID]
  $order_id: String!
  $verification_user_id: ID
) {
  modifyProductVerification(
    product_id: $product_id
    user_id: $user_id
    retailer_name: $retailer_name
    receipts: $receipts
    status: $status
    reason: $reason
    extension_ids: $extension_ids
    order_id: $order_id
    verification_user_id: $verification_user_id
  )
}
` as GeneratedMutation<
  APITypes.ModifyProductVerificationMutationVariables,
  APITypes.ModifyProductVerificationMutation
>;
