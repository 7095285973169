import { Product } from "graphql/API";

// object of the state of a collection
export interface InitialProductCollectionState {
    collectionProducts: any[];
    collectionProductsNextToken?: string;
    fetchingCollectionProducts: boolean;
    lastUpdatedTime?: string;
}

export interface InitialProductBundleState {
    bundleProducts: any[];
    bundleProductsNextToken?: string;
    fetchingBundleProducts: boolean;
    lastUpdatedTime?: string;
}

export interface InitialProductState {
    products: Product[];
    productsNextToken: string | null;
    fetchingProducts: boolean;
    fetchingProductErrors?: string;
    productDetails: Product | null;
    fetchingProductDetails: boolean;
    viewedProducts: any[];
    collections?: { [name: string]: InitialProductCollectionState }
    bundles?: { [name: string]: InitialProductBundleState }
}

export const initialProductState: InitialProductState = {
    products: [],
    productsNextToken: null,
    fetchingProducts: false,
    productDetails: null,
    fetchingProductDetails: false,
    viewedProducts: [],
}

export type ProductState = InitialProductState | InitialProductBundleState | InitialProductCollectionState;