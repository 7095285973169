import { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";

import { Toaster } from "react-hot-toast";

// mui
import {
  CircularProgress,
  CssBaseline,
} from "@mui/material";
import { fetchUserAttributes } from "@aws-amplify/auth";

// routing
import Routes from "navigation/routes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import CookieBanner from "components/CookieBanner";
import { paths } from "navigation/paths";
import { getUser, setUser } from "store/actions/user";

import "./App.css";
// import config from "./config";
import { getFavoriteProductIds } from "store/actions/favorites";
import { ApplicationState } from "store/reducers";
import AppProviders from "app/Providers";

interface IStateProps {
  user: any;
}
interface IDispatchProps {
  setUser: typeof setUser;
  getFavoriteProductIds: typeof getFavoriteProductIds;
}

type AppProps = IStateProps & IDispatchProps;

function App(props: AppProps) {
  const [fetchingUser, setFetchingUser] = useState<boolean>();
  const { user } = useSelector((state: ApplicationState) => state.user);

  const dispatch = useDispatch();
  const getAuth = async () => {
    setFetchingUser(true);
    await fetchUserAttributes()
      .then(async (data) => {
        const { sub, name } = data;
        dispatch(getUser({ id: sub || "" }, name));
        // const { payload } = data.signInUserSession.idToken;
        // const { email, name, phone_number, family_name, given_name } = payload;
        // const cognitoGroups = payload["cognito:groups"];
        // const user = {
        //   email,
        //   name,
        //   phone_number,
        //   family_name,
        //   given_name,
        //   userGroup: cognitoGroups ? cognitoGroups[0] : "User",
        // };
        // props.setUser(user);
        // // notify("success", data.attributes.name);
        // // setLoading(false);
        // setFetchingUser(false);
      })
      .catch((err) => {
        console.log(err);
        setFetchingUser(false);
        // navigate(authPaths.Login)
      });
  };

  const getFavoriteProductIds = () => {
    const id = localStorage.getItem("qfavid");

    // console.log(id);
    if (id) {
      props.getFavoriteProductIds({ id });
    }
  };

  useEffect(() => {
    getAuth();
  }, []);

  useEffect(() => {
    if (user?.id) {
      setFetchingUser(false);
    }
  }, [user]);

  useEffect(() => {
    getFavoriteProductIds();
  }, [fetchingUser]);

  return (
    <AppProviders>
      {fetchingUser ? (
        <CircularProgress color="primary" />
      ) : (
        <>
          <CssBaseline />
          <NavigationScroll>
            <Routes />
            <Toaster position="bottom-center" />
            <CookieBanner
              headline="YOUR COOKIE SETTINGS"
              body={
                <div className="cookie-banner-text">
                  Quasa uses cookies to improve your experience on our site.
                  Cookies enable you to enjoy certain features, social sharing
                  functionality and tailor messages and display ads to your
                  interest on our site, and others. They also help us understand
                  how our site is being used. Find out more in the{" "}
                  <a href={paths.PrivacyPage}>privacy policy</a> section. By
                  continuing to use our site, you consent to use our cookies.
                </div>
              }
            />
          </NavigationScroll>
        </>
      )}
    </AppProviders>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  setUser,
  getFavoriteProductIds,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
