import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// mui
import { useTheme } from "@mui/material/styles";
import { Avatar, Drawer, ButtonBase } from "@mui/material";

// project imports
import Section from "./Section";

// assets
import { IconX } from "@tabler/icons";

// ==============================|| NOTIFICATION ||============================== //

const Notification = ({ enableClose }) => {
  const theme = useTheme();
  const [show, setShow] = useState(enableClose);

  useEffect(() => {
    const alertCheck = () => {
      let data = sessionStorage.getItem("alert");
      
      if (!data) {
        setShow(true);
      } else setShow(false);
    };
    alertCheck();
  }, []);

  const handleClose = () => {
    setShow(false);
    sessionStorage.setItem("alert", true);
  };

  return (
    <>
      {/* children */}

      <Drawer
        anchor={"top"}
        sx={{
          width: "100%",
          display: "flex",
          pr: 2,
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
        open={show}
      >
        <Section />

        {/* close button */}

        <ButtonBase
          sx={{
            borderRadius: "12px",
            overflow: "hidden",
            // display: { xs: "block", md: "none" },
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.orange.light,
              color: theme.palette.orange.dark,
              "&:hover": {
                background: theme.palette.orange.dark,
                color: theme.palette.orange.light,
              },
            }}
            onClick={handleClose}
            color="inherit"
          >
            <IconX stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Drawer>
    </>
  );
};

Notification.defaultProps = {
  enableClose: true,
};

Notification.propTypes = {
  enableClose: PropTypes.bool,
};

export default Notification;
